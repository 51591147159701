import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Input from '../Input';
import * as errors from '../../constants/errors';
import * as timers from '../../constants/timers';

const MAXLEN = 4;
const TIKIR_TIME = 1000;

type Props = {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'IntlShape'.
  intl: IntlShape;
  errorCode?: number;
  isFetching?: boolean;
  onSubmit: (...args: any[]) => any;
  onRequestNewPin: (...args: any[]) => any;
  phone: any;
};

type State = any;

class SmsCodeForm extends React.PureComponent<Props, State> {
  static propTypes: any;

  tiker: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      pin: '',
      isError: null,
      timer: false,
      newCode: false,
      counter: 0,
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      (this.state.timer !== prevState.timer && !this.state.timer) ||
      (this.state.newCode !== prevState.newCode && this.state.newCode)
    ) {
      return { isError: null };
    }
    if (this.state.pin !== prevState.pin) {
      return { isError: null };
    }

    if (this.props.errorCode && this.props.errorCode !== prevProps.errorCode) {
      return { isError: this.props.errorCode };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot) {
    if (snapshot !== null) {
      this.setState(snapshot);
    }

    if (this.props.errorCode !== prevProps.errorCode && this.props.errorCode === errors.ERROR_TO_MANY) {
      this.statrTiker(timers.TIMER_TO_MANE);
    }

    if (this.state.newCode && this.state.newCode !== prevState.newCode) {
      this.statrTiker(timers.TIMER_REPEATE_CODE);
    }

    if (this.props.errorCode === errors.ERROR_TO_MANY && this.state.timer) {
      this.setState({
        pin: '',
      });
    }
  }

  componentWillUnmount() {
    this.endTiker();
  }

  statrTiker = timer => {
    this.setState(
      {
        timer: true,
        counter: timer,
      },
      () => this.ticker()
    );
  };

  endTiker = () => clearInterval(this.tiker);

  ticker = () => {
    this.endTiker();
    this.tiker = setInterval(() => this.tick(), TIKIR_TIME);
  };

  tick = () => {
    if (this.state.counter - TIKIR_TIME > 0) {
      this.setState(state => {
        return {
          counter: state.counter - TIKIR_TIME,
        };
      });
    } else {
      this.endTiker();
      this.setState({
        timer: false,
        newCode: false,
        counter: 0,
      });
    }
  };

  formatTime = ms => {
    const { formatMessage } = this.props.intl;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const min = parseInt(ms / 60000, 10);
    let sec = ms - min * 60 * 1000;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    sec = parseInt(sec / 1000, 10);
    let str = '';
    if (min > 0) {
      str = `${min} ${formatMessage({ id: 'form.expiration.min' })}`;
      if (sec > 0) {
        str += ` ${sec} ${formatMessage({ id: 'form.expiration.postfix' })}`;
      }
    } else {
      str = `${sec} ${formatMessage({ id: 'form.expiration.postfix' })}`;
    }
    return (
      <span>
        {formatMessage({ id: 'form.expiration.prefix' })} {str}
      </span>
    );
  };

  onRequestNewPin = () => {
    this.setState(
      {
        newCode: true,
        timer: true,
      },
      () => {
        this.props.onRequestNewPin();
      }
    );
  };

  onInputChange = event => {
    const input = event.target;
    const pin = input.value.toString().replace(/\D/g, '');
    this.setState({
      pin,
    });
    if (pin.length === MAXLEN) {
      this.props.onSubmit(pin);
    }
  };

  renderErrorsOrLabel = () =>
    !this.state.isError ? (
      <div>
        <div className="login-form__label">
          <FormattedMessage id="pinCodeForm.label_3" />
        </div>
        <div style={{ color: 'white' }}>+{this.props.phone}</div>
        <label className="login-form__label" style={{ marginTop: 30 }}>
          <FormattedMessage id="pinCodeForm.label" />
        </label>
      </div>
    ) : (
      <div className="login-form__error">
        <FormattedMessage id={`pinCodeForm.error.${this.state.isError}`} />
      </div>
    );

  renderForm = () => (
    <Input
      autoComplete="off"
      className="login-form__input login-form__input--sms"
      value={this.state.pin}
      name="pin"
      type="text"
      readOnly={this.props.errorCode === errors.ERROR_TO_MANY && this.state.timer}
      maxLength={MAXLEN}
      onChange={this.onInputChange}
      autoFocus
    />
  );

  render() {
    return (
      <div className="login-form">
        {this.renderErrorsOrLabel()}
        {this.renderForm()}
        <div className="login-form__request-new">
          {this.state.counter > 0 ? (
            this.formatTime(this.state.counter)
          ) : (
            // eslint-disable-next-line
            <a href="#" onClick={this.onRequestNewPin}>
              <FormattedMessage id="pinCodeForm.requestNewCode" />
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(SmsCodeForm);
