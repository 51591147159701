import React from 'react';
import Loading from '../../components/Loading';

function withLoading(WrappedComponent) {
  return class HOC extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isFetching: false,
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.isFetching !== nextProps.isFetching && !nextProps.isFetching) {
        return {
          isFetching: nextProps.isFetching,
        };
      }
      return null;
    }

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFetching' does not exist on type 'Read... Remove this comment to see the full error message
      if (this.state.isFetching) {
        return <Loading />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withLoading;
