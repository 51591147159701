import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

const IntlProviderComponent = ({ locale, children, messages }) => {
  return (
    <IntlProvider key={locale} locale={locale} defaultLocale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default connect(state => ({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  locale: state.i18n.locale,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  messages: state.i18n.messages,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  key: state.i18n.locale,
}))(IntlProviderComponent);
