import React from 'react';

import Routes from './Routes';

import './app.scss';

// require('board-app-components/dist/lib.css');

function App() {
  return <Routes />;
}

export default App;
