import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withMeetings from '../../HOC/withMeetings';
import MeetingsList from '../../../components/MeetingsList';
import MeetingView from '../../../components/MeetingView';

import { loadLocale } from '../../../actions/i18n';
import {
  loadMeetings,
  loadMeetingsMore,
  loadMeetingsMoreUp,
  selectMeeting,
  getMeeting,
} from '../../../actions/meetings';

import * as GROUPS from '../../../constants/listGroup';
import * as routes from '../../../constants/routes';
import cn from '../../../utils/cn';

import './styles.scss';
import '../../../styles/global.scss';

type MeetingsProps = {
  history?: any;
  match?: any;
  offset?: number;
  meetings?: any[];
  direction?: string;
  isFetching?: boolean;
  totalMeetings?: number;
  location?: any;
  loadLocale: (...args: any[]) => any;
  loadMeetings: (...args: any[]) => any;
  loadMeetingsMore: (...args: any[]) => any;
  loadMeetingsMoreUp: (...args: any[]) => any;
  selectMeeting: (...args: any[]) => any;
  getMeeting: (...args: any[]) => any;
  locale?: string;
  itemsLengthBeforeUbpate?: number;
  filters?: any;
  meeting?: any;
  isShowCanseledMeetings?: boolean;
};

type MeetingsState = any;

@cn('meetings')
class Meetings extends React.Component<MeetingsProps, MeetingsState> {
  state = {
    title: null,
  };

  setCurrentTitle = (title, scrollableContainerTopPosition) => {
    if (!title) {
      return;
    }

    const titlePositionCoordinates = title.getBoundingClientRect();
    const titleHeight = titlePositionCoordinates.height;
    const scrollableContainerTopPositionWithOutTitleHeight = scrollableContainerTopPosition - titleHeight;
    if (titlePositionCoordinates.top <= scrollableContainerTopPositionWithOutTitleHeight) {
      this.setState({
        title: title.textContent,
      });
    }
  };

  resetCurrentTitle = scrollableContainer => {
    if (scrollableContainer.scrollTop === 0) {
      this.setState({
        title: '',
      });
    }
  };

  handleOnScroll = (scrollableContainer, upComingTitle, todayTitle, passedTitle, grouped) => {
    const scrollableContainerTopPosition = scrollableContainer.getBoundingClientRect().top;
    this.resetCurrentTitle(scrollableContainer);
    if (grouped[GROUPS.UPCOMING].length > 0) {
      this.setCurrentTitle(upComingTitle, scrollableContainerTopPosition);
    }
    if (grouped[GROUPS.TODAY].length > 0) {
      this.setCurrentTitle(todayTitle, scrollableContainerTopPosition);
    }
    if (grouped[GROUPS.PASSED].length > 0) {
      this.setCurrentTitle(passedTitle, scrollableContainerTopPosition);
    }
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    return (
      <main className={`container container--with-padding-top container--full-height ${cn()}`}>
        <section className={`container__small-column ${cn('meetings-list-container')}`}>
          {this.state.title && <h1 className={cn('title')}>{this.state.title}</h1>}
          <MeetingsList
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'handleOnScroll' does not exist on type '... Remove this comment to see the full error message
            handleOnScroll={this.handleOnScroll}
            className={cn('meetings-list')}
            isShowCanseledMeetings={this.props.isShowCanseledMeetings}
            filters={this.props.filters}
            offset={this.props.offset}
            selectMeeting={this.props.selectMeeting}
            itemsLengthBeforeUbpate={this.props.itemsLengthBeforeUbpate}
            loadMeetingsMore={this.props.loadMeetingsMore}
            loadMeetingsMoreUp={this.props.loadMeetingsMoreUp}
            total={this.props.totalMeetings}
            isFetching={this.props.isFetching}
            history={this.props.history}
            meetings={this.props.meetings}
            meeting={this.props.meeting}
            entity={routes.meetings}
          />
        </section>
        <article className={`container__large-column ${cn('description-container')}`}>
          {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
          <MeetingView meeting={this.props.meeting} getMeeting={this.props.getMeeting} locale={this.props.locale} />
        </article>
      </main>
    );
  }
}

const mapStateToProps = ({ i18n, scrollableList, filters, meeting, app }) => ({
  isShowCanseledMeetings: app.isShowCanseledMeetings,
  itemsLengthBeforeUbpate: scrollableList.itemsLengthBeforeUbpate,
  direction: scrollableList.direction,
  isFetching: scrollableList.isFetching,
  meetings: scrollableList.items,
  totalMeetings: scrollableList.total,
  offset: scrollableList.offset,
  locale: i18n.locale,
  filters,
  meeting,
});

const mapDispatchToProps = {
  loadMeetings,
  loadMeetingsMore,
  loadMeetingsMoreUp,
  loadLocale,
  selectMeeting,
  getMeeting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
)(withMeetings(withRouter(Meetings)));
