// eslint-disable-next-line
export default {
  'menu.meetings': 'Meetings',
  'menu.files': 'Files',
  'menu.reports': 'Reports',
  'menu.members': 'Members',
  'menu.logout': 'Log out',
  'menu.profile': 'Profile',
  'menu.document': 'Document',
  'form.expiration.prefix': 'New code:',
  'form.expiration.min': 'min',
  'form.expiration.postfix': 'sec',
  'phoneForm.next': 'Next',
  'phoneForm.label': 'Enter your phone number',
  'pinCodeForm.back': 'Phone number',
  'pinCodeForm.requestNewCode': 'Send again',
  'pinCodeForm.label': 'Enter a confirmation code from SMS',
  'pinCodeForm.label_2': 'Enter 4-digit PIN code',
  'pinCodeForm.label_3': 'Enter verification code sent to the number',
  'pinCodeForm.label.repeate': 'Repeat PIN code',
  'phoneForm.error.404': 'User not found',
  'phoneForm.error.422': 'Wrong phone!',
  'phoneForm.error.500': 'Internal error, try later',
  'phoneForm.error.502': 'Internal error, try later',
  'phoneForm.error.429': 'Too many attempts. Try again in 3 minutes',
  'pinCodeForm.error.404': 'Wrong code! Enter your code again!',
  'pinCodeForm.error.403': 'Wrong or expired code! Enter your code again!',
  'pinCodeForm.error.429': 'Too many attempts. Try again in 3 minutes',
  'pinCodeForm.error.500': 'Internal error, try later',
  'pinCodeForm.error.502': 'Internal error, try later',
  'pinCodeForm.absent.wereAbsent': 'It looks like you were absent.',
  'pinCodeForm.absent.completedSession': 'We completed your session in a personal account.',
  'pinCodeForm.absent.sendSms': 'Send SMS',
  'pinCodeForm.absent.resendSms': 'Resend SMS to continue.',
  'profile.welcome': 'Welcome',
  'profile.loading': 'Index your experience…',
  'profile.locale': 'English',
  'profile.language': 'Language',
  'profile.version': 'Version',
  'profile.yes': 'Yes',
  'profile.no': 'No',
  'profile.changeLocale': 'Change the interface language?',
  'profile.message': 'message',
  'profile.mobile': 'mobile',
  'profile.email': 'email',
  'profile.tamtam': 'tamtam',
  'mobile.text': 'In order to use Board App on your mobile device please download the mobile app',
  'mobile.download': 'Download App',
  'meetings.types.all': 'All meetings',
  'meetings.groups.upcoming': 'Upcoming',
  'meetings.groups.today': 'Today',
  'meetings.groups.passed': 'Passed',
  'meeting.myFiles': 'My files',
  'meeting.meetingFiles': 'Meeting files',
  'meeting.noDocuments': 'At the moment there are no Files',
  'meeting.engDocuments': 'Eng files',
  'meeting.rusDocuments': 'Rus files',
  'meeting.downloadAllEngDocuments': 'Download files (Eng)',
  'meeting.downloadAllRusDocuments': 'Download files (Rus)',
  'meeting.minutesDocuments': 'Minutes',
  'meeting.files': 'Files',
  'meeting.archive': 'Archive files',
  'time.at': 'at',
  'time.today': 'Today',
  'search.results': 'Search Results',
  'search.results.matching': 'No results found matching:',
  'app.results': 'Nothing was found',
  'files.filters.all': 'All files',
  'files.filters.myComments': 'My comments',
  'files.created': 'Created on',
  'files.updated': 'Updated on',
  'files.originals': 'Export',
  'files.download': 'Download',
  'files.new': 'New',
  'files.responsible': 'Responsible',
  'files.download.original': 'Original',
  'files.download.original.many': 'Originals',
  'files.download.converted': 'Converted PDF',
  'files.download.converted.many': 'Converted PDFs',
  'showMore.more': 'Show more',
  'showMore.less': 'Show less',
  'textarea.placeholder.comment': 'Text Comment',
  'comment.create': 'Create',
  'comment.remove': 'Remove',
  'comment.edit': 'Edit',
  'comment.private': 'Private',
  'loggedOut.description': 'You have not been active for more than 10 minutes. You will be logged out in',
  'loggedOut.continueWork': 'To continue working please press Continue',
  'loggedOut.continue': 'Continue',
  'comments.ballon': 'Tap on page to leave comment',
  'pdfDocument.printWithoutNotes': 'Print a document without notes',
  'members.boardOfDirectors': 'Board of Directors',
  'members.management': 'Management',
  'members.noMembers': 'At the moment there are no Members',
  'reports.report.created': 'Created on {date, date, medium} at {date, time, short}',
  'reports.report.updated': 'Updated on {date, date, medium} at {date, time, short}',
  'reports.report.file_updated': '{date, date, medium}, {date, time, short}',
  'reports.report.eng': 'Eng files',
  'reports.report.rus': 'Rus files',
  'reports.report.search_result_title': 'Documents search result in reports',
  'reports.report.search_result_subtitle': '{count} found',
  'reports.types.all': 'All reports',
};
