import React, { PureComponent } from 'react';
import moment from 'moment';
// eslint-disable-next-line
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';

import Avatar from '../Avatar';
import Button from '../Button';
import DefaultAvatar from '../../assets/images/default-avatar.png';

import * as locales from '../../constants/locales';
import * as routes from '../../constants/routes';
import cn from '../../utils/cn';

import { version } from '../../../package.json';
import './styles.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    borderRadius: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.75)',
  },
};

type OwnProps = {
  locale?: string;
  intl: IntlShape;
  user?: any;
  closeProfile: (...args: any[]) => any;
  onLogout: (...args: any[]) => any;
  changeLocale: (...args: any[]) => any;
  isVisible?: boolean;
  isShowCanseledMeetings?: boolean;
  isMe?: boolean;
  setStorage: (...args: any[]) => any;
};

type State = any;

type Props = OwnProps & typeof Profile.defaultProps;

@cn('profile')
class Profile extends PureComponent<Props, State> {
  static defaultProps = {
    isMe: true,
    onLogout: () => {},
    changeLocale: () => {},
    setStorage: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      locale: this.props.locale,
    };
  }

  loadLocale = locale => {
    this.props.changeLocale(locale);
    moment.locale(locale);
    this.setState({
      locale,
    });
  };

  handleChangeLocale = e => {
    e.preventDefault();

    /* eslint-disable no-alert */

    const isChange = window.confirm(this.props.intl.formatMessage({ id: 'profile.changeLocale' }));

    /* eslint-enable no-alert */

    if (isChange) {
      if (this.state.locale === locales.RU) {
        this.loadLocale(locales.EN);
      } else {
        this.loadLocale(locales.RU);
      }
    }
  };

  handleClose = () => {
    this.props.closeProfile();
  };

  renderContactButtons = cn => {
    const { user } = this.props;

    const message = (
      <>
        <div className={cn('header', 'messengers', 'icon')}>
          <div className={cn('header', 'messengers', 'icon', 'message')} />
        </div>
        <div className={cn('header', 'messengers', 'text')}>
          <FormattedMessage id="profile.message" />
        </div>
      </>
    );

    const phone = (
      <>
        <div
          className={cn('header', 'messengers', 'icon', {
            active: Boolean(user.phone),
          })}
        >
          <div
            className={cn('header', 'messengers', 'icon', 'mobile', {
              active: Boolean(user.phone),
            })}
          />
        </div>
        <div
          className={cn('header', 'messengers', 'text', {
            active: Boolean(user.phone),
          })}
        >
          <FormattedMessage id="profile.mobile" />
        </div>
      </>
    );

    const email = (
      <>
        <div
          className={cn('header', 'messengers', 'icon', {
            active: Boolean(user.email),
          })}
        >
          <div
            className={cn('header', 'messengers', 'icon', 'mail', {
              active: Boolean(user.email),
            })}
          />
        </div>
        <div
          className={cn('header', 'messengers', 'text', {
            active: Boolean(user.email),
          })}
        >
          <FormattedMessage id="profile.email" />
        </div>
      </>
    );

    const tamtam = (
      <>
        <div
          className={cn('header', 'messengers', 'icon', {
            'active-tamtam': Boolean(user.tamtam),
          })}
        >
          <div
            className={cn('header', 'messengers', 'icon', 'tamtam', {
              active: Boolean(user.tamtam),
            })}
          />
        </div>
        <div
          className={cn('header', 'messengers', 'text', {
            active: Boolean(user.tamtam),
          })}
        >
          <FormattedMessage id="profile.tamtam" />
        </div>
      </>
    );

    return (
      <div className={cn('header', 'messengers')}>
        <div className={cn('header', 'messengers', 'item')}>{message}</div>
        {user.phone ? (
          <a
            href={`tel:${user.phone[0] === '+' ? '' : '+'}${user.phone}`}
            className={cn('header', 'messengers', 'item')}
          >
            {phone}
          </a>
        ) : (
          <div className={cn('header', 'messengers', 'item')}>{phone}</div>
        )}

        {user.email ? (
          <a href={`mailto:${user.email}`} className={cn('header', 'messengers', 'item')}>
            {email}
          </a>
        ) : (
          <div className={cn('header', 'messengers', 'item')}>{email}</div>
        )}

        {user.tamtam ? (
          <a
            href={`https://tt.me/${user.tamtam}`}
            target="_blank"
            rel="noopener noreferrer"
            className={cn('header', 'messengers', 'item')}
          >
            {tamtam}
          </a>
        ) : (
          <div className={cn('header', 'messengers', 'item')}>{tamtam}</div>
        )}
      </div>
    );
  };

  renderContactList = cn => {
    const { user } = this.props;
    return (
      <div className={cn('contacts-list')}>
        {user.phone && (
          <div className={cn('contacts-list', 'item')}>
            <a
              href={`tel:${user.phone[0] === '+' ? '' : '+'}${user.phone}`}
              className={cn('contacts-list', 'item', 'value', {
                active: true,
              })}
            >
              {user.phone[0] === '+' ? '' : '+'}
              {user.phone}
            </a>
            <div className={cn('contacts-list', 'item', 'title')}>
              <FormattedMessage id="profile.mobile" />
            </div>
          </div>
        )}
        {user.email && (
          <div className={cn('contacts-list', 'item')}>
            <a
              href={`mailto:${user.email}`}
              className={cn('contacts-list', 'item', 'value', {
                active: true,
              })}
            >
              {user.email}
            </a>
            <div className={cn('contacts-list', 'item', 'title')}>
              <FormattedMessage id="profile.email" />
            </div>
          </div>
        )}
        {user.tamtam && (
          <div className={cn('contacts-list', 'item')}>
            <a
              href={`https://tt.me/${user.tamtam}`}
              target="_blank"
              rel="noopener noreferrer"
              className={cn('contacts-list', 'item', 'value', {
                active: true,
              })}
            >
              {user.tamtam}
            </a>
            <div className={cn('contacts-list', 'item', 'title')}>
              <FormattedMessage id="profile.tamtam" />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderManagementGroup = cn => {
    const { user, locale, intl } = this.props;

    const boards = user.managementGroup.filter(g => g.groupType === 'board');
    const management = user.managementGroup.filter(g => g.groupType === 'management');
    return (
      <>
        {boards.length > 0 && (
          <div className={cn('management-group')}>
            <div className={cn('management-group', 'title')}>
              {intl.formatMessage({ id: 'members.boardOfDirectors' })}
            </div>
            <div className={cn('contacts-list')}>
              {boards.map(group => (
                <div key={group.id} className={cn('contacts-list', 'item')}>
                  <Link
                    className={cn('contacts-list', 'item', 'value')}
                    title={locale === locales.RU ? group.russianName : group.englishName}
                    to={`${routes.members}/${group.groupType}/${group.id}`}
                    onClick={this.handleClose}
                  >
                    {locale === locales.RU ? group.russianName : group.englishName}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
        {management.length > 0 && (
          <div className={cn('management-group')}>
            <div className={cn('management-group', 'title')}>{intl.formatMessage({ id: 'members.management' })}</div>
            <div className={cn('contacts-list')}>
              {management.map(group => (
                <div key={group.id} className={cn('contacts-list', 'item')}>
                  <Link
                    className={cn('contacts-list', 'item', 'value')}
                    title={locale === locales.RU ? group.russianName : group.englishName}
                    to={`${routes.members}/${group.groupType}/${group.id}`}
                    onClick={this.handleClose}
                  >
                    {locale === locales.RU ? group.russianName : group.englishName}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element | null' is not assignab... Remove this comment to see the full error message
  render(cn) {
    const { isVisible, onLogout, user, locale, isMe } = this.props;
    if (!isVisible) return null;

    return (
      <ReactModal
        isOpen={isVisible}
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        contentLabel="ProfileModal"
        style={customStyles}
      >
        <div className={cn('container')}>
          <div className={cn('header')}>
            <div className={cn('header', 'avatar')}>
              <Avatar
                // @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                className={cn('avatar')}
                avatarUrl={user.avatarUrl ? `/api${user.avatarUrl}` : DefaultAvatar}
                userName={locale === locales.RU ? user.russianName : user.englishName}
                size={100}
              />
            </div>
            <div className={cn('header', 'name')}>{locale === locales.RU ? user.russianName : user.englishName}</div>
            <div className={cn('header', 'position')}>
              {locale === locales.RU ? user.russianPosition : user.englishPosition}
            </div>
            {user.englishPositionInCommittee && user.russianPositionInCommittee && (
              <div className={cn('header', 'positionInCommittee')}>
                {locale === locales.RU ? user.englishPositionInCommittee : user.russianPositionInCommittee}
              </div>
            )}
            {!isMe && this.renderContactButtons(cn)}
            <button className={cn('close-button')} onClick={this.handleClose} />
          </div>
          <div className={cn('body')}>
            {isMe ? (
              <div className={cn('addition')}>
                <div className={cn('addition-content')}>
                  <div className={cn('column')}>
                    <p className={cn('column-content')}>
                      <FormattedMessage id="profile.language" />
                    </p>
                    <p className={cn('column-content', { version: true })}>
                      <FormattedMessage id="profile.version" />
                      {`: ${version}`}
                    </p>
                  </div>
                  <div className={cn('column', { right: true })}>
                    <button onClick={this.handleChangeLocale} className={cn('column-content', { locale: true })}>
                      <FormattedMessage id="profile.locale" />
                    </button>
                  </div>
                </div>
                <Button onClick={onLogout} buttonType="green" className={cn('logout-button')}>
                  <FormattedMessage id="menu.logout" />
                </Button>
              </div>
            ) : (
              <>
                {this.renderContactList(cn)}
                {user.managementGroup && this.renderManagementGroup(cn)}
              </>
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof Profile' is not assignable to type 'C... Remove this comment to see the full error message
export default injectIntl(Profile);
