import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import { getMember } from '../../actions/members';

import Profile from '../Profile';
import * as locales from '../../constants/locales';
import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  getMember: (...args: any[]) => any;
  intl: IntlShape;
  className?: string;
  responsible?: any;
};

type State = any;

@cn('files-list')
class Responsible extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showProfile: false,
      responsible: props.responsible,
    };
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element | null' is not assignab... Remove this comment to see the full error message
  render(cn) {
    const {
      className,
      intl: { locale },
    } = this.props;

    const { showProfile, responsible } = this.state;

    if (!responsible) {
      return null;
    }
    return (
      <>
        <div
          className={`${cn('responsible')} ${className}`}
          onClick={() => {
            this.setState({ showProfile: true });
            if (typeof this.props.getMember === 'function') {
              this.props.getMember(responsible.id).then(resp => {
                this.setState({ responsible: resp.data });
              });
            }
          }}
        >
          {locale === locales.RU ? responsible.russianName : responsible.englishName}
        </div>
        {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ isMe: false; isShowCanseledMeetings: false... Remove this comment to see the full error message */}
        <Profile
          isMe={false}
          isShowCanseledMeetings={false}
          user={responsible}
          locale={locale}
          isVisible={showProfile}
          closeProfile={() => this.setState({ showProfile: false })}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  getMember,
};

export default connect(
  undefined,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof Responsible' is not assignable to typ... Remove this comment to see the full error message
)(injectIntl(Responsible));
