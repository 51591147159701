import React from 'react';
import cn from '../../utils/cn';

import './styles.scss';
import CloseIcon from '../Icons/CloseIcon';

@cn('file-downloader')
class FileDownloadProgress extends React.Component {
  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'progress' does not exist on type 'Readon... Remove this comment to see the full error message
    const { progress, aborted, onStop, className, ...restProps } = this.props;

    return (
      <div className={cn('container', className)} {...restProps}>
        <div className={cn('progress-bar')}>
          {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
          <Filler progress={progress} aborted={aborted} />
        </div>
        <div className={cn('close-btn')} onClick={onStop}>
          <CloseIcon className={cn('close-btn', 'ico')} />
        </div>
      </div>
    );
  }
}

@cn('file-downloader')
class Filler extends React.Component {
  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'progress' does not exist on type 'Readon... Remove this comment to see the full error message
    const { progress, aborted } = this.props;
    return <div className={cn('filler', { aborted })} style={{ width: `${progress}%` }} />;
  }
}

export default FileDownloadProgress;
