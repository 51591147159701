// @ts-nocheck
import React from 'react';

import { COLORS, RANGE } from '../../constants/draw';
import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  onChangeColor: (...args: any[]) => any;
  onChangeWeight: (...args: any[]) => any;
  onChangeEraserEnabled: (...args: any[]) => any;
};

type State = any;

@cn('document-drawing-settings')
export class DocumentDrawingSettings extends React.PureComponent<Props, State> {
  state = {
    active: COLORS.blue,
    weight: RANGE.max,
    lastWeight: RANGE.min,
    isErase: false,
  };

  componentDidMount() {
    this.props.onChangeColor(COLORS.blue);
  }

  handleChangeColor = key => {
    this.setState({
      active: COLORS[key],
    });
    if (this.state.isErase) {
      this.handleChangeEraseMode();
    }
    this.props.onChangeColor(COLORS[key]);
  };

  handleChangeRange = val => {
    const weight = val.target.value;
    this.changeWeight(weight);
  };

  changeWeight = weight => {
    this.setState({
      weight,
    });
    this.props.onChangeWeight(weight);
  };

  handleChangeEraseMode = () => {
    const { isErase, weight, lastWeight } = this.state;
    if (!isErase) {
      this.setState({
        lastWeight: weight,
      });
      this.changeWeight(RANGE.maxEraser);
    } else {
      this.changeWeight(lastWeight);
    }
    this.setState(
      {
        isErase: !isErase,
      },
      () => {
        this.props.onChangeEraserEnabled(this.state.isErase);
      }
    );
  };

  getRangeMax = () => {
    const { isErase } = this.state;
    return isErase ? RANGE.maxEraser : RANGE.max;
  };

  render(cn) {
    return (
      <div className={cn('')}>
        <div className={cn('content-container')}>
          <input
            onChange={this.handleChangeRange}
            className={cn('range')}
            type="range"
            value={this.state.weight}
            min={RANGE.min}
            max={this.getRangeMax()}
          />
          <ul className={cn('list')}>
            {Object.keys(COLORS).map((key, index) => {
              return (
                <li
                  onClick={() => this.handleChangeColor(key)}
                  key={`${key}${index}`}
                  className={cn('item', {
                    active: this.state.active === COLORS[key] && !this.state.isErase,
                  })}
                >
                  <button style={{ backgroundColor: key }} className={cn('button')} />
                </li>
              );
            })}
          </ul>
          <div className={cn('rubber-container', { active: this.state.isErase })}>
            <button onClick={this.handleChangeEraseMode} className={cn('rubber')} />
          </div>
        </div>
      </div>
    );
  }
}
