import React from 'react';
import cn from 'classnames';

import './CalendarContainer.scss';

const CalendarContainer = ({ className, children }) => (
  <div className={cn('calendar-container', className)}>{children}</div>
);

export default CalendarContainer;
