import React, { PureComponent, memo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { VariableSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Page, pdfjs } from 'react-pdf';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
import memoize from 'memoize-one';
import { throttle } from 'lodash';

import DocumentComment from '../DocumentComment';
import DocumentPageCommentsLayer from '../DocumentPageCommentsLayer';
import DocumentDrowingLayer from '../DocumentDrowingLayer';

import cn from '../../utils/cn';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

type Props = {
  documentId?: number;
  pages?: any[];
  comments?: any[];
  comment?: any;
  drawings?: any[];
  activePage?: number;
  addComment: (...args: any[]) => any;
  editComment: (...args: any[]) => any;
  deleteComment: (...args: any[]) => any;
  onClickCommentMarck: (...args: any[]) => any;
  onCloseCommentPopup: (...args: any[]) => any;
  onInitComment: (...args: any[]) => any;
  isShowCommentPopup?: boolean;
  isCommentsAddingEnabled?: boolean;
  isCommentsEnabled?: boolean;
  isPreviewEnabled?: boolean;
  isDrawingEnabled?: boolean;
  isDrawingEraserEnabled?: boolean;
  drawingColor?: string;
  drawingWeight?: number;
  saveDraw: (...args: any[]) => any;
  onSetContainerWeight: (...args: any[]) => any;
  onChangeActivePage: (...args: any[]) => any;
};

type State = any;

@cn('document-pdf')
class DocumentViewer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      documentWidth: 1140,
    };

    this.listRef = React.createRef();
    this.listScrollOffset = 0;
  }

  static getDerivedStateFromProps(props) {
    let documentWidth = 1140;

    if (props.isPreviewEnabled) documentWidth -= 110;
    if (props.isCommentsEnabled) documentWidth -= 310;

    return {
      documentWidth,
    };
  }

  static getPageScale = (page, documentWidth) => {
    return page.view[2] ? documentWidth / page.view[2] : 0.15;
  };

  listRef: any;
  listScrollOffset: any;

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { pages, onSetContainerWeight, activePage, isCommentsEnabled, isPreviewEnabled } = this.props;

    const { documentWidth } = this.state;

    if (documentWidth !== prevState.documentWidth) {
      onSetContainerWeight(documentWidth);
    }

    if (
      activePage !== prevProps.activePage &&
      activePage !== DocumentViewer.getPageByScrollOffset(pages, documentWidth, this.listScrollOffset)
    ) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.listRef.current.scrollToItem(activePage - 1, 'start');
    }

    if (isCommentsEnabled !== prevProps.isCommentsEnabled || isPreviewEnabled !== prevProps.isPreviewEnabled) {
      this.listRef.current.resetAfterIndex(0);
    }
  }

  onAddComment = comment => {
    const { addComment, documentId } = this.props;
    addComment(documentId, comment);
  };

  onEditComment = data => {
    const { editComment } = this.props;
    editComment(data);
  };

  onDeleteComment = id => {
    const { deleteComment } = this.props;
    deleteComment(id);
  };

  onCancelComment = () => {
    const { onCloseCommentPopup } = this.props;
    onCloseCommentPopup();
  };

  // уберем слишком частую обработку scroll
  onScroll = throttle(({ scrollOffset, scrollUpdateWasRequested }) => {
    if (scrollUpdateWasRequested) return;

    const { pages, activePage, onChangeActivePage } = this.props;
    const { documentWidth } = this.state;

    this.listScrollOffset = scrollOffset;

    const newActivePage = DocumentViewer.getPageByScrollOffset(pages, documentWidth, scrollOffset);

    if (activePage !== newActivePage) {
      onChangeActivePage(newActivePage);
    }
  }, 350);

  getDrawByPage = page => {
    const { drawings } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return drawings.filter(item => item.pageNumber === page)[0];
  };

  getItemData = memoize(
    (
      pages,
      isShowCommentPopup,
      comment,
      documentWidth,
      activePage,
      isDrawingEnabled,
      drawingWeight,
      drawingColor,
      isDrawingEraserEnabled
    ) => ({
      pages,
      isShowCommentPopup,
      comment,
      documentWidth,
      activePage,
      isDrawingEnabled,
      drawingWeight,
      drawingColor,
      isDrawingEraserEnabled,
    })
  );

  static getPageByScrollOffset(pages, documentWidth, scrollOffset) {
    if (!scrollOffset) return 1;

    let page;

    let pageHeight;
    let offset = scrollOffset;

    for (let i = 0; i < pages.length; i++) {
      page = i + 1;
      const currentPage = pages[i];

      if (currentPage === undefined) {
        // eslint-disable-next-line
        continue;
      }

      const scale = DocumentViewer.getPageScale(currentPage, documentWidth);
      const viewport = currentPage.getViewport({ scale });

      pageHeight = viewport.height + 20;

      if (offset - pageHeight < 0) break;

      offset -= pageHeight;
    }

    return page + Math.round(offset / pageHeight);
  }

  getCommentsByPage = page => {
    const { comments } = this.props;

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return comments
      .map((item, index) => {
        item.positionNum = index + 1;
        return item;
      })
      .filter(item => parseInt(item.position.page, 10) === page);
  };

  getItemSize = index => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const page = this.props.pages[index];
    if (page === undefined) return 0;

    const scale = DocumentViewer.getPageScale(page, this.state.documentWidth);
    const viewport = page.getViewport({ scale });

    return viewport.height + 20;
  };

  initNewComment = (target, e, scale, page, width, height) => {
    const { isCommentsAddingEnabled, onInitComment } = this.props;

    if (isCommentsAddingEnabled) {
      onInitComment(e, scale, page, width, height);
    }
  };

  handleClickComment = comment => {
    const { onClickCommentMarck } = this.props;
    onClickCommentMarck(comment);
  };

  // eslint-disable-next-line react/no-multi-comp
  renderItem = memo(params => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'index' does not exist on type '{ childre... Remove this comment to see the full error message
    const { index, style, data } = params;

    const page = data.pages[index];
    if (page === undefined) return null;

    const scale = DocumentViewer.getPageScale(page, data.documentWidth);
    const { width, height } = page.getViewport({ scale });

    const pageNumber = page.pageNumber;

    return (
      <div key={`key-pdf-page-${pageNumber}`} className="document-pdf__page" style={style}>
        <Page pageNumber={index + 1} scale={scale} renderAnnotationLayer={false} renderTextLayer={false} />
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
        <DocumentPageCommentsLayer
          page={pageNumber}
          onClickLayer={this.initNewComment}
          onClickComment={this.handleClickComment}
          width={width}
          height={height}
          scale={scale}
          comments={this.getCommentsByPage(pageNumber)}
        />
        {data.isShowCommentPopup && data.comment && data.comment.position.page === pageNumber && (
          <DocumentComment
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={cn('document-comment')}
            comment={data.comment}
            scale={scale}
            currentPage={data.activePage}
            containerSize={{ width, height }}
            onCreate={this.onAddComment}
            onEdit={this.onEditComment}
            onDelete={this.onDeleteComment}
            onCancel={this.onCancelComment}
          />
        )}
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
        <DocumentDrowingLayer
          width={width}
          height={height}
          active={data.isDrawingEnabled}
          draw={this.getDrawByPage(pageNumber)}
          weight={data.drawingWeight}
          color={data.drawingColor}
          pageNumber={pageNumber}
          isEraserEnabled={data.isDrawingEraserEnabled}
          saveDraw={this.props.saveDraw}
        />
      </div>
    );
  }, areEqual);

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const {
      pages,
      isShowCommentPopup,
      comment,
      activePage,
      isDrawingEnabled,
      drawingWeight,
      drawingColor,
      isDrawingEraserEnabled,
    } = this.props;
    const { documentWidth } = this.state;

    const itemData = this.getItemData(
      pages,
      isShowCommentPopup,
      comment,
      documentWidth,
      activePage,
      isDrawingEnabled,
      drawingWeight,
      drawingColor,
      isDrawingEraserEnabled
    );

    return (
      <div className={cn()} style={{ width: `${documentWidth}px` }}>
        <Scrollbars width={documentWidth} autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
          <div className={cn('container')} style={{ width: `${documentWidth}px`, height: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  ref={this.listRef}
                  height={height}
                  width={width}
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  itemCount={pages.length}
                  itemSize={this.getItemSize}
                  itemData={itemData}
                  overscanCount={1}
                  onScroll={this.onScroll}
                >
                  {this.renderItem}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default DocumentViewer;
