const apiHost = process.env.REACT_APP_LOCAL_API_HOST || window.location.origin;

const IOS_LINK = `itms-services://?action=download-manifest&url=${apiHost}/builds/ios/manifest.plist`;
const ANDROID_LINK = `${apiHost}/builds/android/app.apk`;

export const appConfig = {
  apiHost,
  apiUrl: `${apiHost}/api`,
  loginToken: 'some',
  timeZone: 'Europe/Moscow',
  timeZoneNum: '+3',
  timeZoneNumForListByYear: '+02:00',
  ios_install_link: IOS_LINK,
  android_install_link: ANDROID_LINK,
};
