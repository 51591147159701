const Device = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },

  iOS() {
    return (
      navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  },

  Desktop() {
    return !Device.Android() && !Device.iOS();
  },
};

export default Device;
