import { SET_LOCALE, ADD_LOCALE } from '../constants/ActionTypes';
import * as locales from '../constants/locales';

const messagesEn = require('../i18n/en').default;

const initialState = {
  locale: locales.EN,
  locales: [],
  messages: messagesEn,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  const { type, ...data } = action;

  switch (type) {
    case SET_LOCALE:
      return {
        ...state,
        ...data,
      };
    case ADD_LOCALE:
      return {
        ...state,
        locales: [...state.locales, action.locale],
      };
    default:
      return state;
  }
};
