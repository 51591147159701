import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import * as ReportsActions from '../../../actions/reports';
import * as routes from '../../../constants/routes';
import * as locales from '../../../constants/locales';

import Item from './Item';
import Breadcrumbs from './Breadcrumbs';

const Report = (props: PropsType) => {
  const { defaultReportId, intl } = props;
  const { id, folderKey } = useParams<ParamTypes>();
  const history = useHistory();
  const folderId = !isNaN(Number(folderKey)) ? folderKey : undefined;
  const selectedTabIndex = !folderId && folderKey ? TabsDictionary.indexOf(folderKey) : 0;
  const reportId = id || defaultReportId;

  const { cn } = props;
  const dispatch = useDispatch();
  const root = useSelector((store: StoreType) => store.reports.root);
  const folder = useSelector((store: StoreType) => store.reports.folder);
  const report = useSelector((store: StoreType) =>
    store.reports.items.find(report => report.id.toString() === reportId.toString())
  );
  const reportName = intl.locale === locales.EN ? report?.englishName : report?.russianName;

  useEffect(() => {
    if (reportId) {
      dispatch(ReportsActions.getReportRoot(reportId));
      if (folderId) {
        dispatch(ReportsActions.getReportFolder(folderId));
      }
    }
  }, [reportId, folderId, dispatch]);

  const selectFolder = useCallback(
    f => () => {
      if (f.pathToRoot?.length) {
        history.push(`${routes.reports}/${reportId}/${f.id}`);
      } else {
        const type = f?.type || folder?.type || 'rus';
        history.push(`${routes.reports}/${reportId}/${type}`);
      }
    },
    [reportId, history, folder]
  );

  const selectFolderBreadcrumb = useCallback(
    id => () => {
      if (id) {
        history.push(`${routes.reports}/${reportId}/${id}`);
      } else {
        const type = folder?.type || 'rus';
        history.push(`${routes.reports}/${reportId}/${type}`);
      }
    },
    [reportId, history, folder]
  );

  const onSelectTab = index => {
    history.push(`${routes.reports}/${reportId}/${TabsDictionary[index]}`);
  };

  if (!reportId) {
    return null;
  }

  return (
    <>
      {((!folderId && report) || (folder && folder.level === 1)) && (
        <>
          <div className={cn('active-report-header')}>
            <div className={cn('active-report-title')}>{reportName}</div>
            {report && (
              <div className={cn('active-report-subtitle')}>
                <FormattedMessage
                  id="reports.report.created"
                  values={{ date: new Date(report?.date), time: new Date(report?.date) }}
                />
              </div>
            )}
          </div>
          <Tabs selectedIndex={selectedTabIndex} onSelect={onSelectTab}>
            <TabList className={cn('tab-list')}>
              <Tab className={cn('tab')} selectedClassName={cn('tab--selected')}>
                <FormattedMessage id="reports.report.rus" />
              </Tab>
              <Tab className={cn('tab')} selectedClassName={cn('tab--selected')}>
                <FormattedMessage id="reports.report.eng" />
              </Tab>
            </TabList>
            <TabPanel>
              <div>
                {root?.rus?.children.map((item, index) => (
                  <Item key={`list_item_${index}`} value={item} onSelectFolder={selectFolder(item)} cn={cn} />
                ))}
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                {root?.eng?.children.map((item, index) => (
                  <Item key={`list_item_${index}`} value={item} onSelectFolder={selectFolder(item)} cn={cn} />
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </>
      )}
      {folderId && folder && folder.level > 1 && (
        <>
          {folder.pathToRoot && (
            <div className={cn('active-folder-header')}>
              <Breadcrumbs
                type={folder.type}
                crumbs={folder.pathToRoot}
                onClick={selectFolderBreadcrumb}
                reportName={reportName}
                cn={cn}
              />
            </div>
          )}
          <div className={cn('active-folder-header')}>
            {folder.parent && <span className={cn('back')} onClick={selectFolder(folder.parent)} />}
            <div>
              <div className={cn('active-folder-title')}>{folder?.name}</div>
              <div className={cn('active-folder-subtitle')}>
                <FormattedMessage
                  id="reports.report.created"
                  values={{ date: new Date(folder?.updatedAt), time: new Date(folder?.updatedAt) }}
                />
              </div>
            </div>
          </div>
          <div>
            {folder.children.map((item, index) => (
              <Item key={`list_item_${index}`} value={item} onSelectFolder={selectFolder(item)} cn={cn} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

interface ParamTypes {
  id: string;
  folderKey: string;
}

type StoreType = {
  reports: any;
};

type PropsType = {
  cn: any;
  defaultReportId: any;
} & WrappedComponentProps;

const TabsDictionary = ['rus', 'eng'];

export default injectIntl(Report);
