import {
  GET_MEMBERS_BY_GROUP_REQUEST,
  GET_MEMBERS_BY_GROUP_SUCCESS,
  GET_MEMBERS_BY_GROUP_FAILURE,
  SEARCH_MEMBERS_REQUEST,
  SEARCH_MEMBERS_SUCCESS,
  SEARCH_MEMBERS_FAILURE,
} from '../constants/ActionTypes';

const defaultState = {
  groups: {},
  search: [],
  isFetching: false,
  error: null,
};

const members = (state = defaultState, action) => {
  switch (action.type) {
    case GET_MEMBERS_BY_GROUP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MEMBERS_BY_GROUP_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        groups: { ...state.groups, [data.id]: data },
        isFetching: false,
      };
    }
    case GET_MEMBERS_BY_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case SEARCH_MEMBERS_REQUEST:
      return {
        ...state,
        search: [],
        isFetching: true,
      };
    case SEARCH_MEMBERS_SUCCESS:
      return {
        ...state,
        search: action.data.items,
        isFetching: false,
      };
    case SEARCH_MEMBERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default members;
