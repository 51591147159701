import React, { Component } from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.scss';

import * as locales from '../../constants/locales';
import cn from '../../utils/cn';

import './styles.scss';

export const GroupType = {
  board: 'board',
  management: 'management',
};

type Props = {
  groupType?: string;
  groupId?: string;
  intl: IntlShape;
  onSelectGroups: (...args: any[]) => any;
  getGroupsByType: (...args: any[]) => any;
};

type State = any;

@cn('members-filters')
class MembersFilterComponent extends Component<Props, State> {
  static makeAllGroup() {
    return { id: 0, englishName: 'All', russianName: 'Все' };
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedType: GroupType.board,
      selectedGroup: MembersFilterComponent.makeAllGroup(),
      groups: {
        [GroupType.board]: [],
        [GroupType.management]: [],
      },
    };
  }

  componentDidMount() {
    this.applyRouteParams(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { groupId: currGroupId, groupType: currGroupType } = this.props;
    const { groupId: nextGroupId, groupType: nextGroupType } = nextProps;
    if (currGroupId !== nextGroupId || currGroupType !== nextGroupType) {
      this.applyRouteParams(nextProps);
    }
  }

  onTypeChange(selectedType) {
    const { getGroupsByType } = this.props;
    return new Promise((resolve, reject) => {
      getGroupsByType(selectedType)
        .then(data => {
          this.setState(
            state => ({
              selectedType,
              groups: { ...state.groups, [selectedType]: data.items || [] },
            }),
            () => {
              this.onGroupSelect(MembersFilterComponent.makeAllGroup());
              resolve(this.state);
            }
          );
        })
        .catch(reject);
    });
  }

  onGroupSelect(selectedGroup) {
    const { onSelectGroups } = this.props;

    this.setState({ selectedGroup });

    let selectedGroups = [];
    if (selectedGroup.id === 0) {
      const { selectedType, groups } = this.state;
      selectedGroups = groups[selectedType];
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      selectedGroups = [selectedGroup];
    }

    if (onSelectGroups) {
      onSelectGroups(selectedGroups);
    }
  }

  applyRouteParams(props) {
    let { groupType } = props;
    if (!Object.values(GroupType).includes(groupType)) {
      groupType = GroupType.board;
    }
    this.onTypeChange(groupType).then(() => {
      const { groupId } = props;
      if (groupId) {
        const { selectedType, groups } = this.state;
        const group = groups[selectedType].find(g => parseInt(g.id, 10) === parseInt(groupId, 10));
        if (group) {
          this.onGroupSelect(group);
        }
      }
    });
  }

  renderGroups({ groups, selectedGroup, intl, cn }) {
    return groups.map(g => (
      <div
        key={g.id}
        className={cn('list', 'item', {
          selected: selectedGroup && g.id === selectedGroup.id,
        })}
        onClick={() => this.onGroupSelect(g)}
      >
        {intl.locale === locales.EN ? g.englishName : g.russianName}
      </div>
    ));
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { intl } = this.props;
    const { selectedType, groups, selectedGroup } = this.state;

    return (
      <div className={cn()}>
        <Tabs
          selectedIndex={selectedType === GroupType.board ? 0 : 1}
          onSelect={index => this.onTypeChange(index === 0 ? GroupType.board : GroupType.management) as any}
          forceRenderTabPanel={true}
        >
          <TabList className={`react-tabs__tab-list ${cn('tab-list')}`}>
            <Tab
              className={`react-tabs__tab ${cn('tab')}`}
              selectedClassName={cn('tab', { selected: true }).toString()}
            >
              {intl.formatMessage({ id: 'members.boardOfDirectors' })}
            </Tab>
            <Tab
              className={`react-tabs__tab ${cn('tab')}`}
              selectedClassName={cn('tab', { selected: true }).toString()}
            >
              {intl.formatMessage({ id: 'members.management' })}
            </Tab>
          </TabList>
          <TabPanel>
            {this.renderGroups({
              groups: [MembersFilterComponent.makeAllGroup(), ...groups[GroupType.board]],
              selectedGroup,
              intl,
              cn,
            })}
          </TabPanel>
          <TabPanel>
            {this.renderGroups({
              groups: [MembersFilterComponent.makeAllGroup(), ...groups[GroupType.management]],
              selectedGroup,
              intl,
              cn,
            })}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof MembersFilterComponent' is not assign... Remove this comment to see the full error message
export const MembersFilter = injectIntl(MembersFilterComponent);
