import * as types from '../constants/ActionTypes';
import Storage from '../utils/storage';

const initialState = {
  isFetching: false,
  smsCodeSended: false,
  loggedIn: Boolean(Storage.get('accessToken')),
  errorCode: null,
  error: null,
  errorMessage: '',
  isAutorize: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case types.SMS_REQUEST:
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        errorCode: null,
        errorMessage: '',
      };
    case types.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        loggedIn: false,
      };
    case types.SMS_FAILURE:
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorCode: action.errorCode,
        error: action.error,
        errorMessage: action.message,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        smsCodeSended: true,
      };
    case types.LOGIN_CHEAK:
      return {
        ...state,
        loggedIn: true,
        isFetching: false,
        errorCode: null,
        error: null,
        errorMessage: '',
      };
    case types.REFRESH_TOKEN_SUCCESS:
    case types.SMS_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isFetching: false,
      };
    case types.ABSENT_SUCCESS:
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        smsCodeSended: false,
      };
    default:
      return state;
  }
};
