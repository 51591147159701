import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import refresh from '../middleware/refresh';
import reducers from '../reducers/reducers';

const { NODE_ENV } = process.env;

const composeWithDevTools =
  NODE_ENV === 'development' && window && (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    : compose;

export function configureStore(initialState = {}) {
  const middlewares = NODE_ENV === 'development' ? [thunk, refresh] : [thunk, refresh];
  const enhanser = composeWithDevTools(applyMiddleware(...middlewares));

  return createStore(reducers, initialState, enhanser);
}
