// eslint-disable-next-line
export default {
  'menu.meetings': 'Заседания',
  'menu.files': 'Документы',
  'menu.reports': 'Отчеты',
  'menu.members': 'Участники',
  'menu.profile': 'Профиль пользователя',
  'menu.logout': 'Выйти',
  'menu.document': 'Документ',
  'form.expiration.prefix': 'Новый код:',
  'form.expiration.min': 'мин',
  'form.expiration.postfix': 'сек',
  'phoneForm.next': 'Далее',
  'phoneForm.label': 'Введите Ваш номер телефона',
  'pinCodeForm.back': 'Номер телефона',
  'pinCodeForm.requestNewCode': 'Выслать новый код',
  'pinCodeForm.label': 'Введите код из SMS',
  'pinCodeForm.label_2': 'Введите 4-значный PIN',
  'pinCodeForm.label_3': 'Код направлен на номер',
  'pinCodeForm.label.repeate': 'Повторите PIN-код',
  'phoneForm.error.404': 'Пользователь с таким телефоном не найден',
  'phoneForm.error.422': 'Некорректные данные',
  'phoneForm.error.429': 'Слишком частый запрос на отправку PIN. Попробуйте через 3 минуты',
  'phoneForm.error.500': 'Внутренняя ошибка сервера, попробуйте позже',
  'phoneForm.error.502': 'Внутренняя ошибка сервера, попробуйте позже',
  'pinCodeForm.error.404': 'Некорректный PIN',
  'pinCodeForm.error.403': 'Некорректный или просроченный PIN!',
  'pinCodeForm.error.500': 'Внутренняя ошибка сервера, попробуйте позже',
  'pinCodeForm.error.502': 'Внутренняя ошибка сервера, попробуйте позже',
  'pinCodeForm.error.429': 'Слишком частый запрос на подтверждение PIN. Попробуйте через 3 минуты',
  'pinCodeForm.absent.wereAbsent': 'Похоже, что Вы отсутствовали.',
  'pinCodeForm.absent.completedSession': 'Мы завершили Ваш сеанс в личном кабинете.',
  'pinCodeForm.absent.sendSms': 'Отправить SMS',
  'pinCodeForm.absent.resendSms': 'Повторно отправьте SMS, чтобы продолжить.',
  'profile.welcome': 'Добро пожаловать',
  'profile.loading': 'Загрузка вашего профиля…',
  'profile.locale': 'Русский',
  'profile.language': 'Язык',
  'profile.version': 'Версия',
  'profile.yes': 'Да',
  'profile.no': 'Нет',
  'profile.changeLocale': 'Изменить язык интерфейса?',
  'profile.message': 'сообщение',
  'profile.mobile': 'телефон',
  'profile.email': 'email',
  'profile.tamtam': 'тамтам',
  'mobile.text': 'Для использования BoardApp с вашего мобильного устройства скачайте приложение по ссылке ниже',
  'mobile.download': 'Скачать',
  'meetings.types.all': 'Все встречи',
  'meetings.groups.upcoming': 'Будущие встречи',
  'meetings.groups.today': 'Сегодняшние встречи',
  'meetings.groups.passed': 'Прошедшие встречи',
  'meeting.myFiles': 'Мои документы',
  'meeting.meetingFiles': 'Материалы заседания',
  'meeting.noDocuments': 'На данный момент файлов нет',
  'meeting.engDocuments': 'Английские файлы',
  'meeting.rusDocuments': 'Русские файлы',
  'meeting.downloadAllEngDocuments': 'Загрузить файлы (Английские)',
  'meeting.downloadAllRusDocuments': 'Загрузить файлы (Русские)',
  'meeting.minutesDocuments': 'Протоколы',
  'meeting.files': 'Файлы',
  'meeting.archive': 'Архивированные файлы',
  'time.at': 'в',
  'time.year': 'года',
  'time.today': 'Сегодня',
  'search.results': 'Результаты поиска',
  'search.results.matching': 'Результаты поиска не найдены:',
  'app.results': 'Ничего не найдено',
  'files.filters.all': 'Все файлы',
  'files.filters.myComments': 'Мои комментарии',
  'files.created': 'Cоздан',
  'files.updated': 'Обновлён',
  'files.originals': 'Экспорт',
  'files.download': 'Загрузить',
  'files.new': 'Новый',
  'files.responsible': 'Ответственный',
  'files.download.original': 'Оригинал',
  'files.download.original.many': 'Оригиналы',
  'files.download.converted': 'Сконвертированный PDF',
  'files.download.converted.many': 'Сконвертированные PDF',
  'showMore.more': 'Показать больше',
  'showMore.less': 'Показать меньше',
  'textarea.placeholder.comment': 'Комментарий',
  'comment.create': 'Создать',
  'comment.remove': 'Удалить',
  'comment.edit': 'Редактировать',
  'comment.private': 'Приватный',
  'loggedOut.description': 'Вы не использовали приложение более 10 минут. Ваша сессия будет завершена через',
  'loggedOut.continueWork': 'Чтобы продолжить работу нажмите Продолжить',
  'loggedOut.continue': 'Продолжить',
  'comments.ballon': 'Кликните на страницу, чтобы оставить комментарий',
  'pdfDocument.printWithoutNotes': 'Печать без заметок',
  'members.boardOfDirectors': 'Совет директоров',
  'members.management': 'Менеджмент',
  'members.noMembers': 'На данный момент нет участников',
  'reports.report.created': 'Создан {date, date, medium} в {date, time, short}',
  'reports.report.updated': 'Обновлен {date, date, medium} в {date, time, short}',
  'reports.report.file_updated': '{date, date, medium}, {date, time, short}',
  'reports.report.eng': 'Eng файлы',
  'reports.report.rus': 'Rus файлы',
  'reports.report.search_result_title': 'Результат поиска файла в отчетах',
  'reports.report.search_result_subtitle': 'Найдено {count}',
  'reports.types.all': 'Все отчеты',
};
