import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { File } from '../../../utils/file';
import * as routes from '../../../constants/routes';
import AsyncImage from '../../../components/AsyncImage';
import MaskIcon from '../../../components/Icons/MaskIcon';
import Responsible from '../../../components/FilesList/Responsible';
import FileUtils from '../../../utils/file';

const Item = (props: PropsType) => {
  const { value, onSelectFolder, cn } = props;

  const history = useHistory();

  const downloadFile = () => {
    return File.download(value.url, value.name);
  };

  if (value.group === 'folder') {
    return (
      <div className={cn('folder')} onClick={onSelectFolder}>
        <div className={cn('folder-icon')} />
        <div>
          <div className={cn('folder-title')}>{value.name}</div>
          <div className={cn('folder-created')}>
            <FormattedMessage
              id="reports.report.created"
              values={{ date: new Date(value.updatedAt), time: new Date(value.updatedAt) }}
            />
          </div>
        </div>
      </div>
    );
  }

  const isOpenable = value?.extension === 'pdf';
  const documentUrl = `${routes.document}/${value.id}/`;
  const openDocument = () => {
    if (isOpenable) {
      history.push(documentUrl);
    }
  };

  return (
    <div className={cn('document')}>
      <span className={cn('document-new', { show: !value.isDownloaded })} />
      <div className={cn('preview')}>
        <div className={cn('type-labels')}>
          {value.commentsCount > 0 && <div className={cn('type-comment')} />}
          {value.isArchive && <div className={cn('type-archive')} />}
        </div>
        <div className={cn('image-container')}>
          {value.previewUrl && FileUtils.isPdfOrDocFile(value.name) ? (
            <Link className={cn('link')} title={value.name} to={documentUrl}>
              <AsyncImage classNames={cn('image')} url={value.previewUrl} />
            </Link>
          ) : (
            <div className={cn('image-default')}>
              <MaskIcon className={cn('image-default-ico')} />
            </div>
          )}
        </div>
      </div>
      <div className={cn('document-description')}>
        <div className={cn('document-title', { openable: isOpenable })} onClick={openDocument}>
          {value.name}
        </div>
        <div className={cn('document-subtitle')}>
          <FormattedMessage id="reports.report.file_updated" values={{ date: new Date(value.uploadedDateTime) }} />
        </div>
        {value.responsible?.length > 0 && (
          <div className={cn('responsible')}>
            {value.responsible.map((el, i) => (
              <Fragment key={el.id}>
                <Responsible className={cn('responsible--name')} responsible={el} />
                {i < value.responsible.length - 1 && <span className={cn('responsible--comma')}>, </span>}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      <span className={cn('download-button')} onClick={downloadFile} />
    </div>
  );
};

type PropsType = {
  value: any;
  onSelectFolder: (...args: any[]) => any;
  cn: any;
};

export default Item;
