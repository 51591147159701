import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import Root from './components/app/Root';

import './styles/index.scss';

const appEl = document.getElementById('app') || document.createElement('div');

ReactModal.setAppElement(appEl);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  appEl
);
