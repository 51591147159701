import React, { Component } from 'react';
import shallowequal from 'shallowequal';
import { injectIntl } from 'react-intl';

import MeetingListItemContainer from '../../containers/MeetingListItemContainer';

import { CANCELED } from '../../constants/meetingStatuses';
import cn from '../../utils/cn';

import './styles.scss';

@cn('list-group')
class ListGroup extends Component {
  dayTitleRef = React.createRef();

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'getTimeTitle' does not exist on type 'Re... Remove this comment to see the full error message
    this.props.getTimeTitle(this.dayTitleRef.current);
  }

  componentDidUpdate(prevProps: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'list' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (!shallowequal(this.props.list, prevProps.list)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getTimeTitle' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.getTimeTitle(this.dayTitleRef.current);
    }
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'list' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      list,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'nameId' does not exist on type 'Readonly... Remove this comment to see the full error message
      nameId,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetingModClass' does not exist on type ... Remove this comment to see the full error message
      meetingModClass,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'intl' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      intl,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isToday' does not exist on type 'Readonl... Remove this comment to see the full error message
      isToday,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
      isShowCanseledMeetings,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getOffTop' does not exist on type 'Reado... Remove this comment to see the full error message
      getOffTop,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getOffTop' does not exist on type 'Reado... Remove this comment to see the full error message
      entity,
    } = this.props;
    const filteredList = list.filter(item => (!isShowCanseledMeetings ? item.status !== CANCELED : true));

    // prepare list - same from admin
    list.forEach(item => {
      item.orderId = item.orderId ? item.orderId : list.length;
    });

    list.sort((a, b) => a.orderId - b.orderId);

    list.forEach((item, index) => {
      item.orderId = index + 1;
    });

    return (
      <div className={cn()}>
        {nameId && (
          /* @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLHead... Remove this comment to see the full error message */
          <h2 ref={this.dayTitleRef} id={nameId} className={cn('title')}>
            {intl.formatMessage({ id: nameId })}
          </h2>
        )}
        {filteredList.map(item => (
          <MeetingListItemContainer
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isToday' does not exist on type '(Intrin... Remove this comment to see the full error message
            isToday={isToday}
            intl={intl}
            modClass={meetingModClass}
            className={cn('meeting-list-item')}
            key={item.id}
            item={item}
            getOffTop={getOffTop}
            entity={entity}
          />
        ))}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof ListGroup' is not assignable to type ... Remove this comment to see the full error message
export default injectIntl(ListGroup);
