import React from 'react';

import withScrollList from '../../HOC/withScrollList';
import withGroupList from '../../HOC/withGroupList';
import ListGroup from '../../../components/ListGroup';

import { LIST_LIMIT } from '../../../constants/list';

type PropsType = {
  reports?: any[];
  cn?: any;
  getOffTop?: (...args: any[]) => any;
  getTimeTitle?: (...args: any[]) => any;
  isShowCanseledMeetings?: boolean;
  handleOnScroll?: (...args: any[]) => any;
  entity: string;
};

const ReportsList = (props: PropsType) => {
  const { reports, cn, getOffTop, getTimeTitle, isShowCanseledMeetings, handleOnScroll, entity } = props;

  if (!reports) {
    return null;
  }

  return (
    <div>
      <ListGroup
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
        isShowCanseledMeetings={isShowCanseledMeetings}
        className={cn('list-group')}
        list={reports.map(({ date, updatedAt, ...report }) => ({
          ...report,
          createdDate: date,
          updatedAt: new Date(+updatedAt),
          // is used to display in the right panel
          date: new Date(+updatedAt),
        }))}
        meetingModClass="meeting-list-item--upcoming"
        getOffTop={getOffTop}
        getTimeTitle={getTimeTitle}
        handleOnScroll={handleOnScroll}
        entity={entity}
      />
    </div>
  );
};

export default withGroupList(withScrollList({ limit: LIST_LIMIT })(ReportsList)) as any;
