import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';
import { getMore, getList, getMoreUp, setActiveList as setActiveMeeting } from './list';

export const loadMeetings =
  (limit, offset, { date, type, query, filter }) =>
  dispatch => {
    return dispatch(getList(limit, offset, { date, type, query, filter }));
  };

export const loadMeetingsMore = (limit, offset) => dispatch => {
  return dispatch(getMore(limit, offset));
};

export const loadMeetingsMoreUp = (limit, offset) => dispatch => {
  return dispatch(getMoreUp(limit, offset));
};

const requestMeeting = id => ({
  [CALL_API]: {
    types: [types.MEETING_GET_REQUEST, types.MEETING_GET_SUCCESS, types.MEETING_GET_FAILURE],
    endpoint: `meeting/${id}`,
    method: 'GET',
  },
});

export const getMeeting = meetingId => dispatch => {
  return dispatch(requestMeeting(meetingId));
};

export const selectMeeting = meeting => dispatch => {
  dispatch(setActiveMeeting(meeting));
  return dispatch(requestMeeting(meeting.id));
};
