import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import cn from '../../utils/cn';

import './styles.scss';

@cn('no-results')
class NoResults extends Component {
  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'titleId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { titleId, query, intl } = this.props;
    return (
      <div className={cn()}>
        <div className={cn('report-icon')}></div>
        <h1 className={cn('title')}>{intl.formatMessage({ id: titleId })}</h1>
        {query && <h1 className={cn('query')}>&laquo;{query}&raquo;</h1>}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof NoResults' is not assignable to type ... Remove this comment to see the full error message
export default injectIntl(NoResults);
