import React from 'react';

const CloseIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" {...props}>
    <path
      fill="#999"
      fillRule="nonzero"
      d="M5.31 4.5L9 8.19 8.19 9 4.5 5.31.81 9 0 8.19 3.69 4.5 0 .81.81 0 4.5 3.69 8.19 0 9 .81z"
    />
  </svg>
);

export default CloseIcon;
