import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import AsyncImage from '../../../components/AsyncImage';
import MaskIcon from '../../../components/Icons/MaskIcon';
import Breadcrumbs from './Breadcrumbs';
import FileUtils from '../../../utils/file';

import * as routes from '../../../constants/routes';
import * as locales from '../../../constants/locales';

const SearchModal = (props: PropsType) => {
  const { cn, intl, locale } = props;
  const location = useLocation<LocationState>();
  const history = useHistory();
  const searchResult = useSelector((store: StoreType) => store.reports.documentSearchResult);
  const closeModal = () => {
    history.push(location.pathname, { modal: undefined });
  };
  const getFolderPath = (doc, folder) => {
    if (doc.folderId && folder.level > 1) {
      return doc.folderId;
    } else if (folder) {
      return folder.type;
    }

    return 'eng';
  };

  const selectResult = useCallback(
    (reportId, folderKey) => () => {
      history.push(`${routes.reports}/${reportId}/${folderKey}`, { modal: undefined });
    },
    [history]
  );

  const isOpen = location?.state?.modal === 'search';

  if (!searchResult) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="report-search-modal"
      overlayClassName="report-search-overlay"
      shouldFocusAfterRender={false}
    >
      <div className={cn('report-search')}>
        <div className={cn('report-search-result-title')}>
          <FormattedMessage id="reports.report.search_result_title" />
        </div>
        <div className={cn('report-search-result-subtitle')}>
          <FormattedMessage id="reports.report.search_result_subtitle" values={{ count: searchResult.total }} />
        </div>
        <button className={cn('close-button')} onClick={closeModal} />
        <Scrollbars
          style={{ width: '100%', height: 500 }}
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {Object.keys(searchResult.items).map((reportKey, index) => {
            const report = searchResult.items[reportKey];
            const reportName = convertReportName(
              locale === locales.EN ? report[0].report.englishName : report[0].report.russianName
            );
            return (
              <React.Fragment key={`search_report${index}`}>
                <div className={cn('report-search-name')}>{reportName}</div>
                <div className={cn('report-search-documents')}>
                  <div>
                    {report.map((doc, index) => {
                      const { folder, report } = doc;
                      const documentUrl = `${routes.document}/${doc.id}/`;
                      const reportName = intl.locale === locales.EN ? report?.englishName : report?.russianName;
                      const crumbs =
                        folder.pathToRoot.length > 1
                          ? [...folder.pathToRoot, { id: folder.id, name: folder.name }]
                          : folder.pathToRoot;
                      return (
                        <div key={`doc_${index}`} className={cn('report-search-document')}>
                          <span className={cn('document-new', { show: !doc.isDownloaded })} />
                          <div className={cn('preview')} style={{ background: '#ededed', marginRight: 20 }}>
                            <div className={cn('type-labels')}>
                              {doc.isCommented && <div className={cn('type-comment')} />}
                              {doc.isArchive && <div className={cn('type-archive')} />}
                            </div>
                            <div className={cn('image-container')}>
                              {doc.previewUrl && FileUtils.isPdfOrDocFile(doc.name) ? (
                                <Link className={cn('link')} title={doc.name} to={documentUrl}>
                                  <AsyncImage classNames={cn('image')} url={doc.previewUrl} />
                                </Link>
                              ) : (
                                <div className={cn('image-default')}>
                                  <MaskIcon className={cn('image-default-ico')} />
                                </div>
                              )}
                            </div>
                          </div>
                          <div onClick={selectResult(doc.report.id, getFolderPath(doc, folder))}>
                            <div className={cn('report-search-document-title')}>{doc.name}</div>
                            <div className={cn('report-search-document-subtitle')}>
                              <FormattedMessage
                                id="reports.report.file_updated"
                                values={{ date: new Date(doc.uploadedDateTime) }}
                              />
                            </div>
                            {doc.responsible && (
                              <div className={cn('report-search-document-subtitle')}>
                                {intl.locale === locales.EN ? doc.responsible.englishName : doc.responsible.russianName}
                              </div>
                            )}
                            <Breadcrumbs
                              type={folder.type}
                              crumbs={crumbs}
                              onClick={() => {}}
                              reportName={reportName}
                              cn={cn}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </Scrollbars>
      </div>
    </Modal>
  );
};

interface LocationState {
  modal: string;
}

type StoreType = {
  reports: any;
};

type PropsType = {
  cn: any;
  locale: any;
} & WrappedComponentProps;

const convertReportName = (name: string) => name.split('#')[0];

export default injectIntl(SearchModal);
