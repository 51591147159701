import React, { PureComponent } from 'react';

import Header from '../Header';
import LoggedOutModalOverlay from '../LoggedOutModalOverlay';

class Content extends PureComponent {
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'withHeader' does not exist on type 'Read... Remove this comment to see the full error message
    const { children, withHeader } = this.props;
    return (
      <div className="app">
        {withHeader && (
          <div className="app__header">
            <div className="container">
              <Header />
            </div>
          </div>
        )}
        <div className="app__content">
          <div className="container">{children}</div>
        </div>
        <div className="app__content">
          <LoggedOutModalOverlay />
        </div>
      </div>
    );
  }
}

export default Content;
