const Storage = {
  get(name) {
    return sessionStorage.getItem(`${name}`);
  },

  set(name, value) {
    sessionStorage.setItem(`${name}`, value);
  },

  delete(name) {
    this.set(name, '');
  },

  deleteKey(name) {
    sessionStorage.removeItem(name);
  },
};

export default Storage;
