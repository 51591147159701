import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';
import { LIST_LIMIT } from '../constants/list';

export function clearList() {
  return {
    type: types.CLEAR_LIST,
  };
}

export const setActiveList = item => ({
  type: types.LIST_SET_ACTIVE,
  item,
});

export const searchListItems = (params, isMore = false) => {
  const paramsStr = `?limit=${params.limit >= 0 ? params.limit : LIST_LIMIT}&offset=${params.offset || 0}`;
  const actionTypes = isMore
    ? [types.LIST_MORE_REQUEST, types.LIST_MORE_SUCCESS, types.LIST_MORE_FAILURE]
    : [types.GET_LIST_REQUEST, types.GET_LIST_SUCCESS, types.GET_LIST_FAILURE];

  return {
    [CALL_API]: {
      types: actionTypes,
      endpoint: `/meeting/search${paramsStr}`,
      body: {
        name: params.query || '',
        date: params.date || '',
        type: params.type === 'all' ? '' : params.type,
        filter: params.filter || '',
      },
    },
  };
};

export function getList(limit, offset, params) {
  return dispatch => {
    dispatch(clearList());
    return dispatch(searchListItems({ limit, offset, ...params }, false));
  };
}

export function getMore(limit = LIST_LIMIT, offset) {
  return (dispatch, getState) => {
    const {
      filters: { query, type, filter },
    } = getState();

    if (filter === 'date') {
      return getState();
    }

    return dispatch(
      searchListItems(
        {
          limit,
          offset,
          query,
          type,
          filter,
        },
        true
      )
    );
  };
}

function onScrollUp(limit = LIST_LIMIT, offset, query = '', type = '', filter = '') {
  const params = `?limit=${limit}&offset=${offset}`;
  return {
    [CALL_API]: {
      types: [types.LIST_MORE_UP_REQUEST, types.LIST_MORE_UP_SUCCESS, types.LIST_MORE_UP_FAILURE],
      endpoint: `/meeting/search${params}`,
      body: {
        name: query || '',
        type: type === 'all' ? '' : type,
        filter: filter || '',
      },
    },
  };
}

export function getMoreUp(limit = LIST_LIMIT, offset) {
  return (dispatch, getState) => {
    const {
      filters: { query, type, filter },
    } = getState();

    if (filter === 'date') {
      return getState();
    }

    return dispatch(onScrollUp(limit, offset, query, type, filter));
  };
}

/*
export function onChangeFilterDate(limit, offset, date, isReport = false) {
  return dispatch => {
    dispatch({
      type: types.LIST_TOGGLE_SEARCH_BY_DATE,
      data: {
        byDate: true
      }
    });
    dispatch({
      type: types.CALENDAR_SELECT_DATE,
      data: {
        selectedDate: date
      }
    });
    return dispatch(searchListItems({ limit, offset, date }, false, isReport)).then(() => {});
  };
} */
