import React from 'react';
import moment from 'moment';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import Search from '../../components/Search';
import MeetingsType from '../../components/MeetingsType';
import ReportTypes from '../../components/ReportTypes';
import DateFilter from '../../components/DateFilter';

import { getEntitiesByYear, filterSet } from '../../actions/filters';
import { getList } from '../../actions/committee';
import { getList as getReportList } from '../../actions/reports';
import { searchDocument } from '../../actions/reports';

import { searchBy as searchSection } from '../../constants/search';
import { appConfig } from '../../config';

import './styles.scss';

let DEFAULT_TYPE = {
  id: 'all',
  nameId: 'meetings.types.all',
};

type MainFiltersProps = {
  location?: any;
  history?: any;
  isTypeFilrter?: boolean;
  isDateFilter?: boolean;
  isSearchFilter?: boolean;
  intl: IntlShape;
  list?: any[];
  locale?: string;
  query?: string;
  includeDates?: any[];
  getList: (...args: any[]) => any;
  getReportList: (...args: any[]) => any;
  searchDocument: (...args: any[]) => any;
  getMeetingsByYear: (...args: any[]) => any;
  filterSet: (...args: any[]) => any;
  searchBy?: string;
  filterReportsType?: any;
  reportTypes?: any[];
};

type MainFiltersState = any;

class MainFilters extends React.PureComponent<MainFiltersProps, MainFiltersState> {
  constructor(props: MainFiltersProps) {
    super(props);

    if (this.props.filterReportsType) {
      DEFAULT_TYPE.nameId = 'reports.types.all';
    } else {
      DEFAULT_TYPE.nameId = 'meetings.types.all';
    }
    this.setStateType(DEFAULT_TYPE);

    this.state = {
      selectedType: DEFAULT_TYPE,
      selectedDate: moment(),
      query: '',
      searchBy: this.props.searchBy,
      location: this.props.location.pathname,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.query !== prevState.query) {
      return {
        query: nextProps.query,
      };
    }
    if (nextProps.searchBy !== prevState.searchBy) {
      return {
        searchBy: nextProps.searchBy,
        query: '',
      };
    }
    if (nextProps.location.pathname !== prevState.location.pathname) {
      return {
        location: nextProps.location.pathname,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.getList(2000, 0);
  }

  componentDidUpdate(prevProps: MainFiltersProps, prevState: MainFiltersState) {
    if (prevState.searchBy !== this.state.searchBy) {
      this.props.filterSet({ query: '', searchBy: this.state.searchBy });
    }
    if (prevProps.filterReportsType !== this.props.filterReportsType) {
      if (this.props.filterReportsType) {
        DEFAULT_TYPE.nameId = 'reports.types.all';
      } else {
        DEFAULT_TYPE.nameId = 'meetings.types.all';
      }
      this.setStateType(DEFAULT_TYPE);
    }
  }

  setStateDate = date => {
    this.setState({
      selectedDate: date,
    });
  };

  setStateFilter = filter => {
    this.setState({
      selectedFilter: filter,
    });
  };

  setStateType = type => {
    this.setState({
      selectedType: type,
    });
  };

  refreshReportFilters = typesIds => {
    this.props.getReportList(0, 0, {
      typesIds,
    });
  };

  setStateQuery = query => {
    this.setState({ query });
  };

  handleSelectType = type => {
    this.setStateType(type);
    this.setStateDate(moment());
    // this.setStateQuery('');
    this.props.filterSet({ type: type.id });
  };

  handleChangeDate = date => {
    this.setStateType(DEFAULT_TYPE);
    this.setStateFilter('date');
    this.setStateDate(moment(date));
    // this.setStateQuery('');
    this.props.filterSet({ date: moment(date), filter: 'date' });
  };

  handleChangeYear = year => {
    this.props.getMeetingsByYear(year, appConfig.timeZoneNumForListByYear);
  };

  handleSearch = query => {
    switch (this.props.searchBy) {
      case searchSection.MEETINGS:
        this.seachByMeetings(query);
        break;
      case searchSection.FILES:
        this.seachByFiles(query);
        break;
      case searchSection.REPORTS:
        this.seachByReports(query);
        break;
      case searchSection.MEMBERS:
        this.seachByMembers(query);
        break;
      default:
        break;
    }
  };

  seachByMeetings = query => {
    this.setStateType(DEFAULT_TYPE);
    this.setStateDate(moment());
    if (query.length >= 3) {
      this.props.filterSet({ query, searchBy: this.state.searchBy });
    } else if (query.length === 0) {
      this.props.filterSet({ type: DEFAULT_TYPE.id });
    }
  };

  seachByFiles = query => {
    if (query.length >= 3) {
      this.props.filterSet({ query, searchBy: this.state.searchBy });
    } else if (query.length === 0) {
      this.props.filterSet({ query: '', searchBy: this.state.searchBy });
    }
  };

  seachByReports = async query => {
    if (query.length >= 3) {
      this.props.filterSet({ query, searchBy: this.state.searchBy });
      await this.props.searchDocument(query);
      if (this.props.location?.state?.modal !== 'search') {
        this.props.history.push(this.props.location.pathname, { modal: 'search' });
      }
    } else if (query.length === 0) {
      this.props.filterSet({ query: '' });
      this.props.history.push(this.props.location.pathname, { modal: undefined });
    }
  };

  seachByMembers = query => {
    if (query.length >= 3) {
      this.props.filterSet({ query, searchBy: this.state.searchBy });
    } else if (query.length === 0) {
      this.props.filterSet({ query: '', searchBy: this.state.searchBy });
    }
  };

  render() {
    return (
      <div className="filters">
        {this.props.isTypeFilrter && (
          <div className="filters__type">
            <MeetingsType
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'intl' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
              intl={this.props.intl}
              onSelectType={this.handleSelectType}
              selectedType={this.state.selectedType}
              // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '{ id: ... Remove this comment to see the full error message
              list={[DEFAULT_TYPE].concat(this.props.list)}
              locale={this.props.locale}
            />
          </div>
        )}
        {this.props.filterReportsType && (
          <div className="filters__type">
            <ReportTypes
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'intl' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
              intl={this.props.intl}
              onSelectType={this.handleSelectType}
              selectedType={this.state.selectedType}
              list={this.props.reportTypes}
              locale={this.props.locale}
              refreshReportFilters={this.refreshReportFilters}
            />
          </div>
        )}
        {this.props.isDateFilter && (
          <div className="filters__date">
            <DateFilter
              locale={this.props.locale}
              onChangeYear={this.handleChangeYear}
              onChangeDate={this.handleChangeDate}
              includeDates={this.props.includeDates}
              selectedDate={this.state.selectedDate}
            />
          </div>
        )}
        {this.props.isSearchFilter && (
          <div className="filters__search">
            <Search
              isClear={!this.state.query || (this.state.query && this.state.length === 0)}
              query={this.state.query}
              onChange={this.handleSearch}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ i18n, committeeList, filters, scrollableList, reports }) => ({
  list: committeeList.items,
  isFetching: committeeList.isFetching,
  locale: i18n.locale.toString(),
  includeDates: filters.items,
  query: filters.query,
  meetings: scrollableList.items,
  offset: scrollableList.offset,
  reportTypes: reports.types,
});

const mapDispatchToProps = {
  getList,
  getReportList,
  searchDocument,
  getMeetingsByYear: getEntitiesByYear,
  filterSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainFilters));
