import React from 'react';
import cn from 'classnames';

import withLoading from '../../containers/HOC/withLoading';

import * as locales from '../../constants/locales';
import truncate from '../../utils/truncate';

import './styles.scss';
import '../../styles/blocks/select.scss';
import '../../styles/global.scss';

type Props = {
  intl?: any;
  selectedType?: any;
  list?: any[];
  locale?: string;
  onSelectType: (...args: any[]) => any;
  refreshReportFilters: (...args: any[]) => any;
};

type State = any;

class ReportTypes extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      types: [],
    };
  }
  renderName = type => {
    if (type.nameId) {
      return this.props.intl.formatMessage({ id: type.nameId });
    }
    return type[this.props.locale === locales.RU ? 'russianName' : 'englishName'];
  };

  renderCheckboxFields = cn => (
    <ul className="select__dropdown meetings-type__dropdown">
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      {this.props.list.map((item: any) => (
        <li key={item.id} className={cn('report-types')}>
          <label className={cn('label')}>
            {truncate(this.renderName(item), 30)}
            <input
              id={item.id}
              type="checkbox"
              className={cn('checkbox')}
              onClick={(e: any) => {
                const types = this.state.types;
                if (e.target.checked) {
                  types.push(item.id);
                } else {
                  const typeIndex = types.findIndex(t => t === item.id);
                  types.splice(typeIndex, 1);
                }
                this.props.refreshReportFilters(types);
                this.setState(types);
              }}
            />
            <span className={cn('checkmark')} />
          </label>
        </li>
      ))}
    </ul>
  );

  render() {
    return (
      <div className="select meetings-type">
        <div className="select__title meetings-type__title">
          {this.renderName(truncate(this.props.selectedType, 30))}
        </div>
        {this.renderCheckboxFields(cn)}
      </div>
    );
  }
}

export default withLoading(ReportTypes);
