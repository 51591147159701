import React from 'react';
import cn from 'classnames';

import withLoading from '../../containers/HOC/withLoading';

import * as locales from '../../constants/locales';
import truncate from '../../utils/truncate';

import './styles.scss';
import '../../styles/blocks/select.scss';

type Props = {
  intl?: any;
  selectedType?: any;
  list?: any[];
  locale?: string;
  onSelectType: (...args: any[]) => any;
};

class MeetingsType extends React.PureComponent<Props> {
  renderName = type => {
    if (type.nameId) {
      return this.props.intl.formatMessage({ id: type.nameId });
    }
    return type[this.props.locale === locales.RU ? 'russianName' : 'englishName'];
  };

  render() {
    return (
      <div className="select meetings-type">
        <div className="select__title meetings-type__title">
          {this.renderName(truncate(this.props.selectedType, 30))}
        </div>
        <ul className="select__dropdown meetings-type__dropdown">
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {this.props.list.map(item => (
            <li
              key={item.id}
              onClick={() => this.props.onSelectType(item)}
              className={cn('select__dropdown-item', {
                'select__dropdown-item--selected': this.props.selectedType.id === item.id,
              })}
            >
              {truncate(this.renderName(item), 30)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withLoading(MeetingsType);
