import React, { Component } from 'react';
import cn from 'classnames';

import './styles.scss';

class Avatar extends Component {
  renderImage = (cn, avatarUrl, className, size) => (
    <img
      alt=""
      className={cn('avatar', className, { [`avatar--size-${size}`]: size })}
      src={avatarUrl || require('../../assets/images/default-avatar.png')}
    />
  );

  renderInitials = (cn, userName, className, size) => {
    const initials = userName
      .split(' ')
      .filter(s => s && s.length > 0)
      .map(s => s[0].toUpperCase());

    return (
      <div className={cn('avatar', className, { [`avatar--size-${size}`]: size }, 'avatar--initials')}>
        <span>{initials.join('')}</span>
      </div>
    );
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'avatarUrl' does not exist on type 'Reado... Remove this comment to see the full error message
    const { avatarUrl, userName, className, size } = this.props;
    return !avatarUrl || avatarUrl === ''
      ? this.renderInitials(cn, userName, className, size)
      : this.renderImage(cn, avatarUrl, className, size);
  }
}

export default Avatar;
