import React from 'react';
import moment from 'moment';
import { LIST_LIMIT } from '../../constants/list';
import Loading from '../../components/Loading';
import NoResults from '../../components/NoResults';
import * as meetingStatuses from '../../constants/meetingStatuses';

function withMeetings(WrappedComponent, injectProps) {
  return class HOC extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isLoaded: false,
        isFetching: false,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        meetings: this.props.meetings || [],
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
        date: this.props.filters.date,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
        type: this.props.filters.type,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
        query: this.props.filters.query,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
        filter: this.props.filters.filter,
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.date !== nextProps.filters.date) {
        return {
          date: nextProps.filters.date,
          filter: nextProps.filters.filter,
        };
      }
      if (prevState.type !== nextProps.filters.type) {
        return {
          type: nextProps.filters.type,
        };
      }
      if (prevState.query !== nextProps.filters.query) {
        return {
          query: nextProps.filters.query,
        };
      }
      return null;
    }

    componentDidMount() {
      if (
        /* !this.state.meetings.length && this.props.location && */
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
        !this.props.location ||
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
        (this.props.location && !this.props.location.state) ||
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
        (this.props.location &&
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
          this.props.location.state &&
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
          !this.props.location.state.loaded)
      ) {
        setTimeout(() => {
          this.setState({ isFetching: true });
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
          const { date, type, query, filter } = this.props.filters;
          this.getMeetings({ date, type, query, filter }).then(() => {
            this.setState({ isLoaded: true, isFetching: false });
          });
        }, 200);
      } else {
        this.setState({ isLoaded: true });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'date' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      if (prevState.date !== this.state.date && this.state.date) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'date' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        this.getMeetings({ date: this.state.date, filter: this.state.filter });
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      if (prevState.type !== this.state.type && this.state.type) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        this.getMeetings({ type: this.state.type });
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
      if (prevState.query !== this.state.query && this.state.query) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
        this.getMeetings({ query: this.state.query });
      }
    }

    selectMeeting = () => {
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
        match: {
          params: { id },
        },
      } = this.props;
      let current = null;
      const startToday = moment().startOf('day');
      const endToday = moment().endOf('day');
      if (id) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        current = this.props.meetings.filter(item => parseInt(item.id, 10) === parseInt(id, 10))[0];
      }

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
      const nonCancelled = this.props.meetings.filter(({ status }) => status !== meetingStatuses.CANCELED);

      if (!current) {
        // сегодняшняя текущая
        current = nonCancelled.find(item => {
          const itemStart = moment(item.dateTimeStart);
          const itemEnd = moment(item.dateTimeEnd);
          return endToday.isBetween(itemStart, itemEnd) || itemEnd.isBetween(startToday, endToday);
        });
      }

      if (!current) {
        // будущая
        const prev = nonCancelled.filter(
          item => item.status !== meetingStatuses.CANCELED && moment(item.dateTimeEnd).isAfter(startToday)
        );
        current = prev.length ? prev[prev.length - 1] : null;
      }
      if (!current && nonCancelled.length) {
        current = nonCancelled[0];
      }
      if (current) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectMeeting' does not exist on type 'R... Remove this comment to see the full error message
        this.props.selectMeeting(current);
      }
    };

    getMeetings = filters => {
      return this.getStartMeetingsByFilters(filters).then(() => this.selectMeeting());
    };

    getStartMeetingsByFilters = params => {
      if (params.date && moment(params.date).format()) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetings' does not exist on type 'Re... Remove this comment to see the full error message
        return this.props.loadMeetings(LIST_LIMIT, 0, {
          date: moment(params.date).format(),
          filter: params.filter,
        });
      }

      if (params.query && params.query.length) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetings' does not exist on type 'Re... Remove this comment to see the full error message
        return this.props.loadMeetings(LIST_LIMIT, 0, { query: params.query });
      }

      if (params.type && params.type !== 'all') {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetings' does not exist on type 'Re... Remove this comment to see the full error message
        return this.props.loadMeetings(LIST_LIMIT, 0, { type: params.type });
      }

      return this.getMeetingsForGroups();
    };

    getMeetingsForGroups = () => {
      return this.getMeetingsForToday().then(() => this.getMeetingsUpcoming().then(() => this.getMeetingsPassed()));
    };

    getMeetingsPassed = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetingsMore' does not exist on type... Remove this comment to see the full error message
      return this.props.loadMeetingsMore(
        LIST_LIMIT,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.meetings.length + this.props.offset
      );
    };

    getMeetingsUpcoming = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.props.offset === 0) {
        return Promise.resolve();
      }

      const offset =
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.props.offset - LIST_LIMIT > 0 ? this.props.offset - LIST_LIMIT : 0;
      const limit =
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.props.offset - LIST_LIMIT > 0 ? LIST_LIMIT : this.props.offset;

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetingsMoreUp' does not exist on ty... Remove this comment to see the full error message
      return this.props.loadMeetingsMoreUp(limit, offset);
    };

    getMeetingsForToday = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetings' does not exist on type 'Re... Remove this comment to see the full error message
      return this.props.loadMeetings(LIST_LIMIT, 0, {
        date: moment().format(),
        type: 'all',
      });
    };

    render() {
      if (
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isLoaded' does not exist on type 'Readon... Remove this comment to see the full error message
        !this.state.isLoaded &&
        (!injectProps || !injectProps.noLoadingMessage)
      ) {
        return <Loading />;
      }

      if (
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        !this.props.meetings.length &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFetching' does not exist on type 'Read... Remove this comment to see the full error message
        !this.props.isFetching &&
        (!injectProps || !injectProps.noLoadingMessage)
      ) {
        return (
          <NoResults
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'titleId' does not exist on type 'Intrins... Remove this comment to see the full error message
            titleId={
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
              this.state.query ? 'search.results.matching' : 'app.results'
            }
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
            query={this.state.query}
          />
        );
      }
      return (
        <WrappedComponent
          {...this.props}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isLoaded' does not exist on type 'Readon... Remove this comment to see the full error message
          isLoaded={this.state.isLoaded}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFetching' does not exist on type 'Read... Remove this comment to see the full error message
          isFetching={this.state.isFetching}
        />
      );
    }
  };
}

export default withMeetings;
