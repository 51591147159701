import {
  DOCUMENT_GET_REQUEST,
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_GET_FAILURE,
  DOCUMENT_EXIT,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAILURE,
  COMMENT_DELETE,
  COMMENT_EDIT_REQUEST,
  COMMENT_EDIT_SUCCESS,
  COMMENT_EDIT_FAILURE,
  COMMENT_ADD_REQUEST,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAILURE,
  SAVE_TMP_DRAW,
  CLEAR_TMP_DRAW,
  DRAW_ADD_REQUEST,
  DRAW_ADD_SUCCESS,
  DRAW_ADD_FAILURE,
} from '../constants/ActionTypes';

const defaultState = {
  currentDraw: [],
  comments: [],
  drawings: [],
  pages: [],
  id: null,
  name: '',
  previewUrl: '',
  previousFileId: null,
  sourceFileId: null,
  type: '',
  uploadedDateTime: null,
  url: '',
  version: null,
  isFetching: false,
  isAddFetching: false,
  isFetchingComment: false,
  error: null,
};

function getCurrentDraw(state, currentDraw) {
  let find = false;
  let newCurrentDraw = state.currentDraw.map(item => {
    if (item.pageNumber === currentDraw.pageNumber) {
      find = true;
      return currentDraw;
    }
    return item;
  });
  if (!find) {
    newCurrentDraw = state.currentDraw;
    newCurrentDraw.push(currentDraw);
  }
  return newCurrentDraw;
}

function updateDrawings(state, newDraw) {
  const oldDrawings = state.drawings;
  let find = false;
  const newDrawings = [];
  oldDrawings.forEach(draw => {
    if (draw.id === newDraw.id) {
      find = true;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      newDrawings.push(newDraw);
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      newDrawings.push(draw);
    }
  });
  if (!find) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    newDrawings.push(newDraw);
  }
  return newDrawings;
}

const document = (state = defaultState, action) => {
  switch (action.type) {
    case DOCUMENT_EXIT:
      return {
        ...defaultState,
      };
    case DOCUMENT_GET_REQUEST:
      return {
        ...state,
        currentDraw: [],
        isFetching: true,
      };
    case DOCUMENT_GET_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      };
    case DOCUMENT_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case COMMENT_DELETE_REQUEST:
      return {
        ...state,
        isFetchingComment: true,
      };
    case COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        isFetchingComment: false,
      };
    case COMMENT_DELETE_FAILURE:
      return {
        ...state,
        isFetchingComment: false,
        error: action.data.error,
      };
    case COMMENT_EDIT_REQUEST:
      return {
        ...state,
        isFetchingComment: true,
      };
    case COMMENT_EDIT_SUCCESS:
      return {
        ...state,
        comments: state.comments.map(item => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
          if (item.id === action.data.id) {
            return action.data;
          }
          return item;
        }),
        isFetchingComment: false,
      };
    case COMMENT_EDIT_FAILURE:
      return {
        ...state,
        isFetchingComment: false,
        error: action.data.error,
      };
    case COMMENT_ADD_REQUEST:
      return {
        ...state,
        isFetchingComment: true,
      };
    case COMMENT_ADD_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.data],
        isFetchingComment: false,
      };
    case COMMENT_ADD_FAILURE:
      return {
        ...state,
        isFetchingComment: false,
        error: action.data.error,
      };
    case COMMENT_DELETE:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        comments: state.comments.filter(item => item.id !== action.id),
      };
    case DRAW_ADD_REQUEST:
      return {
        ...state,
        isAddFetching: true,
      };
    case DRAW_ADD_SUCCESS:
      return {
        ...state,
        currentDraw: [
          ...state.currentDraw.filter(
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pageNumber' does not exist on type 'neve... Remove this comment to see the full error message
            draw => draw.pageNumber !== action.data.pageNumber
          ),
        ],
        drawings: updateDrawings(state, action.data),
        isAddFetching: false,
      };
    case DRAW_ADD_FAILURE:
      return {
        ...state,
        isAddFetching: false,
        error: action.error,
      };
    case CLEAR_TMP_DRAW:
      return {
        ...state,
        currentDraw: state.currentDraw.filter(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'pageNumber' does not exist on type 'neve... Remove this comment to see the full error message
          item => item.pageNumber !== action.pageNumber
        ),
      };
    case SAVE_TMP_DRAW:
      return {
        ...state,
        currentDraw: getCurrentDraw(state, action.currentDraw),
      };
    default:
      return state;
  }
};

export default document;
