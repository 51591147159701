import React from 'react';

const Breadcrumbs = (props: PropsType) => {
  const { cn, crumbs, onClick, reportName, type } = props;

  return (
    <div className={cn('breadcrumbs')}>
      <span className={cn('crumb')} onClick={onClick()}>
        {reportName}
      </span>
      <span className={cn('arrow')} />
      <span className={cn('crumb')} onClick={onClick()}>
        {type}
      </span>
      {crumbs.length > 0 && <span className={cn('arrow')} />}
      {crumbs
        .filter(crumb => crumb.level !== 1)
        .map((crumb, index, arr) => {
          const { id, name } = crumb;

          return (
            <React.Fragment key={`crumb_${index}`}>
              <span className={cn('crumb')} onClick={onClick(id)}>
                {name}
              </span>
              {index < arr.length - 1 && <span className={cn('arrow')} />}
            </React.Fragment>
          );
        })}
    </div>
  );
};

type PropsType = {
  crumbs: any[];
  onClick: (...args: any[]) => any;
  cn: any;
  reportName: string;
  type?: any;
};

export default Breadcrumbs;
