import has from 'lodash/has';
import get from 'lodash/get';

export const getOrigin = () => {
  let origin = '';

  if (has(window, 'location.hostname')) {
    origin = get(window, 'location.hostname', '');
  }

  return origin;
};

export const regexpText = (reg, str) => {
  return reg.search(str) !== -1;
};

export function isBooleanString(string) {
  return string === 'true' || string === 'false';
}

/*
  Base64 / binary data / UTF-8 strings utilities
  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
*/

/* Base64 string to array encoding */
export function uint6ToB64(nUint6) {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
    ? nUint6 + 71
    : nUint6 < 62
    ? nUint6 - 4
    : nUint6 === 62
    ? 43
    : nUint6 === 63
    ? 47
    : 65;
}

/* eslint no-bitwise: ["error", { "allow": ["&", "|=", "<<", ">>>"] }] */
export function base64EncArr(aBytes) {
  const eqLen = (3 - (aBytes.length % 3)) % 3;
  let sB64Enc = '';

  for (let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3;
    /* Uncomment the following line in order to split the output in lines 76-character long: */
    /*
    if (nIdx > 0 && (nIdx * 4 / 3) % 76 === 0) { sB64Enc += "\r\n"; }
    */
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCharCode(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63)
      );
      nUint24 = 0;
    }
  }

  return eqLen === 0 ? sB64Enc : sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? '=' : '==');
}
