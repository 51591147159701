import React from 'react';
import { Link } from 'react-router-dom';

import Tick1x from '../../assets/images/check.png';
import Tick2x from '../../assets/images/check@2x.png';
import Tick3x from '../../assets/images/check@3x.png';

require('./styles.scss');

type OwnProps = {
  users?: any[];
  isFetching?: boolean;
  showMinimal?: boolean;
  canDelete?: boolean;
  hideHead?: boolean;
  deleteFunc?: (...args: any[]) => any;
  rowClickFunc?: (...args: any[]) => any;
  locale?: string;
};

type Props = OwnProps & typeof UsersTable.defaultProps;

export class UsersTable extends React.Component<Props> {
  static defaultProps = {
    locale: 'en',
  };

  renderTick(type, user) {
    if ((type === 'member' && user.isMember === false) || (type === 'user' && user.isMember === true)) {
      return <img alt="tick-check-box" src={Tick1x} srcSet={`${Tick2x}, ${Tick3x}`} className="check" />;
    }
  }

  renderTableBody() {
    const { isFetching, users, showMinimal, canDelete, locale, rowClickFunc } = this.props;
    if (isFetching) {
      return (
        <div className="lds-rolling">
          <div />
        </div>
      );
    }

    return (
      <tbody>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {users.map((user, i) => {
          let userType = 'users';
          if (user.isMember === true) {
            userType = 'members';
          }

          return (
            <tr
              key={`${user.phone}_${userType}_${user.id}`}
              className={`user-item-row ${typeof rowClickFunc === 'function' ? 'pointer' : ''}`}
              onClick={() => typeof rowClickFunc === 'function' && rowClickFunc(user)}
            >
              <td className="user-list-avatar-box">{this.renderProfileImage(user)}</td>
              <td>
                <p className="user-name">{locale === 'en' ? user.englishName : user.russianName}</p>
                <p className="user-role">{locale === 'en' ? user.englishPosition : user.russianPosition}</p>
              </td>
              {!showMinimal && <td className="tick-box">{this.renderTick('user', user)}</td>}
              {!showMinimal && <td className="tick-box">{this.renderTick('member', user)}</td>}
              {!showMinimal && (
                <td>
                  <Link className="user-item-edit" to={`/${userType}/${user.id}`}>
                    Edit
                  </Link>
                </td>
              )}
              {canDelete && (
                // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                <td className="tick-box delete-pointer" onClick={() => this.props.deleteFunc(user.id)}>
                  ✖
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  }

  renderProfileImage(user) {
    const { locale } = this.props;

    if (!user.avatarUrl || user.avatarUrl === '') {
      const names = (locale === 'en' ? user.englishName : user.russianName).split(' ');
      let initials = '';
      for (let i = 0; i < names.length; i++) {
        initials += names[i][0];
      }
      return (
        <div className="media-left media-middle">
          <div
            className="user__photo"
            style={{
              color: 'white',
              backgroundColor: 'rgb(3,185, 86)',
              textAlign: 'center',
              lineHeight: '58px',
              fontSize: '21px',
            }}
          >
            {initials.toUpperCase()}
          </div>
        </div>
      );
    }
    return (
      <div className="media-left media-middle">
        <div
          className="user__photo"
          style={{
            backgroundImage: `url('/api${user.avatarUrl}')`,
          }}
        />
      </div>
    );
  }

  render() {
    const { showMinimal, canDelete, hideHead } = this.props;
    const getHeaderClassName = () => {
      if (hideHead) {
        return 'invheader';
      }

      return '';
    };

    return (
      <table style={{ width: '100%' }} className="userList">
        <thead>
          <tr className="user-head-row">
            <th style={{ width: '50px' }} className="invheader">
              Avatar
            </th>
            <th className={getHeaderClassName()}>Name, position</th>
            {!showMinimal && <th style={{ width: '150px', textAlign: 'center' }}>Member</th>}
            {!showMinimal && <th style={{ width: '150px', textAlign: 'center' }}>User</th>}
            {!showMinimal && <th className="invheader">Edit</th>}
            {canDelete && <th className="invheader">Delete</th>}
          </tr>
        </thead>
        {this.renderTableBody()}
      </table>
    );
  }
}
