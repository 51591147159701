import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import cn from '../../utils/cn';

import './styles.scss';

@cn('no-members')
class NoMembers extends Component {
  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { messageId, intl } = this.props;
    return (
      <div className={cn('container')}>
        <div className={cn('image')} />
        <div className={cn('message')}>{intl.formatMessage({ id: messageId })}</div>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof NoMembers' is not assignable to type ... Remove this comment to see the full error message
export default injectIntl(NoMembers);
