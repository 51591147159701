import React from 'react';
import cn from '../../utils/cn';
import withGroupList from '../../containers/HOC/withGroupList';
import withScrollList from '../../containers/HOC/withScrollList';
import ListGroup from '../ListGroup';
import * as GROUPS from '../../constants/listGroup';
import { LIST_LIMIT } from '../../constants/list';

type Props = {
  meetings?: any[];
  direction?: string;
  filters?: any;
  grouped?: any;
  isShowCanseledMeetings?: boolean;
  meeting?: any;
  getOffTop?: (...args: any[]) => any;
  handleOnScroll?: (...args: any[]) => any;
  getTimeTitle?: (...args: any[]) => any;
  entity?: any;
};

@cn('meetings-list')
class MeetingsList extends React.PureComponent<Props> {
  renderGroups = cn => {
    const { grouped, isShowCanseledMeetings, getOffTop, getTimeTitle, entity } = this.props;
    return (
      <>
        {grouped[GROUPS.UPCOMING].length > 0 && (
          <ListGroup
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
            isShowCanseledMeetings={isShowCanseledMeetings}
            className={cn('list-group')}
            list={grouped[GROUPS.UPCOMING]}
            meetingModClass="meeting-list-item--upcoming"
            nameId="meetings.groups.upcoming"
            getOffTop={getOffTop}
            getTimeTitle={getTimeTitle}
            entity={entity}
          />
        )}
        {grouped[GROUPS.TODAY].length > 0 && (
          <ListGroup
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
            isShowCanseledMeetings={isShowCanseledMeetings}
            isToday
            className={cn('list-group')}
            list={grouped[GROUPS.TODAY]}
            meetingModClass="meeting-list-item--today"
            nameId="meetings.groups.today"
            getOffTop={getOffTop}
            getTimeTitle={getTimeTitle}
            entity={entity}
          />
        )}
        {grouped[GROUPS.PASSED].length > 0 && (
          <ListGroup
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
            isShowCanseledMeetings={isShowCanseledMeetings}
            className={cn('list-group')}
            list={grouped[GROUPS.PASSED]}
            meetingModClass="meeting-list-item--passed"
            nameId="meetings.groups.passed"
            getOffTop={getOffTop}
            getTimeTitle={getTimeTitle}
            entity={entity}
          />
        )}
      </>
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { isShowCanseledMeetings, filters, grouped, getOffTop, handleOnScroll, getTimeTitle, entity } = this.props;

    return (
      <div className={cn()}>
        {filters.query && grouped[GROUPS.RESULTS] && grouped[GROUPS.RESULTS].length ? (
          <ListGroup
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isShowCanseledMeetings' does not exist o... Remove this comment to see the full error message
            isShowCanseledMeetings={isShowCanseledMeetings}
            className={cn('list-group')}
            list={grouped[GROUPS.RESULTS]}
            meetingModClass="meeting-list-item--results"
            nameId="search.results"
            getOffTop={getOffTop}
            getTimeTitle={getTimeTitle}
            handleOnScroll={handleOnScroll}
            entity={entity}
          />
        ) : (
          this.renderGroups(cn)
        )}
      </div>
    );
  }
}

export default withGroupList(withScrollList({ limit: LIST_LIMIT })(MeetingsList));
