import FileSaver from 'file-saver';
import printJS from 'print-js';
import Storage from './storage';

import { base64EncArr } from './utils';

export const FileDownloader = {
  prepareRequest(url) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.withCredentials = true;
    xhr.open('GET', url);

    xhr.setRequestHeader('Authorization', `Bearer: ${Storage.get('accessToken')}`);
    return xhr;
  },

  save(data, filename) {
    FileSaver.saveAs(data, filename);
  },
};

function requestFile(url: string) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.withCredentials = true;
    xhr.open('GET', url);

    xhr.setRequestHeader('Authorization', `Bearer: ${Storage.get('accessToken')}`);

    xhr.onload = resolve;
    xhr.onerror = reject;

    xhr.send();
  });
}

export const File = {
  getExtension(url) {
    if (!url) return null;

    return url.split('.').pop();
  },

  isExcelExtension(extension: string): boolean {
    return extension === 'xls' || extension === 'xlsx';
  },

  isWordExtension(extension: string): boolean {
    return extension === 'doc' || extension === 'docx';
  },

  isPdfOrDocFile(name: string): boolean {
    const extension = this.getExtension(name.toLowerCase());
    return (
      this.getExtension(extension) === 'doc' ||
      this.getExtension(extension) === 'docx' ||
      this.getExtension(extension) === 'pdf'
    );
  },

  changeExtension(filename, newExtension) {
    const pos = filename.lastIndexOf('.');
    const name = filename.substr(0, pos < 0 ? filename.length : pos);
    return `${name}.${newExtension}`;
  },

  printRawPdf(data) {
    return new Promise((resolve, reject) => {
      printJS({
        printable: data,
        type: 'pdf',
        base64: true,
        onError: reject,
        onPrintDialogClose: resolve,
        onPdfOpen: resolve,
        // for Firefox and other not supported browsers
        fallbackPrintable: `data:application/pdf;base64,${base64EncArr(data)}`,
      });
    });
  },

  download(url, name) {
    return new Promise((resolve, reject) => {
      requestFile(`/api${url}`).then(e => {
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        const { response } = e.target;
        if (!response) {
          reject();
          return;
        }

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response, name);
          reject();
          return;
        }

        const blob = new Blob([response]);

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;

        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Blob' is not assignable to param... Remove this comment to see the full error message
          window.URL.revokeObjectURL(blob);

          resolve();
        }, 100);
      });
    });
  },
};

export default File;
