import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

import ToManyError from '../ToManyError';
import Button from '../Button';

import * as errors from '../../constants/errors';
import * as timers from '../../constants/timers';

import '../../styles/blocks/login-form.scss';

const TIKIR_TIME = 1000;

type Props = {
  errorCode?: number;
  isFetching?: boolean;
  onSubmit: (...args: any[]) => any;
};

type State = any;

class PhoneForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: '',
      timer: false,
      isError: null,
      counter: 0,
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.timer !== prevState.timer && !this.state.timer) {
      return { isError: null, phone: '' };
    }
    if (this.state.phone !== prevState.phone) {
      return { isError: null };
    }
    if (this.props.errorCode && this.props.errorCode !== prevProps.errorCode) {
      return { isError: this.props.errorCode };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot) {
    if (snapshot !== null) {
      this.setState(snapshot);
    }

    if (this.props.errorCode !== prevProps.errorCode && this.props.errorCode === errors.ERROR_TO_MANY) {
      this.statrTiker(timers.TIMER_TO_MANE);
    }
  }

  statrTiker = timer => {
    this.setState(
      {
        timer: true,
        counter: timer,
      },
      () => this.ticker()
    );
  };

  // @ts-expect-error ts-migrate(2551) FIXME: Property 'tiker' does not exist on type 'PhoneForm... Remove this comment to see the full error message
  endTiker = () => clearInterval(this.tiker);

  ticker = () => {
    this.endTiker();
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'tiker' does not exist on type 'PhoneForm... Remove this comment to see the full error message
    this.tiker = setInterval(() => this.tick(), TIKIR_TIME);
  };

  tick = () => {
    if (this.state.counter - TIKIR_TIME > 0) {
      this.setState(state => {
        return {
          counter: state.counter - TIKIR_TIME,
        };
      });
    } else {
      this.endTiker();
      this.setState({
        timer: false,
        counter: 0,
      });
    }
  };

  onSubmitForm = e => {
    e.preventDefault();
    this.props.onSubmit(`${this.state.phone}`);
  };

  onInputChange = event => {
    const input = event.target;
    const phone = input.value.toString().replace(/\D/g, '');

    this.setState({
      phone,
    });
  };

  renderErrorsOrLabel = () =>
    !this.state.isError ? (
      <label className="login-form__label">
        <FormattedMessage id="phoneForm.label" />
      </label>
    ) : (
      <div className="login-form__error">
        <FormattedMessage id={`phoneForm.error.${this.state.isError}`} />
      </div>
    );

  renderForm = () => (
    <form name="login" onSubmit={this.onSubmitForm}>
      <div className="login-form__content-container">
        <InputMask
          mask="+9 999 999 99 99 99 99 99 99"
          maskChar=""
          autoComplete="off"
          className="login-form__input"
          name="phone"
          type="text"
          onChange={this.onInputChange}
          autoFocus
        />
      </div>
      <Button
        className="login-form__button"
        type="submit"
        buttonSize="big"
        disabled={this.state.phone.toString().length === 0 || this.props.isFetching}
      >
        <FormattedMessage id="phoneForm.next" />
      </Button>
    </form>
  );

  render() {
    return this.state.timer ? (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'IntlShape... Remove this comment to see the full error message
      <ToManyError id="phoneForm.error.429" />
    ) : (
      <div className="login-form">
        {this.renderErrorsOrLabel()}
        {this.renderForm()}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof PhoneForm' is not assignable to type ... Remove this comment to see the full error message
export default injectIntl(PhoneForm);
