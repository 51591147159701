import React, { PureComponent } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';

import cn from '../../utils/cn';
import trancate from '../../utils/trancate';

import './styles.scss';

type Props = {
  comments?: any[];
  activeComment?: any;
  onClickComment?: (...args: any[]) => any;
  deleteComment?: (...args: any[]) => any;
};

@cn('document-comments')
class DocumentComments extends PureComponent<Props> {
  renderCommentTime = (cn, comment) => <time className={cn('time')}>{moment(comment.createdDate).format('ll')}</time>;

  renderRemoveCommentButton = (cn, deleteComment, commentId) => (
    <button onClick={() => deleteComment(commentId)} className={cn('button')}>
      Remove
    </button>
  );

  renderDocumentCommentsListItem = (cn, comments, setActiveComment, activeComment, deleteComment) =>
    comments.map(comment => (
      <li
        key={`comment_index${comment.id}`}
        onClick={() => setActiveComment(comment)}
        className={cn('item', {
          active: activeComment && activeComment.id === comment.id,
        })}
      >
        <div className={cn('number-container')}>
          <span className={cn('number')}>{comment.positionNum}</span>
        </div>
        <p className={cn('comment')}>
          {activeComment && activeComment.id === comment.id ? comment.text : trancate(comment.text, 308)}
        </p>
        {activeComment && activeComment.id === comment.id ? this.renderCommentTime(cn, comment) : null}
        {activeComment && activeComment.id === comment.id
          ? this.renderRemoveCommentButton(cn, deleteComment, comment.id)
          : null}
      </li>
    ));

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { comments, activeComment, onClickComment, deleteComment } = this.props;

    return (
      <Scrollbars id="scrollbar" width={330} autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
        <ul className={cn()}>
          {this.renderDocumentCommentsListItem(cn, comments, onClickComment, activeComment, deleteComment)}
        </ul>
      </Scrollbars>
    );
  }
}

export default DocumentComments;
