import React, { Component } from 'react';

import * as locales from '../../constants/locales';

import cn from '../../utils/cn';
import truncate from '../../utils/truncate';

import './styles.scss';
import '../../styles/global.scss';

type Props = {
  checkboxFields: any[];
  onMount: (...args: any[]) => any;
  locale?: string;
  onChange: (...args: any[]) => any;
};

type State = any;

@cn('files-checkboxes')
class FilesCheckboxes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: this.addChackedAttributes(this.props.checkboxFields),
    };
  }

  componentDidMount() {
    this.props.onMount(this.state.fields);
  }

  addChackedAttributes = fields => {
    return fields.map(field => {
      return {
        ...field,
        chacked: true,
      };
    });
  };

  handleChange = input => {
    const newFields = this.changeChackedForOne(input);
    this.setState({ fields: newFields });
    this.props.onChange(newFields);
  };

  changeChackedForOne = input => {
    return this.state.fields.map(field => {
      if (field.id === parseInt(input.target.id, 10)) {
        field.chacked = !field.chacked;
      }
      return field;
    });
  };

  renderCheckboxFields = cn =>
    this.state.fields.map(field => (
      <li key={field.id} className={cn('item')}>
        <label className={cn('label')}>
          {this.props.locale === locales.RU ? truncate(field.russianName, 30) : truncate(field.englishName, 30)}
          <input
            id={field.id}
            defaultChecked={field.chacked}
            onChange={this.handleChange}
            className={cn('checkbox')}
            type="checkbox"
          />
          <span className={cn('checkmark')} />
        </label>
      </li>
    ));

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { checkboxFields } = this.props;

    return <ul className={cn()}>{checkboxFields && this.renderCheckboxFields(cn)}</ul>;
  }
}

export default FilesCheckboxes;
