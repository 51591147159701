import React from 'react';

import DatePeriod from '../DatePeriod';

import * as locales from '../../constants/locales';
import { statuses } from '../../constants/meetingStatuses';

import cn from '../../utils/cn';
import truncate from '../../utils/truncate';

import './styles.scss';

type Props = {
  isToday?: boolean;
  locale?: string;
  item?: any;
  modClass?: string;
  intl?: any;
  currentId?: number;
  onSelectItem: (...args: any[]) => any;
  getOffTop: (...args: any[]) => any;
};

@cn('meeting-list-item')
class MeetingListItem extends React.Component<Props> {
  meetingListItemRef = React.createRef();

  handleSelect = item => {
    this.props.onSelectItem(item);
  };

  renderFiles = (item, cn) => {
    const files = this.props.locale === locales.RU ? item.documentsCountRus : item.documentsCountEng;
    return files > 0 ? (
      <div className={cn('files-container')}>
        <span className={cn('files-counter')}>{files}</span>
        <span className={cn('files-clip')} />
      </div>
    ) : null;
  };

  renderTagging = (item, cn) => {
    return (
      <div className={cn('tagging-container')}>
        <span className={cn('tagging')}>
          {this.props.locale === locales.RU ? item.committee.russianNameShort : item.committee.englishNameShort}
        </span>
      </div>
    );
  };

  componentDidMount() {
    this.props.getOffTop(this.meetingListItemRef.current, this.props.currentId);
  }

  componentDidUpdate() {
    this.props.getOffTop(this.meetingListItemRef.current, this.props.currentId);
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { locale, item, modClass, currentId } = this.props;
    return (
      <div
        id={item.id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLDivE... Remove this comment to see the full error message
        ref={this.meetingListItemRef}
        className={`${cn()} ${modClass} ${item.id === currentId && 'current'}`}
        onClick={() => this.handleSelect(item)}
      >
        <h2 className={cn('title')}>
          {locale === locales.RU ? truncate(item.russianName, 30) : truncate(item.englishName, 30)}
        </h2>
        <DatePeriod
          className={cn('time-container')}
          dateTimeStart={item.dateTimeStart || item.date}
          dateTimeEnd={item.dateTimeEnd}
          isSingleDate={!item.dateTimeEnd}
          locale={this.props.locale}
        />
        <div className={cn('status')}>
          {/* @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
          {statuses[this.props.locale][item.status]}
        </div>
        {this.renderFiles(item, cn)}
        {item.committee &&
          item.committee.englishNameShort &&
          item.committee.russianNameShort &&
          this.renderTagging(item, cn)}
      </div>
    );
  }
}

export default MeetingListItem;
