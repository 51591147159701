import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Avatar from '../Avatar';

import * as locales from '../../constants/locales';

import './styles.scss';

const ProfileLoading = injectIntl(({ user, locale }) => (
  <div className="profile-loading">
    {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'avatarUrl' does not exist on type 'IntlS... Remove this comment to see the full error message */}
    {user.avatarUrl && (
      <Avatar
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
        className="profile-loading__ava"
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'avatarUrl' does not exist on type 'IntlS... Remove this comment to see the full error message
        avatarUrl={`/api${user.avatarUrl}`}
        // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
        userName={locale === locales.RU ? user.russianName : user.englishName}
        size={100}
      />
    )}
    {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'englishName' does not exist on type 'Int... Remove this comment to see the full error message */}
    {user.englishName && (
      <div className="profile-loading__welcome">
        <FormattedMessage id="profile.welcome" />,
        <h1 className="profile-loading__name">
          {/* @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message */}
          {locale === locales.RU ? user.russianName : user.englishName}
        </h1>
      </div>
    )}
    <p className="profile-loading__p">
      <FormattedMessage id="profile.loading" />
    </p>
  </div>
));

export default ProfileLoading;
