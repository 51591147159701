import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';

export function getEntitiesByYear(year, timeZone) {
  return {
    [CALL_API]: {
      types: [
        types.CALENDAR_GET_BY_YEAR_REQUEST,
        types.CALENDAR_GET_BY_YEAR_SUCCESS,
        types.CALENDAR_GET_BY_YEAR_FAILURE,
      ],
      endpoint: '/meetingsByYear',
      body: {
        year,
        timeZone,
      },
    },
  };
}

function filterSetDate(date, filter) {
  return {
    type: types.FILTER_SET_DATE,
    filter,
    date,
  };
}

function filterSetType(type) {
  return {
    type: types.FILTER_SET_TYPE,
    selectType: type,
  };
}

function filterSetQuery(query, searchBy) {
  return {
    type: types.FILTER_SET_QUERY,
    query,
    searchBy,
  };
}

export const filterSet =
  ({ query, type, date, searchBy, filter }) =>
  dispatch => {
    if (type) {
      return dispatch(filterSetType(type));
    }
    if (date) {
      return dispatch(filterSetDate(date, filter));
    }
    if (query !== null) {
      return dispatch(filterSetQuery(query, searchBy));
    }
    return true;
  };
