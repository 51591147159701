import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';
import { LIST_LIMIT } from '../constants/list';

export function clearList() {
  return {
    type: types.CLEAR_FILES_LIST,
  };
}
export const requestList = (params, isMore = false, withFetching = true) => {
  const paramsStr = `?limit=${params.limit >= 0 ? params.limit : LIST_LIMIT}&offset=${params.offset || 0}`;
  const actionTypes = isMore
    ? [types.FILES_MORE_REQUEST, types.FILES_MORE_SUCCESS, types.FILES_MORE_FAILURE]
    : [types.GET_FILES_REQUEST, types.GET_FILES_SUCCESS, types.GET_FILES_FAILURE];

  const filters = {
    query: params.query || '',
    typesIds: params.typesIds || [],
    isCommentedByUser: params.isCommentedByUser || false,
  };

  return {
    [CALL_API]: {
      types: actionTypes,
      endpoint: `/document/search${paramsStr}`,
      body: {
        name: filters.query,
        committeesIds: filters.typesIds,
        isCommentedByUser: filters.isCommentedByUser,
      },
    },
    filters,
    withFetching,
  };
};

export function refreshList(limit, offset, params) {
  return dispatch => {
    return dispatch(requestList({ limit, offset, ...params }, false, false));
  };
}

export function getList(limit, offset, params) {
  return dispatch => {
    dispatch(clearList());
    return dispatch(requestList({ limit, offset, ...params }, false));
  };
}

export function getMore(limit = LIST_LIMIT, offset, params) {
  return (dispatch, getState) => {
    const {
      files: { query },
    } = getState();
    return dispatch(
      requestList(
        {
          limit,
          offset,
          query,
          ...params,
        },
        true
      )
    );
  };
}
