import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Logo from '../../../components/Logo';
import Button from '../../../components/Button';

import Device from '../../../utils/device';
import { appConfig } from '../../../config';

import './styles.scss';

type Props = {
  locale?: string;
};

type State = any;

class Mobile extends Component<Props, State> {
  state = {
    isIphone: false,
  };

  componentDidMount() {
    this.isIphone();
    document.body.classList.add('mobile');
  }

  isIphone = () => {
    if (Device.iOS()) {
      this.setState({
        isIphone: true,
      });
    }
  };

  renderDownloadLink = isIphone => {
    return (
      <Button
        className="mobile__link"
        buttonSize="big"
        onClick={() => {
          if (isIphone) {
            if (appConfig.ios_install_link) {
              window.location.href = appConfig.ios_install_link;
            }
          } else {
            if (appConfig.android_install_link) {
              window.location.href = appConfig.android_install_link;
            }
          }
        }}
      >
        <FormattedMessage id="mobile.download" />
      </Button>
    );
  };

  render() {
    return (
      <div className="mobile">
        <Logo className="mobile__logo" />
        <div className="mobile__content">
          <FormattedMessage id="mobile.text" />
        </div>
        {this.renderDownloadLink(this.state.isIphone)}
        <div className="mobile__logo-bottom" />
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof Mobile' is not assignable to type 'Co... Remove this comment to see the full error message
export default injectIntl(Mobile);
