import { MEETING_GET_SUCCESS, MEETING_GET_REQUEST, MEETING_GET_FAILURE } from '../constants/ActionTypes';

const defaultState = {
  id: null,
  status: null,
  comments: 0,
  type: -1,
  englishName: '',
  englishDescription: '',
  russianName: '',
  russianDescription: '',
  members: [],
  committee: null,
  documents: {
    eng: [],
    rus: [],
    minutes: [],
  },
  isFetching: false,
  error: null,
};

const meeting = (state = defaultState, action) => {
  switch (action.type) {
    case MEETING_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case MEETING_GET_SUCCESS: {
      const { rus = [], eng = [], minutes = [] } = action.data.documents;
      return {
        ...state,
        ...action.data,
        documents: {
          eng: [...eng],
          rus: [...rus],
          minutes: [...minutes],
        },
        isFetching: false,
      };
    }
    case MEETING_GET_FAILURE:
      return {
        ...state,
        ...action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default meeting;
