import React from 'react';

import FileDownloadProgress from '../FileDownloadProgress';
import { File, FileDownloader } from '../../utils/file';

import './styles.scss';

import { DocType } from './index';

export function ExportFileProgressBar({ file, makeStartDownloadFn, fileType }) {
  const [progress, setProgress] = React.useState(0);
  const [aborted, setAborted] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const xhrRef = React.useRef(null);

  const onProgress = evt => {
    setProgress(Math.round((evt.loaded / evt.total) * 100));
  };

  const onStart = () => {
    setProgress(0);
    setDownloading(true);
    setAborted(false);

    const fileUrl = fileType === DocType.pdf ? file.pdfURL : file.url;
    const fileName = fileType === DocType.pdf ? File.changeExtension(file.name, 'pdf') : file.name;

    const xhr = FileDownloader.prepareRequest('/api' + fileUrl);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'XMLHttpRequest' is not assignable to type 'n... Remove this comment to see the full error message
    xhrRef.current = xhr;

    xhr.onload = () => {
      setDownloading(false);
      setAborted(false);
      FileDownloader.save(xhr.response, fileName);
    };
    xhr.onprogress = onProgress;
    xhr.onerror = () => {
      setAborted(true);
    };
    xhr.send();
  };

  makeStartDownloadFn(onStart);

  const onStop = () => {
    if (xhrRef && xhrRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      xhrRef.current.abort();
    }
    setDownloading(false);
  };

  return (
    <>
      {downloading ? (
        <div className="new-files-list__item__progress-bar">
          {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
          <FileDownloadProgress
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'progress' does not exist on type 'Intrin... Remove this comment to see the full error message
            progress={progress}
            aborted={aborted}
            onStop={onStop}
          />
        </div>
      ) : null}
    </>
  );
}
