import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import cn from '../../utils/cn';

type Props = {
  isOpen: boolean;
  toggleFull: (...args: any[]) => any;
};

@cn('show-more')
class ShowMore extends Component<Props> {
  renderMessage = isOpen => {
    return <FormattedMessage id={isOpen ? 'showMore.more' : 'showMore.less'} />;
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { toggleFull, isOpen } = this.props;
    return (
      <button onClick={toggleFull} className={cn()}>
        {this.renderMessage(isOpen)}
      </button>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof ShowMore' is not assignable to type '... Remove this comment to see the full error message
export default injectIntl(ShowMore);
