import React, { Component } from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import { UsersTable } from '../UsersTable';

import NoMembers from './NoMembers';
import NoResult from '../NoResults';
import Profile from '../Profile';

import * as locales from '../../constants/locales';
import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  intl: IntlShape;
  byQuery?: boolean;
  isFetching?: boolean;
  showGroups?: any[];
  members?: any;
  query?: string;
  getMember?: (...args: any[]) => any;
};

type State = any;

@cn('members-list')
class MembersList extends Component<Props, State> {
  state = {
    selectedMember: undefined,
    showProfile: false,
  };

  renderMembersList = ({ members, isFetching, intl }) => {
    const hideHead = true;
    const showMinimal = true;
    return (
      <UsersTable
        users={members}
        showMinimal={showMinimal}
        hideHead={hideHead}
        isFetching={isFetching}
        locale={intl.locale}
        rowClickFunc={user => {
          this.setState({ selectedMember: user, showProfile: true });
          if (typeof this.props.getMember === 'function') {
            this.props.getMember(user.id).then(resp => {
              this.setState({ selectedMember: resp.data });
            });
          }
        }}
      />
    );
  };

  renderGroups = ({ cn, showGroups, groups, isFetching, intl }) => {
    const groupsToShow = showGroups.map(g => groups[g.id]).filter(g => g && g.members.length > 0);

    return groupsToShow.length > 0 ? (
      groupsToShow.map(group => {
        const members = group.members;
        return (
          <React.Fragment key={`member-group-${group.id}`}>
            <div className={cn('list-title')}>
              {intl.locale === locales.EN ? group.englishName : group.russianName}{' '}
              <span className={cn('list-title', { number: true })}>{members.length}</span>
            </div>
            {/* @ts-expect-error ts-migrate(2345) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message */}
            {this.renderMembersList({ cn, members, isFetching, intl })}
            <div className={cn('list-spacer')} />
          </React.Fragment>
        );
      })
    ) : (
      // @ts-expect-error ts-migrate(2322) FIXME: Property 'messageId' does not exist on type 'Intri... Remove this comment to see the full error message
      <NoMembers key="member-group-noresult" messageId="members.noMembers" />
    );
  };

  renderSearchResult = ({ members, isFetching, query, intl }) => {
    return !isFetching && members && members.length === 0 ? (
      // @ts-expect-error ts-migrate(2322) FIXME: Property 'titleId' does not exist on type 'Intrins... Remove this comment to see the full error message
      <NoResult titleId="search.results.matching" query={query} />
    ) : (
      this.renderMembersList({
        // @ts-expect-error ts-migrate(2345) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
        cn,
        members,
        isFetching,
        intl,
      })
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { members, byQuery, showGroups, query, isFetching, intl } = this.props;

    const { selectedMember, showProfile } = this.state;

    return (
      <div className={cn()}>
        {byQuery
          ? this.renderSearchResult({
              members: members.search,
              query,
              isFetching,
              intl,
            })
          : this.renderGroups({
              cn,
              showGroups,
              groups: members.groups,
              isFetching,
              intl,
            })}
        {selectedMember && showProfile && (
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ isMe: false; isShowCanseledMeetings: false... Remove this comment to see the full error message
          <Profile
            isMe={false}
            isShowCanseledMeetings={false}
            user={selectedMember}
            locale={intl.locale}
            isVisible={showProfile}
            closeProfile={() => this.setState({ showProfile: false })}
          />
        )}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof MembersList' is not assignable to typ... Remove this comment to see the full error message
export default injectIntl(MembersList);
