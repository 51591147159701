import React, { Component } from 'react';
import moment from 'moment';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  document?: any;
  isDrawingEnabled?: boolean;
  isPreviewEnabled?: boolean;
  isCommentsEnabled?: boolean;
  isCommentsAddingEnabled?: boolean;
  printPdfDocument?: (...args: any[]) => any;
  downloadPdfDocument?: (...args: any[]) => any;
  toggleDrawing?: (...args: any[]) => any;
  togglePreview?: (...args: any[]) => any;
  toggleComments?: (...args: any[]) => any;
  toggleCommentsAdding?: (...args: any[]) => any;
  onBack?: (...args: any[]) => any;
  intl: IntlShape;
};

type State = any;

@cn('document-header')
class DocumentHeaderNoIntl extends Component<Props, State> {
  state = {
    printing: false,
  };

  renderShowPagesListButton = (cn, togglePreview, isPreviewEnabled) => {
    return (
      <button
        onClick={togglePreview}
        className={cn('button', {
          'with-circle': true,
          menu: true,
          active: isPreviewEnabled ? true : '',
        })}
      />
    );
  };

  renderTime = time => {
    return time ? moment(time).format('lll') : null;
  };

  setPrintingStatus = status => {
    this.setState({ printing: status });
  };

  printPdfDocument = () => {
    const { printPdfDocument } = this.props;
    const { printing } = this.state;

    if (!printing && printPdfDocument) {
      this.setPrintingStatus(true);
      printPdfDocument()
        .then(() => this.setPrintingStatus(false))
        .catch(() => this.setPrintingStatus(false));
    }
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const {
      document,
      isDrawingEnabled,
      isPreviewEnabled,
      isCommentsEnabled,
      isCommentsAddingEnabled,
      downloadPdfDocument,
      toggleDrawing,
      togglePreview,
      toggleComments,
      toggleCommentsAdding,
      onBack,
      intl,
    } = this.props;

    return (
      <header className={cn()}>
        <div className={cn('content')}>
          <nav className={cn('global-navigate')}>
            <ul className={cn('list')}>
              <li className={cn('list', 'item')}>
                <button onClick={onBack} className={cn('button', { back: true })} />
              </li>
              <li className={cn('list', 'item')}>
                {this.renderShowPagesListButton(cn, togglePreview, isPreviewEnabled)}
              </li>
              <li className={cn('list', 'item')}>
                <button
                  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  onClick={() => downloadPdfDocument(document.url, document.name)}
                  className={cn('button', { download: true })}
                />
              </li>
              <li className={cn('list', 'item')}>
                <button
                  onClick={this.printPdfDocument}
                  className={cn('button', { print: true })}
                  title={intl.formatMessage({
                    id: 'pdfDocument.printWithoutNotes',
                  })}
                />
              </li>
            </ul>
          </nav>
          <div className={cn('information')}>
            <h1 className={cn('title')}>{document.name}</h1>
            <time className={cn('time')}>{this.renderTime(document.uploadedDateTime)}</time>
          </div>
          <nav className={cn('file-navigate')}>
            <ul className={cn('list')}>
              <li className={cn('item')}>
                <button
                  onClick={toggleDrawing}
                  className={cn('button', {
                    'with-circle': true,
                    marker: true,
                    active: isDrawingEnabled,
                  })}
                />
              </li>
              <li className={cn('item')}>
                <button
                  onClick={toggleCommentsAdding}
                  className={cn('button', {
                    'with-circle': true,
                    sticker: true,
                    active: isCommentsAddingEnabled,
                  })}
                />
              </li>
              <li className={cn('item')}>
                <button
                  onClick={toggleComments}
                  className={cn('button', {
                    'with-circle': true,
                    comment: true,
                    active: isCommentsEnabled,
                  })}
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof DocumentHeader' is not assignable to ... Remove this comment to see the full error message
export const DocumentHeader = injectIntl(DocumentHeaderNoIntl);
