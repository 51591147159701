import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

// import { addLocaleData } from 'react-intl';
// import enLocaleData from 'react-intl/locale-data/en';
// import ruLocaleData from 'react-intl/locale-data/ru';
// addLocaleData([...enLocaleData, ...ruLocaleData]);

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/ru';

import IntlProvider from './IntlProvider';
import { init } from '../../actions/i18n';
import { init as initStorage } from '../../actions/app';

import App from './app';

import { configureStore } from '../../store/store';

const store = configureStore();

const Root = () => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  store.dispatch(init());
  store.dispatch(initStorage(false));

  return (
    <Provider store={store}>
      <IntlProvider>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  );
};

export default Root;
