import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Avatar from '../../components/Avatar';
import Profile from '../../components/Profile';

import { loadLocale } from '../../actions/i18n';
import { setStorage } from '../../actions/app';
import { logout } from '../../actions/auth';
import { profile } from '../../actions/user';

import * as locales from '../../constants/locales';
import DefaultAvatar from '../../assets/images/default-avatar.png';

import './styles.scss';
import '../../styles/blocks/select.scss';

type MenuProfileProps = {
  loadProfile: (...args: any[]) => any;
  onLogout: (...args: any[]) => any;
  loadLocale: (...args: any[]) => any;
  setStorage: (...args: any[]) => any;
  user?: any;
  locale?: string;
  isShowCanseledMeetings?: boolean;
};

type MenuProfileState = any;

class MenuProfile extends React.PureComponent<MenuProfileProps, MenuProfileState> {
  constructor(props: MenuProfileProps) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.englishName) {
      this.props.loadProfile();
    }
  }

  handleShowModal = () => {
    this.setState({
      isVisible: true,
    });
  };

  handleHideModal = () => {
    this.setState({
      isVisible: false,
    });
  };

  render() {
    const { user, locale, isShowCanseledMeetings } = this.props;
    return (
      <div className="select profile-menu">
        <Avatar
          // @ts-expect-error ts-migrate(2769) FIXME: Property 'avatarUrl' does not exist on type 'Intri... Remove this comment to see the full error message
          avatarUrl={user.avatarUrl ? `/api${user.avatarUrl}` : DefaultAvatar}
          userName={locale === locales.RU ? user.russianName : user.englishName}
          size={36}
        />
        <ul className="select__dropdown">
          <li className="select__dropdown-item">
            <button onClick={this.handleShowModal} className="profile-menu__button">
              <FormattedMessage id="menu.profile" />
            </button>
          </li>
          <li className="select__dropdown-item">
            <button className="profile-menu__button" onClick={this.props.onLogout}>
              <FormattedMessage id="menu.logout" />
            </button>
          </li>
        </ul>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'isMe' is missing in type '{ setStorage: (... Remove this comment to see the full error message */}
        <Profile
          setStorage={this.props.setStorage}
          isShowCanseledMeetings={isShowCanseledMeetings}
          changeLocale={this.props.loadLocale}
          user={user}
          locale={locale}
          onLogout={this.props.onLogout}
          isVisible={this.state.isVisible}
          closeProfile={this.handleHideModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user, i18n, app }) => ({
  user,
  isShowCanseledMeetings: app.isShowCanseledMeetings,
  locale: i18n.locale,
});

const mapDispatchToProps = {
  onLogout: logout,
  loadLocale,
  loadProfile: profile,
  setStorage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof MenuProfile' is not assignable to typ... Remove this comment to see the full error message
)(injectIntl(MenuProfile));
