import moment from 'moment';

import { SET_LOCALE, ADD_LOCALE } from '../constants/ActionTypes';
import * as locales from '../constants/locales';

import Storage from '../utils/storage';

const allEnMessages = require('../i18n/en').default; // eslint-disable-line
const allRuMessages = require('../i18n/ru').default; // eslint-disable-line

const getLocale = () => {
  Storage.get(locales.STORAGE_NAME_LOCALE);

  return locales.EN;
};

const setLocale = ({ locale, messages }) => {
  Storage.set(locales.STORAGE_NAME_LOCALE, locale);

  return { type: SET_LOCALE, locale, messages };
};

const loadLocale = locale => dispatch => {
  let messages;

  switch (locale) {
    case locales.RU:
      moment.locale(locales.RU);
      messages = allRuMessages;
      break;
    default:
      moment.locale(locales.EN);
      messages = allEnMessages;
      break;
  }

  dispatch(setLocale({ locale, messages }));
};

const detectLanguage = () => {
  const langFromLocalStorage = Storage.get(locales.STORAGE_NAME_LOCALE);

  return langFromLocalStorage || locales.EN;
};

const loadCurrentLocale = locale => async dispatch => {
  await dispatch(loadLocale(locale || detectLanguage()));
};

const init = locale => async dispatch => {
  await dispatch(loadCurrentLocale(locale));
};

const addLocale = locale => ({ type: ADD_LOCALE, locale });

export { loadLocale, init, addLocale, getLocale };
