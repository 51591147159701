import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import Logo from '../../../components/Logo';
import PhoneForm from '../../../components/PhoneForm';
import SmsCodeForm from '../../../components/SmsCodeForm';
import UserAbsentForm from '../../../components/UserAbsentForm';
import UserLoading from '../../UserLoading';
import BackIcon from '../../../components/Icons/BackIcon';

import { login, confirmPin, logout } from '../../../actions/auth';
import { loadLocale } from '../../../actions/i18n';
import * as routes from '../../../constants/routes';

import Device from '../../../utils/device';
import Storage from '../../../utils/storage';

import './styles.scss';
import * as locales from '../../../constants/locales';
import moment from 'moment';

type LoginProps = {
  noSubmitPhone: (...args: any[]) => any;
  onConfirmPin: (...args: any[]) => any;
  location?: any;
  match?: any;
  smsCodeSended?: boolean;
  errorCode?: number;
  loggedIn?: boolean;
  isFetching?: boolean;
  isAutorize?: boolean;
  logout?: (...args: any[]) => any;
  user?: any;
  changeLocale: (...args: any[]) => any;
};

type LoginState = any;

class Login extends React.PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      phone: '',
      step: !props.loggedIn && props.user.isLoaded ? 3 : 0,
      loggedIn: this.props.loggedIn,
      smsCodeSended: this.props.smsCodeSended,
      dataPreload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.smsCodeSended !== nextProps.smsCodeSended && nextProps.smsCodeSended) {
      return {
        step: 1,
        smsCodeSended: nextProps.smsCodeSended,
      };
    }
    if (prevState.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      return {
        // step: 2,
        loggedIn: nextProps.loggedIn,
      };
    }
    if (prevState.smsCodeSended !== nextProps.smsCodeSended && !nextProps.smsCodeSended) {
      return {
        step: 0,
        phone: '',
        smsCodeSended: nextProps.smsCodeSended,
        loggedIn: nextProps.loggedIn,
        dataPreload: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: LoginProps, prevState: LoginState) {
    if (prevState.loggedIn !== this.state.loggedIn && this.state.loggedIn) {
      this.getAccessToken();
    }
  }

  timer = null;

  getAccessToken = () => {
    const accessToken = Storage.get('accessToken');
    // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    clearTimeout(this.timer);
    if (!accessToken) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'null'.
      this.timer = setTimeout(this.getAccessToken, 200);
    } else {
      this.setState({
        step: 2,
      });
    }
  };

  requestNewPin = () => {
    this.props.noSubmitPhone(this.state.phone);
  };

  confirmPin = pin => {
    this.props.onConfirmPin({
      phone: this.state.phone,
      pin,
    });
    if (this.state.phone[0] === '7') {
      this.props.changeLocale(locales.RU);
      moment.locale(locales.RU);
    }
  };

  login = phone => {
    this.setState({ phone });
    this.props.noSubmitPhone(phone);
  };

  absentLogin = () => {
    const {
      user: { phone },
    } = this.props;

    if (phone && phone.length > 0) {
      this.login(phone);
    } else {
      this.setState({ step: 0 });
    }
  };

  loaded = () => {
    this.setState({
      dataPreload: true,
    });
  };

  renderLogin = () => {
    switch (this.state.step) {
      case 0:
        return (
          <PhoneForm
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'isFetching' does not exist on type 'Intr... Remove this comment to see the full error message
            isFetching={this.props.isFetching}
            errorCode={this.props.errorCode}
            onSubmit={this.login}
          />
        );
      case 1:
        return (
          <SmsCodeForm
            isFetching={this.props.isFetching}
            onRequestNewPin={this.requestNewPin}
            errorCode={this.props.errorCode}
            onSubmit={this.confirmPin}
            phone={this.state.phone}
          />
        );
      case 2:
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'match' does not exist on type 'Intrinsic... Remove this comment to see the full error message
        return <UserLoading match={this.props.match} onLoad={this.loaded} />;
      case 3:
        return <UserAbsentForm onSubmit={this.absentLogin} />;
      default:
        return null;
    }
  };

  back = e => {
    e.preventDefault();
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.logout();
    this.setState({ step: 0 });
  };

  render() {
    if ((!this.state.step && this.props.loggedIn) || this.state.dataPreload) {
      return (
        <Redirect
          to={{
            pathname:
              /* this.props.location.state && this.props.location.state.next ?
          this.props.location.state.next : */ routes.index,
            state: {
              loaded: this.state.dataPreload,
            },
          }}
        />
      );
    }
    return (
      <div className="login">
        <Logo />
        {[1, 3].includes(this.state.step) && (
          // eslint-disable-next-line
          <a href="#" onClick={this.back} className="login__back">
            <BackIcon className="login__back-ico" />
            {Device.Desktop() && <FormattedMessage id="pinCodeForm.back" />}
          </a>
        )}
        <div className="login__form">{this.renderLogin()}</div>
        <div className="login__logo-bottom" />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, user }) => ({
  smsCodeSended: auth.smsCodeSended,
  errorCode: auth.errorCode,
  loggedIn: auth.loggedIn,
  isFetching: auth.isFetching,
  isAutorize: auth.isAutorize,
  user,
});

const mapDispatchToProps = {
  noSubmitPhone: login,
  onConfirmPin: confirmPin,
  changeLocale: loadLocale,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof Login' is not assignable to type 'Com... Remove this comment to see the full error message
)(injectIntl(Login));
