import { SET_STORAGE } from '../constants/ActionTypes';
import { STORAGE_NAME_IS_SHOW_CANSELED_MEETIONGS } from '../constants/meetings';

import { isBooleanString } from '../utils/utils';
import Storage from '../utils/storage';

const setStorage = value => {
  let resultStorage;

  Storage.set(STORAGE_NAME_IS_SHOW_CANSELED_MEETIONGS, value);

  if (isBooleanString(value) || typeof resultStorage === 'boolean') {
    resultStorage = JSON.parse(value);
  } else {
    throw new Error('Значение storage должно быть true или false');
  }
  return {
    type: SET_STORAGE,
    [STORAGE_NAME_IS_SHOW_CANSELED_MEETIONGS]: resultStorage,
  };
};

const detectStorage = () => {
  const DEFAULT_SHOW_CANCELLED_MEETINGS_VALUE = 'false';
  const valueFromLocalStorage = Storage.get(STORAGE_NAME_IS_SHOW_CANSELED_MEETIONGS);

  return valueFromLocalStorage ? valueFromLocalStorage : DEFAULT_SHOW_CANCELLED_MEETINGS_VALUE;
};

const loadCurrentStorage = value => async dispatch => {
  await dispatch(setStorage(value || detectStorage()));
};

const init = value => async dispatch => {
  await dispatch(loadCurrentStorage(value));
};

export { init, setStorage };
