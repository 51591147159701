import React from 'react';
// eslint-disable-next-line
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';

import Button from '../Button';

import cn from '../../utils/cn';

type Props = {
  intl: IntlShape;
  onSubmit: (...args: any[]) => any;
};

@cn('login-form')
class UserAbsentForm extends React.PureComponent<Props> {
  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn: any) {
    const { onSubmit } = this.props;

    return (
      <div className={cn('content-absent')}>
        <label className={`${cn('label', { large: true })} ${cn('content-absent', 'title')}`}>
          <FormattedMessage id="pinCodeForm.absent.wereAbsent" />
        </label>
        <label className={cn('label')}>
          <FormattedMessage id="pinCodeForm.absent.completedSession" />
          <br />
          <FormattedMessage id="pinCodeForm.absent.resendSms" />
        </label>
        <Button className={cn('button')} buttonSize="big" onClick={onSubmit}>
          <FormattedMessage id="pinCodeForm.absent.sendSms" />
        </Button>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof UserAbsentForm' is not assignable to ... Remove this comment to see the full error message
export default injectIntl(UserAbsentForm);
