import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import AsyncImage from '../AsyncImage';
import DatePeriod from '../DatePeriod';

import MaskIcon from '../Icons/MaskIcon';

import * as routes from '../../constants/routes';
import cn from '../../utils/cn';
import FileUtils from '../../utils/file';

import { ExportFileProgressBar } from './ExportFileProgressBar';
import ExportFileDropdown from './ExportFileDropdown';
import Responsible from '../FilesList/Responsible';

import './styles.scss';

export const DocType = {
  original: 'original',
  pdf: 'pdf',
};

@cn('new-files-list')
class FilesList extends Component {
  constructor(props: any) {
    super(props);

    if (props.makeDownloadAllFn) {
      props.makeDownloadAllFn(this.downloadAll);
    }
  }

  // {
  //   [ key: file id ]: { [key: DocType]: start download function }
  // }
  downloadFilesFns = {};

  UNSAFE_componentWillReceiveProps() {
    this.downloadFilesFns = {};
  }

  downloadAll = docType => {
    Object.keys(this.downloadFilesFns).forEach(key => {
      if (typeof this.downloadFilesFns[key][docType] === 'function') {
        this.downloadFilesFns[key][docType]();
      }
    });
  };

  renderItemExtension = (fileExtension, cn) => {
    return !fileExtension || fileExtension === 'pdf' ? undefined : (
      <div className={cn('extension')}>{fileExtension.toUpperCase()}</div>
    );
  };

  renderItemDownloadButton = (file, intl, cn) => {
    const menuItems = [
      {
        id: file.id,
        type: DocType.original,
        label: intl.formatMessage({ id: 'files.download.original' }),
      },
    ];

    if (file.pdfURL) {
      menuItems.push({
        id: file.id,
        type: DocType.pdf,
        label: intl.formatMessage({ id: 'files.download.converted' }),
      });
    }

    return menuItems.length > 1 ? (
      <div className={`${cn('menu', 'button')} select`} title={intl.formatMessage({ id: 'files.download' })}>
        <ExportFileDropdown
          items={menuItems}
          onSelect={item => {
            if (this.downloadFilesFns[item.id][item.type]) {
              this.downloadFilesFns[item.id][item.type]();
            }
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onDownloadFile' does not exist on type '... Remove this comment to see the full error message
            if (this.props.onDownloadFile) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'onDownloadFile' does not exist on type '... Remove this comment to see the full error message
              this.props.onDownloadFile();
            }
          }}
        />
      </div>
    ) : (
      <div
        className={`${cn('menu', 'button')} select`}
        title={intl.formatMessage({ id: 'files.download' })}
        onClick={() => {
          if (this.downloadFilesFns[file.id][DocType.original]) {
            this.downloadFilesFns[file.id][DocType.original]();
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onDownloadFile' does not exist on type '... Remove this comment to see the full error message
          if (this.props.onDownloadFile) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onDownloadFile' does not exist on type '... Remove this comment to see the full error message
            this.props.onDownloadFile();
          }
        }}
      />
    );
  };

  renderItem = (file, cn, intl) => {
    const canView = file.pdfURL || file.extension === 'pdf' || FileUtils.getExtension(file.url) === 'pdf';

    const isNew = !file.isDownloaded;
    const isCommented = file.commentsCount > 0;
    const isArchive = file.isArchive;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'parentType' does not exist on type 'Read... Remove this comment to see the full error message
    const { parentType, parentId } = this.props;
    let documentUrl = `${routes.document}/${file.id}/`;
    if (parentType != null && parentId != null) documentUrl = `${routes.document}/${file.id}/${parentType}/${parentId}`;

    return (
      <div key={file.id} className={cn('item')}>
        <div className={cn('type-container')}>
          {isNew && <div className={cn('type-new')} title={intl.formatMessage({ id: 'files.new' })} />}
        </div>
        <div className={cn('preview')}>
          {this.renderItemExtension(file.extension, cn)}
          <div className={cn('type-labels')}>
            {isCommented && <div className={cn('type-comment')} />}
            {isArchive && <div className={cn('type-archive')} />}
          </div>
          <div className={cn('image-container')}>
            {file.previewUrl && canView && FileUtils.isPdfOrDocFile(file.name) ? (
              <Link className={cn('link')} title={file.name} to={documentUrl}>
                <AsyncImage classNames={cn('image')} url={file.previewUrl} />
              </Link>
            ) : (
              <div className={cn('image-default')}>
                <MaskIcon className={cn('image-default-ico')} />
              </div>
            )}
          </div>
        </div>
        <div className={cn('content')}>
          {file.previewUrl && canView ? (
            <Link className={cn('link')} title={file.name} to={documentUrl}>
              <div className={cn('description')} title={file.name}>
                {file.name}
              </div>
            </Link>
          ) : (
            <div className={cn('description')} title={file.name}>
              {file.name}
            </div>
          )}
          <DatePeriod
            className={cn('date-uploaded')}
            dateTimeStart={file.uploadedDateTime}
            isSingleDate
            locale={intl.locale}
          />
          {file.responsible?.length > 0 && (
            <div className={cn('responsible')}>
              {file.responsible.map((el, i) => (
                <Fragment key={el.id}>
                  <Responsible className={cn('responsible--name')} responsible={el} />
                  {i < file.responsible.length - 1 && <span className={cn('responsible--comma')}>,</span>}
                </Fragment>
              ))}
            </div>
          )}
          <ExportFileProgressBar
            file={file}
            makeStartDownloadFn={fn => {
              this.downloadFilesFns[file.id] = {
                ...this.downloadFilesFns[file.id],
                [DocType.original]: fn,
              };
            }}
            fileType={DocType.original}
          />
          {Boolean(file.pdfURL) && (
            <ExportFileProgressBar
              file={file}
              makeStartDownloadFn={fn => {
                this.downloadFilesFns[file.id] = {
                  ...this.downloadFilesFns[file.id],
                  [DocType.pdf]: fn,
                };
              }}
              fileType={DocType.pdf}
            />
          )}
        </div>
        <div className={cn('menu')}>{this.renderItemDownloadButton(file, intl, cn)}</div>
      </div>
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { title, files, intl } = this.props;

    return (
      <section className={cn()}>
        <div className={cn('container')}>
          {title && (
            <div className={cn('header')}>
              <div className={cn('header', 'title')}>{title}</div>
            </div>
          )}
          <div className={cn('items')}>{files.map(file => this.renderItem(file, cn, intl))}</div>
        </div>
      </section>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof FilesList' is not assignable to type ... Remove this comment to see the full error message
export default injectIntl(FilesList);
