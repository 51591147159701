import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import * as routes from '../../constants/routes';
import Members from '../../containers/Pages/Members';
import Meetings from '../../containers/Pages/Meetings';
import Files from '../../containers/Pages/Files';
import Reports from '../../containers/Pages/Reports';
import Login from '../../containers/Pages/Login';
import Mobile from '../../containers/Pages/Mobile';
import Document from '../../containers/Pages/Document';
import Content from './Content';
import { regexpText } from '../../utils/utils';
import Device from '../../utils/device';
import { setLoginSuccess, logout } from '../../actions/auth';
import { buildHeadersByState, axiosInstance as axios } from '../../middleware/refresh';

const appUserType = 'USER';

const checkRole = async () => {
  try {
    const res = await axios({
      url: `/me`,
      withCredentials: true,
      headers: buildHeadersByState(),
    });
    const me = res.data;
    return me.type === appUserType;
  } catch (err) {
    // console.error('==== roleCheck Error ====', err);
    return false;
  }
};

type RoutesState = any;

class Routes extends React.Component<{}, RoutesState> {
  state = {
    loading: true,
  };

  async UNSAFE_componentWillMount() {
    if (window.location.pathname !== '/login') {
      const isValidRole = await checkRole();
      if (isValidRole) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'loggedIn' does not exist on type 'Routes... Remove this comment to see the full error message
        this.loggedIn = true;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setLoginSuccess' does not exist on type ... Remove this comment to see the full error message
        this.props.setLoginSuccess();
        this.setState({ loading: false });
        return;
      }
    }

    this.setState({ loading: false });
  }

  requireAuth = (Component, props) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loggedIn' does not exist on type 'Routes... Remove this comment to see the full error message
    return this.loggedIn || this.props.loggedIn ? (
      <Content
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'withHeader' does not exist on type 'Intr... Remove this comment to see the full error message
        withHeader={Device.Desktop() && !regexpText(props.location.pathname, `${routes.document}`)}
      >
        <Component {...props} />
      </Content>
    ) : (
      <Redirect
        to={{
          pathname: routes.login,
          state: {
            next: props.location.pathname,
          },
        }}
      />
    );
  };

  render() {
    const loaded = this.state.loading === false;
    if (!Device.Desktop()) {
      return (
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <Route exact path={routes.mobile} render={props => this.requireAuth(Mobile, props)} />
          <Route path="*" render={() => <Redirect to={routes.mobile} />} />
        </Switch>
      );
    }
    return (
      <div>
        {loaded && (
          <Switch>
            <Route exact path={routes.login} component={Login} />
            <Route exact path={routes.meetings} render={props => this.requireAuth(Meetings, props)} />
            <Route exact path={`${routes.meetings}/:id`} render={props => this.requireAuth(Meetings, props)} />
            <Route exact path={routes.files} render={props => this.requireAuth(Files, props)} />
            <Route
              exact
              path={`${routes.document}/:documentId/:parentType?/:parentId?`}
              render={props => this.requireAuth(Document, props)}
            />
            <Route
              exact
              path={`${routes.members}/:groupType?/:groupId?`}
              render={props => this.requireAuth(Members, props)}
            />
            <Route exact path={routes.document} render={props => this.requireAuth(Document, props)} />
            <Route
              exact
              path={`${routes.reports}/:id?/:folderKey?`}
              render={props => this.requireAuth(Reports, props)}
            />
            <Route path="*" render={() => <Redirect to={routes.meetings} />} />
          </Switch>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { loggedIn }, i18n: { locale } }) => ({
  loggedIn,
  locale,
});

const mapStateDispatchToProps = {
  setLoginSuccess,
  logout,
};

export default withRouter(connect(mapStateToProps, mapStateDispatchToProps)(Routes) as any);
