import React from 'react';

import MeetingDescription from '../MeetingDescription';
import MeetingFiles from '../MeetingFiles';

import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  meeting?: any;
  getMeeting: (...args: any[]) => any;
  locale?: string;
};

@cn('meeting-view')
class MeetingView extends React.Component<Props> {
  onDownloadFile = () => {
    const { meeting, getMeeting } = this.props;
    getMeeting(meeting.id);
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { meeting, locale } = this.props;
    return (
      <div className={cn()}>
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
        <MeetingDescription
          locale={locale}
          englishName={meeting.englishName}
          russianName={meeting.russianName}
          englishDescription={meeting.englishDescription}
          russianDescription={meeting.russianDescription}
          dateTimeStart={meeting.dateTimeStart}
          dateTimeEnd={meeting.dateTimeEnd}
          status={meeting.status}
          isFetching={meeting.isFetching}
          error={meeting.error}
          // @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
          className={cn('meeting-description')}
        />
        <MeetingFiles
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
          className={cn('meeting-files')}
          locale={locale}
          meeting={meeting}
          documents={meeting.documents}
          onDownloadFile={this.onDownloadFile}
        />
      </div>
    );
  }
}

export default MeetingView;
