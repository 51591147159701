import React from 'react';

import './ButtonDatepicker.scss';

type Props = {
  onClickHandle?: (...args: any[]) => any;
  value?: string;
};

class ButtonDatepicker extends React.Component<Props> {
  render() {
    return (
      <button onClick={this.props.onClickHandle} value={this.props.value} className="button-datepicker">
        {this.props.value}
      </button>
    );
  }
}

export default ButtonDatepicker;
