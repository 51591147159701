// @ts-nocheck
import React, { PureComponent, memo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList, areEqual } from 'react-window';
import { Page } from 'react-pdf';
import memoize from 'memoize-one';

import cn from '../../utils/cn';

import './styles.scss';

@cn('document-preview')
class DocumentPreview extends PureComponent {
  static getPageScale = page => {
    let scale = 0.15;

    if (page) {
      const view = page.view;
      if (view[3] > view[2]) {
        scale = 80 / view[2];
      } else {
        scale = 110 / view[2];
      }
    }

    return scale;
  };

  listRef = React.createRef();

  componentDidUpdate(prevProps: any) {
    const { activePage } = this.props;

    if (activePage !== prevProps.activePage) {
      this.listRef.current.scrollToItem(activePage - 1);
    }
  }

  getItemData = memoize((pages, activePage, onSelectePage, cn) => ({
    pages,
    activePage,
    onSelectePage,
    cn,
  }));

  getItemSize = index => {
    const page = this.props.pages[index];
    if (page === undefined) return 0;

    const scale = DocumentPreview.getPageScale(page);
    const viewport = page.getViewport({
      scale,
    });

    return viewport.height + 43;
  };

  renderItem = memo(params => {
    const { index, style, data } = params;

    const page = data.pages[index];

    return (
      <div style={style}>
        <div
          className={data.cn('item', {
            active: data.activePage === page.pageNumber,
          })}
        >
          <Page
            className={data.cn('preview')}
            pageNumber={page.pageNumber}
            scale={DocumentPreview.getPageScale(page)}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onClick={() => data.onSelectePage(page.pageNumber)}
          />
          <p className={data.cn('number')}>{page.pageNumber}</p>
        </div>
      </div>
    );
  }, areEqual);

  render(cn) {
    const { pages, activePage, onSelectePage } = this.props;

    const itemData = this.getItemData(pages, activePage, onSelectePage, cn);

    return (
      <Scrollbars autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
        <div className={cn()}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeList
                  ref={this.listRef as any}
                  height={height}
                  width={width}
                  itemCount={pages.length}
                  itemSize={this.getItemSize}
                  itemData={itemData}
                  overscanCount={15}
                >
                  {this.renderItem}
                </VariableSizeList>
              );
            }}
          </AutoSizer>
        </div>
      </Scrollbars>
    );
  }
}

export default DocumentPreview;
