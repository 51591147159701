import React, { Component } from 'react';
import { connect } from 'react-redux';

import FilesFilters from '../../FilesFilters';
import DocumentsList from '../../../components/DocumentsList';

import * as FileActions from '../../../actions/files';

import cn from '../../../utils/cn';

import '../../../styles/global.scss';

type FilesProps = {
  meetings?: any[];
  committeeList?: any[];
  locale?: string;
  query?: string;
  isFetching?: boolean;
  offset?: number;
  limit?: number;
  filters?: any;
  total?: number;
  getList: (...args: any[]) => any;
  refreshList: (...args: any[]) => any;
  getMore: (...args: any[]) => any;
  isShowCanseledMeetings?: boolean;
};

@cn('files')
class Files extends Component<FilesProps> {
  handleGetFiles = (limit, offset, params) => {
    this.props.getList(limit, offset, params);
  };

  onDownloadFile = () => {
    const { refreshList, limit, offset, filters } = this.props;
    refreshList(limit, offset, filters);
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    return (
      <main className={`${cn()} container container--with-padding-top`}>
        <section className="container__small-column">
          <FilesFilters
            list={this.props.meetings}
            offset={this.props.offset}
            total={this.props.total}
            isFetching={this.props.isFetching}
            query={this.props.query}
            listTypes={this.props.committeeList}
            locale={this.props.locale}
            getFiles={this.handleGetFiles}
            getFilesMore={this.props.getMore}
          />
        </section>
        <article className="container__large-column">
          <DocumentsList
            isMeetings
            isShowCanseledMeetings={this.props.isShowCanseledMeetings}
            list={this.props.meetings}
            locale={this.props.locale}
            parentType="file"
            isFetching={this.props.isFetching}
            onDownloadFile={this.onDownloadFile}
          />
        </article>
      </main>
    );
  }
}

const mapStateToProps = ({ i18n, committeeList, files, filters, app }) => ({
  isShowCanseledMeetings: app.isShowCanseledMeetings,
  committeeList: committeeList.items,
  meetings: files.items,
  offset: files.offset,
  limit: files.limit,
  filters: files.filters,
  total: files.total,
  query: filters.query,
  isFetching: files.isFetching,
  locale: i18n.locale,
});

const mapDispatchToProps = {
  getList: FileActions.getList,
  refreshList: FileActions.refreshList,
  getMore: FileActions.getMore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
)(Files);
