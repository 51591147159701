import React, { Component } from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';

import withSortedFiles from '../../containers/HOC/withSortedFiles';
import NewFilesList, { DocType } from '../NewFilesList';
import cn from '../../utils/cn';
import * as locales from '../../constants/locales';

import './styles.scss';

type Props = {
  documents?: any;
  locale?: string;
  className?: string;
  localFiles?: any[];
  commonFiles?: any[];
  meeting?: any;
  onDownloadFile: (...args: any[]) => any;
  intl: IntlShape;
};

type State = any;

@cn('meeting-files')
class MeetingFiles extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTabIndex: props.locale || locales.EN,
    };
  }

  downloadAllFns = {
    minutes: {
      rus: () => {},
      eng: () => {},
    },
    eng: () => {},
    rus: () => {},
  };

  downloadAllRus = docType => {
    const { documents } = this.props;
    if (documents.minutes.length) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      this.downloadAllFns.minutes.rus(docType);
    }
    if (documents.rus.length) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      this.downloadAllFns.rus(docType);
    }
  };

  downloadAllEng = docType => {
    const { documents } = this.props;
    if (documents.minutes.length) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      this.downloadAllFns.minutes.eng(docType);
    }
    if (documents.eng.length) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      this.downloadAllFns.eng(docType);
    }
  };

  onDownloadAll = (locale, docType) => {
    if (locale === locales.EN) {
      this.downloadAllEng(docType);
    }
    if (locale === locales.RU) {
      this.downloadAllRus(docType);
    }
  };

  renderDownloadAll = cn => {
    const { intl } = this.props;
    const { selectedTabIndex } = this.state;

    const engText = intl.formatMessage({
      id: 'meeting.downloadAllEngDocuments',
    });
    const rusText = intl.formatMessage({
      id: 'meeting.downloadAllRusDocuments',
    });

    return (
      <div className={cn('download-all')} onClick={() => this.onDownloadAll(selectedTabIndex, DocType.original)}>
        {selectedTabIndex === locales.EN ? engText : rusText}
      </div>
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { intl, onDownloadFile, documents } = this.props;
    const { selectedTabIndex } = this.state;

    const originalsText = intl.formatMessage({ id: 'files.originals' });
    const engTabDocLength = documents.minutes.length + documents.eng.length;
    const rusTabDocLength = documents.minutes.length + documents.rus.length;

    const engFiles = documents.eng;
    const rusFiles = documents.rus;
    const minutesFiles = documents.minutes;

    return (
      <div className={cn('container')}>
        <Tabs
          defaultIndex={selectedTabIndex === locales.EN ? 1 : 0}
          onSelect={index =>
            this.setState({
              selectedTabIndex: index === 1 ? locales.EN : locales.RU,
            })
          }
          forceRenderTabPanel
        >
          <TabList className={cn('tab-list')}>
            <Tab className={cn('tab')} selectedClassName={cn('tab--selected')}>
              {intl.formatMessage({ id: 'meeting.rusDocuments' })}{' '}
              <span className={cn('docs-count')}>{rusTabDocLength}</span>
            </Tab>
            <Tab className={cn('tab')} selectedClassName={cn('tab--selected')}>
              {intl.formatMessage({ id: 'meeting.engDocuments' })}{' '}
              <span className={cn('docs-count')}>{engTabDocLength}</span>
            </Tab>
          </TabList>
          {this.renderDownloadAll(cn)}
          <TabPanel>
            {minutesFiles.length > 0 && (
              <NewFilesList
                // @ts-expect-error ts-migrate(2322) FIXME: Property 'originalsText' does not exist on type 'I... Remove this comment to see the full error message
                originalsText={originalsText}
                title={intl.formatMessage({ id: 'meeting.minutesDocuments' })}
                files={documents.minutes}
                onDownloadFile={onDownloadFile}
                parentType="meeting"
                parentId={this.props.meeting.id}
                makeDownloadAllFn={fn => {
                  this.downloadAllFns.minutes.rus = fn;
                }}
              />
            )}
            {rusFiles.length > 0 && (
              <NewFilesList
                // @ts-expect-error ts-migrate(2322) FIXME: Property 'originalsText' does not exist on type 'I... Remove this comment to see the full error message
                originalsText={originalsText}
                title={intl.formatMessage({ id: 'meeting.files' })}
                files={rusFiles}
                onDownloadFile={onDownloadFile}
                parentType="meeting"
                parentId={this.props.meeting.id}
                makeDownloadAllFn={fn => {
                  this.downloadAllFns.rus = fn;
                }}
              />
            )}

            {rusTabDocLength === 0 && (
              <div className={cn('no-files-text')}>{intl.formatMessage({ id: 'meeting.noDocuments' })}</div>
            )}
          </TabPanel>
          <TabPanel>
            {minutesFiles.length > 0 && (
              <NewFilesList
                // @ts-expect-error ts-migrate(2322) FIXME: Property 'originalsText' does not exist on type 'I... Remove this comment to see the full error message
                originalsText={originalsText}
                title={intl.formatMessage({ id: 'meeting.minutesDocuments' })}
                files={documents.minutes}
                onDownloadFile={onDownloadFile}
                parentType="meeting"
                parentId={this.props.meeting.id}
                makeDownloadAllFn={fn => {
                  this.downloadAllFns.minutes.eng = fn;
                }}
              />
            )}
            {engFiles.length > 0 && (
              <NewFilesList
                // @ts-expect-error ts-migrate(2322) FIXME: Property 'originalsText' does not exist on type 'I... Remove this comment to see the full error message
                originalsText={originalsText}
                title={intl.formatMessage({ id: 'meeting.files' })}
                files={engFiles}
                onDownloadFile={onDownloadFile}
                parentType="meeting"
                parentId={this.props.meeting.id}
                makeDownloadAllFn={fn => {
                  this.downloadAllFns.eng = fn;
                }}
              />
            )}
            {engTabDocLength === 0 && (
              <div className={cn('no-files-text')}>{intl.formatMessage({ id: 'meeting.noDocuments' })}</div>
            )}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof HOC' is not assignable to type 'Compo... Remove this comment to see the full error message
export default injectIntl(withSortedFiles(MeetingFiles));
