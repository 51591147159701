import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import MeetingListItem from '../../components/MeetingListItem';
import { selectMeeting } from '../../actions/meetings';

type MeetingListItemContainerProps = {
  isToday?: boolean;
  history?: any;
  locale?: string;
  item?: any;
  modClass?: string;
  intl?: any;
  currentId?: number;
  selectMeeting: (...args: any[]) => any;
  getOffTop?: (...args: any[]) => any;
  entity: string;
};

class MeetingListItemContainer extends React.Component<MeetingListItemContainerProps> {
  handleSelectItem = item => {
    this.props.history.push({
      pathname: `${this.props.entity}/${item.id}`,
    });
    this.props.selectMeeting(item);
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
      <MeetingListItem
        currentId={this.props.currentId}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
        getOffTop={this.props.getOffTop}
        onSelectItem={this.handleSelectItem}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ i18n, scrollableList }) => ({
  locale: i18n.locale,
  currentId: scrollableList.selectedItem.id,
});

const mapDispatchToProps = {
  selectMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetingListItemContainer as any) as any);
