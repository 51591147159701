import {
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_REPORTS_REQUEST,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAILURE,
  REPORT_MORE_FAILURE,
  REPORT_MORE_REQUEST,
  REPORT_MORE_SUCCESS,
  CLEAR_REPORT_LIST,
  REPORT_GET_TYPE_REQUEST,
  REPORT_GET_TYPE_FAILURE,
  REPORT_GET_TYPE_SUCCESS,
  GET_REPORT_ROOT_REQUEST,
  GET_REPORT_ROOT_SUCCESS,
  GET_REPORT_ROOT_FAILURE,
  GET_REPORT_FOLDER_REQUEST,
  GET_REPORT_FOLDER_SUCCESS,
  GET_REPORT_FOLDER_FAILURE,
  DOCUMENT_SEARCH_REQUEST,
  DOCUMENT_SEARCH_SUCCESS,
  DOCUMENT_SEARCH_FAILURE,
  DOCUMENT_SEARCH_CLEAR,
} from '../constants/ActionTypes';

const defaultState = {
  types: [],
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
  isFetching: false,
  getReportTypesFetching: false,
  isFoldersFetching: false,
  root: null,
  folder: null,
  error: null,
  filters: {},
};

const files = (state = defaultState, action) => {
  switch (action.type) {
    case REPORT_GET_TYPE_REQUEST:
      return {
        ...state,
        getReportTypesFetching: true,
      };
    case REPORT_GET_TYPE_FAILURE:
      return {
        ...state,
        ...action.error,
        getReportTypesFetching: false,
      };
    case REPORT_GET_TYPE_SUCCESS:
      return {
        ...state,
        types: action.data,
        getReportTypesFetching: false,
      };
    case GET_REPORTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        items: action.data.items,
        limit: action.data.limit,
        offset: action.data.offset,
        total: action.data.total,
        isFetching: false,
      };
    case GET_REPORTS_FAILURE:
      return {
        ...state,
        ...action.error,
        isFetching: false,
      };
    case CLEAR_REPORT_LIST:
      return {
        ...state,
        items: [],
        limit: 0,
        offset: 0,
        total: 0,
      };
    case REPORT_MORE_REQUEST:
    case GET_REPORT_REQUEST:
      return {
        ...state,
        filters: action.filters,
        isFetching: action.withFetching,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        ...action.data,
        items: action.data.items,
        isFetching: false,
      };
    case REPORT_MORE_SUCCESS:
      return {
        ...state,
        ...action.data,
        items: [...state.items, ...action.data.items],
        isFetching: false,
      };
    case REPORT_MORE_FAILURE:
    case GET_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    case GET_REPORT_ROOT_REQUEST:
      return {
        ...state,
        isFoldersFetching: true,
      };
    case GET_REPORT_ROOT_SUCCESS:
      return {
        ...state,
        isFoldersFetching: false,
        root: action.data,
      };
    case GET_REPORT_ROOT_FAILURE:
      return {
        ...state,
        isFoldersFetching: false,
        error: action.error,
      };

    case GET_REPORT_FOLDER_REQUEST:
      return {
        ...state,
        isFoldersFetching: true,
      };
    case GET_REPORT_FOLDER_SUCCESS:
      return {
        ...state,
        isFoldersFetching: false,
        folder: action.data,
      };
    case GET_REPORT_FOLDER_FAILURE:
      return {
        ...state,
        isFoldersFetching: false,
        error: action.error,
      };
    case DOCUMENT_SEARCH_REQUEST:
      return {
        ...state,
        isSearchFetching: true,
      };
    case DOCUMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isSearchFetching: false,
        documentSearchResult: action.data,
      };
    case DOCUMENT_SEARCH_FAILURE:
      return {
        ...state,
        isSearchFetching: false,
        error: action.error,
      };
    case DOCUMENT_SEARCH_CLEAR:
      return {
        ...state,
        documentSearchResult: undefined,
      };
    default:
      return state;
  }
};

export default files;
