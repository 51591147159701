import * as types from '../constants/ActionTypes';

const initialState = {
  isFetching: false,
  id: null,
  englishName: '',
  englishPosition: '',
  russianName: '',
  russianPosition: '',
  phone: '',
  avatarUrl: '',
  isLoaded: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        errorCode: null,
        errorMessage: '',
      };
    case types.PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorCode: action.errorCode,
        error: action.error,
        errorMessage: action.message,
      };
    case types.PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        ...data,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
