import React from 'react';

import Input from '../Input';

import './styles.scss';

type Props = {
  isClear?: boolean;
  query?: string;
  onChange: (...args: any[]) => any;
};

type State = any;

class Search extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isClear: this.props.isClear,
      query: this.props.query,
    };
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Search'.
    this.timer = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isClear !== prevState.isClear) {
      return {
        isClear: nextProps.isClear,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.isClear !== prevState.isClear && this.state.isClear) {
      this.setState({
        query: '',
      });
    }
  }

  handleChangeWithTimer = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Search'.
    if (this.timer === null) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Search'.
      this.timer = setTimeout(() => {
        this.props.onChange(this.state.query);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Search'.
        clearTimeout(this.timer);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Search'.
        this.timer = null;
      }, 200);
    }
  };

  handleChange = e => {
    e.preventDefault();
    const val = e.target.value;
    this.setState({ query: val });
    this.handleChangeWithTimer();
  };

  render() {
    return (
      <div className="search">
        <Input onChange={this.handleChange} value={this.state.query} className="search__input" />
      </div>
    );
  }
}

export default Search;
