import React from 'react';
import DatePicker from 'react-datepicker';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import 'moment/locale/ru';

import CalendarContainer from './CalendarContainer';
import ButtonDatepicker from './ButtonDatepicker';

import * as locales from '../../constants/locales';

import { getYear, getMonth, format } from 'date-fns';

import './styles.scss';

type OwnProps = {
  showTimeSelect?: boolean;
  selectedDate?: any;
  onChange?: (...args: any[]) => any;
  onOpen?: (...args: any[]) => any;
  includeDates?: any[];
  onMonthChange?: (...args: any[]) => any;
  onYearChange?: (...args: any[]) => any;
  required?: boolean;
  minDate?: any;
  maxDate?: any;
  placeholderText?: string;
  locale?: string;
  openToDate?: any;
};

type State = any;

type Props = OwnProps & typeof Datepicker.defaultProps;

class Datepicker extends React.PureComponent<Props, State> {
  static defaultProps = {
    required: false,
    includeDates: [],
    onOpenCalendar: () => {},
    onChange: () => {},
    onMonthChange: () => {},
    onYearChange: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      startDate: this.props.selectedDate,
      currentYear: getYear(this.props.selectedDate),
      isOpen: false,
      includeDates: this.props.includeDates,
      highlightDates: this.highlightWithRanges(this.props.includeDates),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedDate !== nextProps.selectedDate || prevState.includeDates !== nextProps.includeDates) {
      return {
        startDate: nextProps.selectedDate,
        includeDates: nextProps.includeDates,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.currentYear !== prevState.currentYear) {
      this.hendleYearChange(this.state.currentYear);
    }
    if (this.state.includeDates !== prevState.includeDates) {
      this.setState(state => {
        return {
          highlightDates: this.highlightWithRanges(state.includeDates),
        };
      });
    }
  }

  highlightWithRanges = array => {
    const now = new Date();
    const active = [];
    const noActive = [];
    array.forEach(date => {
      const cur = new Date(date);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
      cur >= now ? active.push(cur) : noActive.push(cur);
    });
    return [{ 'datepicker-days__active': active }, { 'datepicker-days__noactive': noActive }];
  };

  handleOpen = () => {
    this.setState(state => {
      return {
        isOpen: !state.isOpen,
      };
    });

    if (!this.state.isOpen) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onOpen(this.state.currentYear);
    }
  };

  handleChange = date => {
    const newDate = new Date(date);
    this.setState({
      startDate: newDate,
      isOpen: false,
    });
    this.props.onChange(newDate);
  };

  hendleMonthChange = date => {
    this.setState({
      currentYear: getYear(date),
    });
    this.props.onMonthChange(getMonth(date));
  };

  hendleYearChange = year => {
    this.props.onYearChange(year);
  };

  render() {
    const { minDate, maxDate, placeholderText, openToDate, locale } = this.props;

    const currentLocale = locale === 'ru' ? ru : en;

    const datePickerProps = {
      useShortMonthInDropdown: true,
      includeDates: isEmpty(this.state.includeDates)
        ? undefined
        : this.state.includeDates.map(date => new Date(moment.utc(date).toISOString())),
      dateFormatCalendar: 'MMMM yyyy',
      showTimeSelect: this.props.showTimeSelect,
      timeFormat: 'HH:mm',
      dateFormat: locale === locales.EN ? 'MMM dd, yyyy' : 'dd MMMM yyyy',
      selected: new Date(this.state.startDate),
      onChange: this.handleChange,
      onMonthChange: this.hendleMonthChange,
      minDate,
      maxDate,
      placeholderText,
      openToDate,
      locale: currentLocale,
      dayClassName: () => 'datepicker-days',
      calendarContainer: CalendarContainer,
      inline: this.state.isOpen,
      onClickOutside: e => {
        if (e.target.getAttribute('class') !== 'button-datepicker') {
          this.setState({ isOpen: false });
        }
      },
      highlightDates: this.state.highlightDates,
      forceShowMonthNavigation: true,
    };
    return (
      <div className="datepicker">
        <ButtonDatepicker
          value={format(this.state.startDate, datePickerProps.dateFormat, { locale: currentLocale })}
          onClickHandle={this.handleOpen}
        />
        {this.state.isOpen && <DatePicker {...datePickerProps} />}
      </div>
    );
  }
}

export default Datepicker;
