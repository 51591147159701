import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import user from './user';
import scrollableList from './scrollableList';
import committeeList from './committeeList';
import filters from './filters';
import meeting from './meeting';
import files from './files';
import reports from './reports';
import members from './members';
import document from './document';
import i18n from './i18n';

const reducers = {
  app,
  i18n,
  auth,
  user,
  meeting,
  files,
  reports,
  members,
  document,
  scrollableList,
  committeeList,
  filters,
};

export default combineReducers(reducers);
