import React from 'react';
import cn from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';

import './styles.scss';

const ToManyError = injectIntl(props => {
  return (
    <div className={cn('to-many-error', props.className)}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'IntlShape' is not assignable to type 'number... Remove this comment to see the full error message */}
      <FormattedMessage id={props.id} />
    </div>
  );
});

export default ToManyError;
