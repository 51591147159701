import {
  CALENDAR_GET_BY_YEAR_REQUEST,
  CALENDAR_GET_BY_YEAR_SUCCESS,
  CALENDAR_GET_BY_YEAR_FAILURE,
  FILTER_SET_QUERY,
  FILTER_SET_TYPE,
  FILTER_SET_DATE,
} from '../constants/ActionTypes';

const defaultState = {
  items: [],
  year: null,
  date: null,
  type: null,
  filter: null,
  query: '',
  searchBy: null,
  isFetching: false,
};

const filters = (state = defaultState, action) => {
  switch (action.type) {
    case FILTER_SET_QUERY:
      return {
        ...state,
        type: null,
        date: null,
        query: action.query,
        searchBy: action.searchBy !== null ? action.searchBy : state.searchBy,
      };
    case FILTER_SET_TYPE:
      return {
        ...state,
        date: null,
        query: '',
        type: action.selectType,
      };
    case FILTER_SET_DATE:
      return {
        ...state,
        type: null,
        query: '',
        date: action.date,
        filter: action.filter,
      };
    case CALENDAR_GET_BY_YEAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CALENDAR_GET_BY_YEAR_SUCCESS:
      return {
        ...state,
        items: [...action.data],
        isFetching: false,
      };
    case CALENDAR_GET_BY_YEAR_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default filters;
