import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReportsList from './ReportsList';
import Report from './Report';
import SearchModal from './SearchModal';
import * as ReportsActions from '../../../actions/reports';
import * as routes from '../../../constants/routes';

import cn from '../../../utils/cn';

import '../../../styles/global.scss';
import './styles.scss';
import NoResults from '../../../components/NoResults';

type ReportsProps = {
  reports?: any[];
  report?: {};
  reportTypes?: any[];
  locale?: string;
  query?: string;
  isFetching?: boolean;
  offset?: number;
  limit?: number;
  filters?: any;
  total?: number;
  getList: (...args: any[]) => any;
  refreshList: (...args: any[]) => any;
  getMore: (...args: any[]) => any;
  getReportTypes: (...args: any[]) => any;
  getReports: (...args: any[]) => any;
};

@cn('reports')
class Reports extends Component<ReportsProps> {
  componentDidMount() {
    this.props.getReports();
    this.props.getReportTypes();
  }

  setCurrentTitle = (title, scrollableContainerTopPosition) => {
    if (!title) {
      return;
    }

    const titlePositionCoordinates = title.getBoundingClientRect();
    const titleHeight = titlePositionCoordinates.height;
    const scrollableContainerTopPositionWithOutTitleHeight = scrollableContainerTopPosition - titleHeight;
    if (titlePositionCoordinates.top <= scrollableContainerTopPositionWithOutTitleHeight) {
      this.setState({
        title: title.textContent,
      });
    }
  };

  resetCurrentTitle = scrollableContainer => {
    if (scrollableContainer.scrollTop === 0) {
      this.setState({
        title: '',
      });
    }
  };

  handleOnScroll = (scrollableContainer, upComingTitle, todayTitle, passedTitle, grouped) => {
    const scrollableContainerTopPosition = scrollableContainer.getBoundingClientRect().top;
    this.resetCurrentTitle(scrollableContainer);
    this.setCurrentTitle(upComingTitle, scrollableContainerTopPosition);
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { reports, report, getMore, filters, offset, locale } = this.props;

    if (reports?.length === 0) {
      return (
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'titleId' does not exist on type 'Intrins... Remove this comment to see the full error message
        <NoResults titleId="app.results" />
      );
    }

    const defaultReport = reports?.[0];
    return (
      <main className={`${cn()} container container--with-padding-top`}>
        <section className="container__small-column">
          <ReportsList
            handleOnScroll={this.handleOnScroll}
            reports={reports}
            meetings={reports}
            meeting={report || defaultReport}
            loadMeetingsMore={getMore}
            filters={filters}
            offset={offset}
            entity={routes.reports}
            cn={cn}
          />
        </section>
        <article className="container__large-column">
          <Report cn={cn} defaultReportId={defaultReport?.id} />
        </article>
        <SearchModal cn={cn} locale={locale} />
      </main>
    );
  }
}

const mapStateToProps = ({ i18n, reports, filters }) => ({
  reportTypes: reports.types,
  reports: reports.items,
  report: reports.report,
  offset: reports.offset,
  limit: reports.limit,
  filters: reports.filters,
  total: reports.total,
  query: filters.query,
  isFetching: reports.isFetching,
  locale: i18n.locale,
});

const mapDispatchToProps = {
  getReports: ReportsActions.getReports,
  getList: ReportsActions.getList,
  refreshList: ReportsActions.refreshList,
  getMore: ReportsActions.getMore,
  getReportTypes: ReportsActions.getReportTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
)(Reports);
