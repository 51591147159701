import axios from 'axios';
import { camelizeKeys } from 'humps';

import Storage from '../utils/storage';

import { appConfig } from '../config';
import { version } from '../../package.json';

let isRefreshing = false;
const refreshSubscribers = [];

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8',
};

export function getAuthHeaders() {
  return {
    Authorization: `Bearer: ${Storage.get('accessToken')}`,
  };
}

export function getAppHeaders() {
  return {
    app_platform: 'web',
    app_version: version,
    api_version: '2.0.0',
  };
}

function subscribeTokenRefresh(cb) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  // @ts-expect-error ts-migrate(2349) FIXME: Type 'never' has no call signatures.
  refreshSubscribers.map(cb => cb(token));
}

export const buildHeadersByState = () => {
  const headers = {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...getAppHeaders(),
  };

  return headers;
};

export const setSessionDate = ({ accessToken, refreshToken, expiration }) => {
  Storage.set('accessToken', accessToken);
  Storage.set('refreshToken', refreshToken);
  Storage.set('expiration', expiration);
};

export const axiosInstance = axios.create({
  baseURL: appConfig.apiUrl,
  withCredentials: true,
  timeout: 30000,
});
export const CALL_API = Symbol('Call API');

function actionWith(action, data) {
  const finalAction = Object.assign({}, action, data);

  delete finalAction[CALL_API];

  return finalAction;
}

// eslint-disable-next-line
export default ({ getState }) =>
  next =>
  action => {
    const callAPI = action[CALL_API];

    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    const { body, method } = callAPI;
    let { endpoint } = callAPI;

    const { types } = callAPI;
    const state = getState();

    if (typeof endpoint === 'function') {
      endpoint = endpoint(state);
    }

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }
    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.');
    }

    const [requestType, successType, failureType] = types;
    next(actionWith(action, { type: requestType }));

    const headers = buildHeadersByState();

    let axiosMethod = axiosInstance.post;
    if (method === 'GET') {
      axiosMethod = axiosInstance.get;
    }
    if (method === 'DELETE') {
      axiosMethod = axiosInstance.delete;
    }
    if (method === 'PATCH') {
      axiosMethod = axiosInstance.patch;
    }

    const params = method === 'GET' ? [{ headers }] : [{ ...body }, { headers }];

    return axiosMethod(endpoint, ...params)
      .then(response => {
        const camelizedResult = camelizeKeys(response);
        next(
          actionWith(
            action,
            Object.assign(
              {},
              {
                type: successType,
              },
              { ...camelizedResult }
            )
          )
        );
        return camelizedResult;
      })
      .catch(error => {
        next(
          actionWith(
            action,
            Object.assign(
              {},
              {
                type: failureType,
              },
              {
                errorCode: error.response ? error.response.status : 500,
                error: error.json ? error.json.error : 'Something bad happened',
                message: error.json ? error.json.message : null,
              }
            )
          )
        );
      });
  };

axiosInstance.interceptors.request.use(
  function (requestConfig) {
    return requestConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      config: { resConfig, url },
      response: { status },
    } = error;
    const originalRequest = resConfig;
    if (status === 403 && url !== '/user/confirmPin') {
      if (!isRefreshing) {
        isRefreshing = true;
        axiosInstance
          .post('/user/refreshToken', {
            refreshToken: Storage.get('refreshToken'),
          })
          .then(res => {
            isRefreshing = false;
            setSessionDate(res.data);
            onRrefreshed(res.data.accessToken);
          });
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer: ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }

    if (status === 401) {
      Storage.deleteKey('accessToken');
      Storage.deleteKey('refreshToken');
      Storage.deleteKey('expiration');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);
