import React, { PureComponent } from 'react';

import withSortedFiles from '../../containers/HOC/withSortedFiles';
import FilesList from '../FilesList';
import NewFilesList from '../NewFilesList';

type Props = {
  documents?: any;
  localFiles?: any[];
  commonFiles?: any[];
  onDownloadFile: (...args: any[]) => any;
  originalsText: string;
  parentType?: string;
  locale?: string;
};

class Documents extends PureComponent<Props> {
  render() {
    const { localFiles, commonFiles, onDownloadFile, originalsText, parentType } = this.props;

    return (
      <NewFilesList
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'originalsText' does not exist on type 'I... Remove this comment to see the full error message
        files={localFiles.concat(commonFiles)}
        onDownloadFile={onDownloadFile}
        parentType={parentType}
      />
    );
  }
}

export default withSortedFiles(Documents);
