import React, { PureComponent } from 'react';
import { Document } from 'react-pdf';
import memoize from 'memoize-one';

import DocumentPreview from '../DocumentPreview';
import DocumentComments from '../DocumentComments';
import DocumentViewer from '../DocumentViewer';

import Ballon from '../Ballon';
import Loading from '../Loading';

import Mouse from '../../utils/mouse';
import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  documentId: string;
  document?: any;
  getDocument: (...args: any[]) => any;
  getFileRequest: (...args: any[]) => any;
  exitDocument: (...args: any[]) => any;
  isCommentsEnabled?: boolean;
  isCommentsAddingEnabled?: boolean;
  isDrawingEnabled?: boolean;
  isPreviewEnabled?: boolean;
  deleteComment: (...args: any[]) => any;
  editComment: (...args: any[]) => any;
  addComment: (...args: any[]) => any;
  isDrawingEraserEnabled?: boolean;
  drawingColor?: string;
  drawingWeight?: number;
  addDraw: (...args: any[]) => any;
  onPdfDocumentLoaded?: (...args: any[]) => any;
};

type State = any;

@cn('document')
export class DocumentBody extends PureComponent<Props, State> {
  state = {
    containerWidth: '60%',
    activePage: 1,
    comment: null,
    isShowCommentPopup: false,
    isShowBallon: false,
    isLoading: true,
    pages: [],
  };

  componentDidMount() {
    const { documentId, getDocument } = this.props;
    getDocument(documentId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { document, isCommentsAddingEnabled } = this.props;
    const { isShowCommentPopup } = this.state;

    if (document.comments.length !== prevProps.document.comments.length) {
      this.setState({
        comment: null,
        isShowCommentPopup: false,
      });
    }

    if (document.comments !== prevProps.document.comments) {
      this.setState(state => {
        return {
          comment: state.comment ? document.comments.find(item => item.id === state.comment.id) : null,
        };
      });
    }

    if (isShowCommentPopup !== prevState.isShowCommentPopup) {
      if (isShowCommentPopup) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        window.document.querySelector('.document-pdf').addEventListener('click', this.handleToggleCommentPopup);
      } else {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        window.document.querySelector('.document-pdf').removeEventListener('click', this.handleToggleCommentPopup);
      }

      if (!isShowCommentPopup && isCommentsAddingEnabled) {
        this.setState({
          isShowBallon: isCommentsAddingEnabled,
        });
      }
    }

    if (isCommentsAddingEnabled !== prevProps.isCommentsAddingEnabled) {
      this.setState({
        isShowCommentPopup: false,
        comment: null,
        isShowBallon: isCommentsAddingEnabled,
      });
    }
  }

  componentWillUnmount() {
    const { exitDocument } = this.props;

    exitDocument();
    document.removeEventListener('click', this.handleToggleCommentPopup);
  }

  getCommentsWithPositionNum = memoize(comments => {
    return comments.map((item, index) => {
      item.positionNum = index + 1;
      return item;
    });
  });

  onDocumentLoadSuccess = doc => {
    const { onPdfDocumentLoaded } = this.props;

    if (onPdfDocumentLoaded) {
      doc.getData().then(data => {
        onPdfDocumentLoaded(data);
      });
    }

    const pages = [];

    for (let i = 1; i <= doc.numPages; i++) {
      doc.getPage(i).then(page => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        pages.push(page);
        if (pages.length === doc.numPages) {
          this.setState({
            pages,
            isLoading: false,
          });
        }
      });
    }
  };

  handleToggleCommentPopup = e => {
    const target = e.target;
    const popup = window.document.querySelector('.document-comment');
    const commentMark = window.document.querySelector('.document-comments-layer__comment');
    try {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const itsCommentMark = target === commentMark || commentMark.contains(target);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const itsPopup = target === popup || popup.contains(target);
      if (!itsPopup) {
        this.handleCancel();
      }
      if (itsCommentMark) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        commentMark.click();
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  handleInitComment = (e, scale, page, width, height) => {
    const { isCommentsAddingEnabled } = this.props;
    if (!isCommentsAddingEnabled) return;

    const position = Mouse.getClickPosition(e);
    this.setState({
      isShowCommentPopup: true,
      isShowBallon: false,
      comment: {
        isShared: true,
        text: '',
        position: {
          page,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          left: parseInt((position.x * 100) / width, 10),
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          top: parseInt((position.y * 100) / height, 10),
        },
      },
    });
  };

  handleClickCommentInCommentsList = comment => {
    this.setState({
      comment,
      isShowCommentPopup: true,
      activePage: comment.position.page,
    });

    this.handleSelectPage(comment.position.page, comment, true);
  };

  handleCloseCommentPopup = () => {
    this.setState({
      comment: null,
      isShowCommentPopup: false,
    });
  };

  handleClickCommentMarck = comment => {
    const { isCommentsAddingEnabled } = this.props;
    if (isCommentsAddingEnabled) return;

    this.setState({
      comment,
      activePage: comment.position.page,
      isShowCommentPopup: true,
    });
  };

  handleCancel = () => {
    this.handleCloseCommentPopup();
  };

  handleSelectPage = (page, comment, isShowCommentPopup) => {
    this.setState({
      activePage: page,
      isShowCommentPopup: isShowCommentPopup || false,
      comment: isShowCommentPopup ? comment : null,
    });
  };

  handleAddDraw = currentDraw => {
    const { addDraw } = this.props;
    addDraw(currentDraw);
  };

  onChangeActivePage = activePage => {
    this.setState({
      activePage,
    });
  };

  handleSetContainerWeight = width => {
    this.setState({ containerWidth: `${width}px` });
  };

  renderBallon() {
    const { isShowBallon } = this.state;
    if (!isShowBallon) return null;

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'IntlShape... Remove this comment to see the full error message
    return <Ballon textId="comments.ballon" />;
  }

  renderPreview(cn) {
    const { isPreviewEnabled } = this.props;
    const { pages, activePage } = this.state;

    if (!isPreviewEnabled) return null;

    return (
      <div className={cn('small-column')}>
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
        <DocumentPreview pages={pages} activePage={activePage} onSelectePage={this.handleSelectPage} />
      </div>
    );
  }

  renderViewer(cn) {
    const {
      document,
      isDrawingEnabled,
      drawingColor,
      drawingWeight,
      isDrawingEraserEnabled,
      isPreviewEnabled,
      isCommentsEnabled,
      isCommentsAddingEnabled,
      addComment,
      editComment,
      deleteComment,
    } = this.props;
    const { pages, activePage, comment, isShowCommentPopup, containerWidth } = this.state;

    return (
      <div className={cn('large-column')} style={{ width: containerWidth }}>
        {
          // @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
          <DocumentViewer
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={cn('document-pdf')}
            onSetContainerWeight={this.handleSetContainerWeight}
            documentId={document.id}
            pages={pages}
            activePage={activePage}
            onInitComment={this.handleInitComment}
            isDrawingEnabled={isDrawingEnabled}
            drawingColor={drawingColor}
            drawingWeight={drawingWeight}
            isDrawingEraserEnabled={isDrawingEraserEnabled}
            drawings={document.drawings}
            saveDraw={this.handleAddDraw}
            isPreviewEnabled={isPreviewEnabled}
            isCommentsEnabled={isCommentsEnabled}
            isCommentsAddingEnabled={isCommentsAddingEnabled}
            isShowCommentPopup={isShowCommentPopup}
            comments={this.getCommentsWithPositionNum(document.comments)}
            comment={comment}
            addComment={addComment}
            editComment={editComment}
            deleteComment={deleteComment}
            onCloseCommentPopup={this.handleCloseCommentPopup}
            onClickCommentMarck={this.handleClickCommentMarck}
            onChangeActivePage={this.onChangeActivePage}
          />
        }
      </div>
    );
  }

  renderComments(cn) {
    const { document, isCommentsEnabled, deleteComment } = this.props;
    const { comment } = this.state;

    if (!isCommentsEnabled) return null;

    return (
      <div className={cn('medium-column')}>
        {/* @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message */}
        <DocumentComments
          comments={this.getCommentsWithPositionNum(document.comments)}
          activeComment={comment}
          onClickComment={this.handleClickCommentInCommentsList}
          deleteComment={deleteComment}
        />
      </div>
    );
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { document, getFileRequest } = this.props;
    const { isLoading } = this.state;
    let url = document.url;

    if (document.extension !== 'pdf') {
      url = document.pdfURL;
    }

    if (!url) return <Loading />;

    return (
      <Document
        className="document__content-container"
        file={getFileRequest(url)}
        loading={<Loading />}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {this.renderBallon()}
            {this.renderPreview(cn)}
            {this.renderViewer(cn)}
            {this.renderComments(cn)}
          </>
        )}
      </Document>
    );
  }
}
