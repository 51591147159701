import React from 'react';

import '../../styles/blocks/select.scss';

type Props = {
  intl?: any;
  items: any[];
  onSelect: (...args: any[]) => any;
};

class ExportFileDropdown extends React.PureComponent<Props> {
  render() {
    const { items, onSelect } = this.props;

    return (
      <ul className="select__dropdown new-files-list__export-file-dropdown">
        {items.map(item => (
          <li key={`${item.id}_${item.type}`} onClick={() => onSelect(item)} className="select__dropdown-item">
            {item.label}
          </li>
        ))}
      </ul>
    );
  }
}

export default ExportFileDropdown;
