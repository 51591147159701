import React from 'react';
import cn from 'classnames';
import Datepicker from '../Datepicker/index';

type Props = {
  selectedDate?: any;
  className?: string;
  locale?: string;
  includeDates?: any[];
  onChangeDate: (...args: any[]) => any;
  onChangeYear: (...args: any[]) => any;
};

class DateFilter extends React.PureComponent<Props> {
  handleChangeDate = date => {
    this.props.onChangeDate(date);
  };

  handleOpenCalendar = year => {
    this.handleYearChange(year);
  };

  handleYearChange = year => {
    this.props.onChangeYear(year);
  };

  render() {
    return (
      <div className={cn('date-filter', this.props.className)}>
        <Datepicker
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
          includeDates={this.props.includeDates}
          onOpen={this.handleOpenCalendar}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(year: any) => void' is not assignable to ty... Remove this comment to see the full error message
          onYearChange={this.handleOpenCalendar}
          locale={this.props.locale}
          selectedDate={new Date(this.props.selectedDate)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(date: any) => void' is not assignable to ty... Remove this comment to see the full error message
          onChange={this.handleChangeDate}
        />
      </div>
    );
  }
}

export default DateFilter;
