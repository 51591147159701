import React, { Component } from 'react';

import DatePeriod from '../DatePeriod';
import ShowMore from '../ShowMore';

import * as locales from '../../constants/locales';
import { statuses } from '../../constants/meetingStatuses';

import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  locale?: string;
  englishName?: string;
  russianName?: string;
  englishDescription?: string;
  russianDescription?: string;
  dateTimeStart?: string;
  dateTimeEnd?: string;
  status?: number;
  isFetching?: boolean;
  error?: any;
};

type State = any;

@cn('meeting-description')
class MeetingDescription extends Component<Props, State> {
  state = {
    showFullDescription: false,
    showFullButton: false,
    russianDescription: '',
    englishDescription: '',
  };

  toggleFullDescription = () => {
    this.setState(state => {
      return {
        showFullDescription: !state.showFullDescription,
      };
    });
  };

  hideShowMoreButton = () => {
    this.setState({
      showFullButton: false,
    });
  };

  showShowMoreButton = () => {
    this.hideShowMoreButton();
    const fourLinesHeight = 120;
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    if (this.descriptionRef.current.clientHeight >= fourLinesHeight) {
      this.setState({
        showFullButton: true,
      });
    }
  };

  renderTitle = cn => {
    return (
      <h1 className={cn('title')}>
        {this.props.locale === locales.RU ? this.props.russianName : this.props.englishName}
      </h1>
    );
  };

  createMarkup = () => {
    const description =
      this.props.locale === locales.RU ? this.props.russianDescription : this.props.englishDescription;

    /* eslint-disable react/no-danger */

    return (
      <p
        dangerouslySetInnerHTML={{
          __html: description ? description.replace(/\r(?!\n)|\n(?!\r)/g, '<br/>') : '',
        }}
      />
    );

    /* eslint-enable react/no-danger */
  };

  renderDescription = cn => {
    return (
      <div
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLDivE... Remove this comment to see the full error message
        ref={this.descriptionRef}
        className={`${cn('paragraph')} ${this.state.showFullDescription ? cn('paragraph--show-full') : ''}`}
      >
        {this.createMarkup()}
      </div>
    );
  };

  descriptionRef = React.createRef();

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.russianDescription !== prevProps.russianDescription &&
      this.props.englishDescription !== prevProps.englishDescription
    ) {
      this.showShowMoreButton();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.russianDescription !== nextProps.russianDescription &&
      prevState.englishDescription !== nextProps.englishDescription
    ) {
      return {
        showFullDescription: false,
        russianDescription: nextProps.russianDescription,
      };
    }
    return null;
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    return (
      <section className={cn()}>
        {this.renderTitle(cn)}
        <div className={cn('status')}>
          {/* @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
          {statuses[this.props.locale][this.props.status]}
        </div>
        <DatePeriod
          className={cn('time-container')}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'IntlSh... Remove this comment to see the full error message
          dateTimeStart={this.props.dateTimeStart}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'IntlSh... Remove this comment to see the full error message
          dateTimeEnd={this.props.dateTimeEnd}
          locale={this.props.locale}
        />
        {this.renderDescription(cn)}
        <ShowMore
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'isOpen' does not exist on type 'Intrinsi... Remove this comment to see the full error message
          isOpen={!this.state.showFullDescription}
          toggleFull={this.toggleFullDescription}
          className={`${cn('show-more')} ${
            this.state.showFullButton ? 'meeting-description__show-more--show' : ''
          } meeting-button`}
        />
      </section>
    );
  }
}

export default MeetingDescription;
