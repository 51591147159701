import React from 'react';
import cn from 'classnames';

import './styles.scss';

const Logo = props => {
  return (
    <div className={cn('logo', props.className)}>
      <div className="logo__name">BoardApp</div>
      <span className="logo__dots" />
    </div>
  );
};

export default Logo;
