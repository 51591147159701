import React from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import moment from 'moment';

import cn from '../../utils/cn';

import './styles.scss';

const POPUP_WIDTH = 400;
const POPUP_HEIGHT = 210 + 25;

type Props = {
  scale?: number;
  comment?: any;
  currentPage?: number;
  onEdit?: (...args: any[]) => any;
  onCreate?: (...args: any[]) => any;
  onDelete?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
  position?: any;
  containerSize: any;
  intl: IntlShape;
};

type State = any;

@cn('document-comment')
class DocumentComment extends React.PureComponent<Props, State> {
  state = {
    text: this.props.comment ? this.props.comment.text : '',
    isShared: this.props.comment ? this.props.comment.isShared : true,
    viewMode: this.props.comment && this.props.comment.id,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isShared !== nextProps.comment.isShared && nextProps.comment.id) {
      return { isShared: nextProps.comment.isShared };
    }
    return null;
  }

  renderDate = (time, cn) => <time className={cn('time')}>{moment(time).format('ll')}</time>;

  renderSubmitButton = cn => (
    <button type="submit" className={cn('button', { green: true })}>
      <FormattedMessage id="comment.create" />
    </button>
  );

  handleChangeComment = e => {
    this.setState({
      text: e.target.value, // .replace('<', '&laquo').replace('>', '&raquo')
    });
  };

  handleChangePrivateStatus = () => {
    if (this.state.viewMode) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onEdit({
        isShared: !this.state.isShared,
        id: this.props.comment.id,
      });
    } else {
      this.setState(state => {
        return {
          isShared: !state.isShared,
        };
      });
    }
  };

  getPopupPosition = () => {
    const { comment, containerSize } = this.props;

    let left = (comment.position.left * containerSize.width) / 100 - POPUP_WIDTH / 2;
    if (left < 0) {
      left = 25;
    } else if (left + POPUP_WIDTH > containerSize.width) {
      left = containerSize.width - POPUP_WIDTH - 25;
    }

    let top = (comment.position.top * containerSize.height) / 100;
    top = top > POPUP_HEIGHT ? top - POPUP_HEIGHT - 25 : top + 25;

    const styles = {
      left,
      top,
    };

    return styles;
  };

  handleSubmitComment = e => {
    e.preventDefault();
    if (this.state.text !== '') {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onCreate({
        isShared: this.state.isShared,
        text: this.state.text,
        position: {
          page: this.props.currentPage,
          top: this.props.comment.position.top,
          left: this.props.comment.position.left,
        },
      });
    }
  };

  handleDelete = () => {
    if (this.state.viewMode) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onDelete(this.props.comment.id);
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onCancel();
    }
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { comment, intl } = this.props;
    const styles = comment ? this.getPopupPosition() : '';
    return (
      <form
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'CSSProper... Remove this comment to see the full error message
        style={styles}
        onSubmit={this.handleSubmitComment}
        className={this.state.viewMode ? cn({ ready: true }) : cn()}
      >
        <div className={cn('content-container')}>
          <fieldset className={cn('comment-container')}>
            <textarea
              readOnly={this.state.viewMode}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
              rows="6"
              onChange={this.handleChangeComment}
              placeholder={intl.formatMessage({
                id: 'textarea.placeholder.comment',
              })}
              className={cn('comment')}
              value={this.state.text}
            />
            {this.state.viewMode && this.renderDate(comment.createdDate, cn)}
          </fieldset>
          <div className={cn('navigate-container')}>
            <div className={cn('buttons-container')}>
              <button onClick={this.handleDelete} type="button" className={cn('button')}>
                <FormattedMessage id="comment.remove" />
              </button>
              {!this.state.viewMode && this.renderSubmitButton(cn)}
            </div>
            <div className={cn('checkbox-container')}>
              <span className={cn('text')}>
                <FormattedMessage id="comment.private" />
              </span>
              <label className={cn('checkbox-input-container')}>
                <input
                  onChange={this.handleChangePrivateStatus}
                  checked={!this.state.isShared}
                  className={cn('checkbox')}
                  type="checkbox"
                />
                <i className={cn('custom-checkbox')} />
              </label>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof DocumentComment' is not assignable to... Remove this comment to see the full error message
export default injectIntl(DocumentComment);
