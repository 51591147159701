import React from 'react';

import cn from '../../utils/cn';

import './styles.scss';

type Props = {
  page?: number;
  width?: number;
  height?: number;
  scale?: number;
  comments?: any[];
  onClickComment: (...args: any[]) => any;
  onClickLayer: (...args: any[]) => any;
};

@cn('document-comments-layer')
class DocumentPageCommentsLayer extends React.PureComponent<Props> {
  documentCommentsLayerRef = React.createRef();

  handleClickComment = comment => {
    this.props.onClickComment(comment);
  };

  handleClickLayer = e => {
    this.props.onClickLayer(
      this.documentCommentsLayerRef,
      e,
      this.props.scale,
      this.props.page,
      this.props.width,
      this.props.height
    );
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { width, height, comments } = this.props;

    return (
      <div
        onClick={this.handleClickLayer}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLDivE... Remove this comment to see the full error message
        ref={this.documentCommentsLayerRef}
        className={cn()}
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {comments.map(item => (
          <div
            className={cn('comment')}
            onClick={() => this.handleClickComment(item)}
            key={`comment_on_page_${item.id}`}
            style={{
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              left: `${(item.position.left * width) / 100}px`,
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              top: `${(item.position.top * height) / 100}px`,
            }}
          >
            <span className={cn('content')}>{item.positionNum}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default DocumentPageCommentsLayer;
