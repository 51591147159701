import * as locales from './locales';

export const PREPARE = 0;
export const READY = 1;
export const CANCELED = 2;
export const LOGGED = 3;
export const statuses = {
  [locales.EN]: {
    [PREPARE]: 'prepare',
    [READY]: 'ready',
    [CANCELED]: 'canceled',
    [LOGGED]: 'logged',
  },
  [locales.RU]: {
    [PREPARE]: 'готовится',
    [READY]: 'Готово',
    [CANCELED]: 'Отменено',
    [LOGGED]: 'logged',
  },
};

export const defaultStatus = PREPARE;
