export const COLORS = {
  blue: 'rgba(52,187,255, 0.5)',
  pink: 'rgba(255,102,255, 0.5)',
  red: 'rgba(255,102,102, 0.5)',
  orange: 'rgba(255,153,51, 0.5)',
  green: 'rgba(41,204,41, 0.5)',
  yellow: 'rgba(230,230,92, 0.5)',
  white: 'rgba(255,255,255, 0.5)',
};
export const RANGE = {
  min: 2,
  max: 10,
  maxEraser: 15,
};
