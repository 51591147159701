export const SOME_ACTION_TYPE = 'SOME_ACTION_TYPE';

export const SET_LOCALE = 'set_locale';
export const ADD_LOCALE = 'add_locale';

export const SET_STORAGE = 'set_storage';
export const ADD_STORAGE = 'add_storage';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CHEAK = 'LOGIN_CHEAK';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const ABSENT_SUCCESS = 'ABSENT_SUCCESS';

export const SMS_REQUEST = 'SMS_REQUEST';
export const SMS_SUCCESS = 'SMS_SUCCESS';
export const SMS_FAILURE = 'SMS_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_REQUEST = 'GET_LIST_REQUEST';
export const GET_LIST_FAILURE = 'GET_LIST_FAILURE';
export const LIST_MORE_REQUEST = 'LIST_MORE_REQUEST';
export const LIST_MORE_SUCCESS = 'LIST_MORE_SUCCESS';
export const LIST_MORE_FAILURE = 'LIST_MORE_FAILURE';
export const LIST_MORE_UP_REQUEST = 'LIST_MORE_UP_REQUEST';
export const LIST_MORE_UP_SUCCESS = 'LIST_MORE_UP_SUCCESS';
export const LIST_MORE_UP_FAILURE = 'LIST_MORE_UP_FAILURE';
export const CLEAR_LIST = 'CLEAR_LIST';

export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_REQUEST = 'GET_FILES_REQUEST';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';
export const FILES_MORE_REQUEST = 'FILES_MORE_REQUEST';
export const FILES_MORE_SUCCESS = 'FILES_MORE_SUCCESS';
export const FILES_MORE_FAILURE = 'FILES_MORE_FAILURE';
export const CLEAR_FILES_LIST = 'CLEAR_FILES_LIST';

export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const GET_REPORT_ROOT_REQUEST = 'GET_REPORT_ROOT_REQUEST';
export const GET_REPORT_ROOT_SUCCESS = 'GET_REPORT_ROOT_SUCCESS';
export const GET_REPORT_ROOT_FAILURE = 'GET_REPORT_ROOT_FAILURE';
export const GET_REPORT_FOLDER_REQUEST = 'GET_REPORT_FOLDER_REQUEST';
export const GET_REPORT_FOLDER_SUCCESS = 'GET_REPORT_FOLDER_SUCCESS';
export const GET_REPORT_FOLDER_FAILURE = 'GET_REPORT_FOLDER_FAILURE';
export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const REPORT_MORE_REQUEST = 'REPORT_MORE_REQUEST';
export const REPORT_MORE_SUCCESS = 'REPORT_MORE_SUCCESS';
export const REPORT_MORE_FAILURE = 'REPORT_MORE_FAILURE';
export const CLEAR_REPORT_LIST = 'CLEAR_REPORT_LIST';
export const REPORT_GET_TYPE_REQUEST = 'REPORT_GET_TYPE_REQUEST';
export const REPORT_GET_TYPE_SUCCESS = 'REPORT_GET_TYPE_SUCCESS';
export const REPORT_GET_TYPE_FAILURE = 'REPORT_GET_TYPE_FAILURE';

export const SEARCH_MEMBERS_SUCCESS = 'SEARCH_MEMBERS_SUCCESS';
export const SEARCH_MEMBERS_REQUEST = 'SEARCH_MEMBERS_REQUEST';
export const SEARCH_MEMBERS_FAILURE = 'SEARCH_MEMBERS_FAILURE';
export const GET_MEMBERS_GROUPS_REQUEST = 'GET_MEMBERS_GROUPS_REQUEST';
export const GET_MEMBERS_GROUPS_SUCCESS = 'GET_MEMBERS_GROUPS_SUCCESS';
export const GET_MEMBERS_GROUPS_FAILURE = 'GET_MEMBERS_GROUPS_FAILURE';
export const GET_MEMBERS_BY_GROUP_REQUEST = 'GET_MEMBERS_BY_GROUP_REQUEST';
export const GET_MEMBERS_BY_GROUP_SUCCESS = 'GET_MEMBERS_BY_GROUP_SUCCESS';
export const GET_MEMBERS_BY_GROUP_FAILURE = 'GET_MEMBERS_BY_GROUP_FAILURE';
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAILURE = 'GET_MEMBER_FAILURE';

export const COMMITTEE_LIST_REQUEST = 'COMMITTEE_LIST_REQUEST';
export const COMMITTEE_LIST_SUCCESS = 'COMMITTEE_LIST_SUCCESS';
export const COMMITTEE_LIST_FAILURE = 'COMMITTEE_LIST_FAILURE';

export const CALENDAR_GET_BY_YEAR_REQUEST = 'CALENDAR_GET_BY_YEAR_REQUEST';
export const CALENDAR_GET_BY_YEAR_SUCCESS = 'CALENDAR_GET_BY_YEAR_SUCCESS';
export const CALENDAR_GET_BY_YEAR_FAILURE = 'CALENDAR_GET_BY_YEAR_FAILURE';
export const FILTER_SET_TYPE = 'FILTER_SET_TYPE';
export const FILTER_SET_DATE = 'FILTER_SET_DATE';
export const FILTER_SET_QUERY = 'FILTER_SET_QUERY';

export const MEETING_GET_SUCCESS = 'MEETING_GET_SUCCESS';
export const MEETING_GET_REQUEST = 'MEETING_GET_REQUEST';
export const MEETING_GET_FAILURE = 'MEETING_GET_FAILURE';
export const LIST_SET_ACTIVE = 'LIST_SET_ACTIVE';

export const DOCUMENT_GET_REQUEST = 'DOCUMENT_GET_REQUEST';
export const DOCUMENT_GET_SUCCESS = 'DOCUMENT_GET_SUCCESS';
export const DOCUMENT_GET_FAILURE = 'DOCUMENT_GET_FAILURE';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAILURE = 'COMMENT_DELETE_FAILURE';
export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_EDIT_REQUEST = 'COMMENT_EDIT_REQUEST';
export const COMMENT_EDIT_SUCCESS = 'COMMENT_EDIT_SUCCESS';
export const COMMENT_EDIT_FAILURE = 'COMMENT_EDIT_FAILURE';
export const COMMENT_ADD_REQUEST = 'COMMENT_ADD_REQUEST';
export const COMMENT_ADD_SUCCESS = 'COMMENT_ADD_SUCCESS';
export const COMMENT_ADD_FAILURE = 'COMMENT_ADD_FAILURE';
export const DRAW_ADD_REQUEST = 'DRAW_ADD_REQUEST';
export const DRAW_ADD_SUCCESS = 'DRAW_ADD_SUCCESS';
export const DRAW_ADD_FAILURE = 'DRAW_ADD_FAILURE';
export const SAVE_TMP_DRAW = 'SAVE_TMP_DRAW';
export const CLEAR_TMP_DRAW = 'CLEAR_TMP_DRAW';

export const DOCUMENT_EXIT = 'DOCUMENT_EXIT';

export const DOCUMENT_SEARCH_REQUEST = 'DOCUMENT_SEARCH_REQUEST';
export const DOCUMENT_SEARCH_SUCCESS = 'DOCUMENT_SEARCH_SUCCESS';
export const DOCUMENT_SEARCH_FAILURE = 'DOCUMENT_SEARCH_FAILURE';
export const DOCUMENT_SEARCH_CLEAR = 'DOCUMENT_SEARCH_CLEAR';
