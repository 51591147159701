import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  LIST_MORE_FAILURE,
  LIST_MORE_REQUEST,
  LIST_MORE_SUCCESS,
  LIST_MORE_UP_FAILURE,
  LIST_MORE_UP_REQUEST,
  LIST_MORE_UP_SUCCESS,
  CLEAR_LIST,
  LIST_SET_ACTIVE,
} from '../constants/ActionTypes';

const defaultState = {
  direction: 'down',
  items: [],
  limit: 0,
  offset: 0,
  offsetBeforeUbpate: 0,
  total: 0,
  selectedItem: {},
  isFetching: false,
  error: null,
};

function addToStart(arr1, arr2) {
  const array = [];
  arr1.forEach(item1 => {
    const find = arr2.find(item2 => item2.id === item1.id);
    if (!find) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      array.push(item1);
    }
  });

  return [...array, ...arr2];
}

const scrollableList = (state = defaultState, action) => {
  switch (action.type) {
    case LIST_SET_ACTIVE:
      return {
        ...state,
        selectedItem: action.item,
      };
    case CLEAR_LIST:
      return {
        ...state,
        items: [],
        limit: 0,
        offset: 0,
        offsetBeforeUbpate: 0,
        total: 0,
      };
    case LIST_MORE_UP_REQUEST:
    case LIST_MORE_REQUEST:
    case GET_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        direction: 'down',
        itemsLengthBeforeUbpate: state.items.length,
        ...action.data,
        items: action.data.items,
        isFetching: false,
      };
    case LIST_MORE_SUCCESS:
      return {
        ...state,
        direction: action.data.items.length ? 'down' : state.direction,
        itemsLengthBeforeUbpate: state.items.length,
        ...action.data,
        offset: action.data.items.length ? action.data.offset : state.offset,
        items: [...state.items, ...action.data.items],
        isFetching: false,
      };
    case LIST_MORE_UP_SUCCESS:
      return {
        ...state,
        direction: action.data.items.length ? 'up' : state.direction,
        itemsLengthBeforeUbpate: state.items.length,
        ...action.data,
        offset: action.data.items.length ? action.data.offset : state.offset,
        items: addToStart(action.data.items, state.items),
        isFetching: false,
      };
    case LIST_MORE_UP_FAILURE:
    case LIST_MORE_FAILURE:
    case GET_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default scrollableList;
