import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';

import MenuProfile from '../../containers/MenuProfile';
import MainFilters from '../../containers/MainFilters';

import * as routes from '../../constants/routes';
import { searchBy as searchSection } from '../../constants/search';

import { regexpText } from '../../utils/utils';

import './styles.scss';

const Header = injectIntl(
  withRouter(props => {
    let sectionNameId = '';
    let searchBy = searchSection.MEETINGS;
    const isReport = regexpText(props.location.pathname, `${routes.reports}`);
    const isMeeting = regexpText(props.location.pathname, `${routes.meetings}`);

    if (regexpText(props.location.pathname, `${routes.files}`)) {
      sectionNameId = 'menu.files';
      searchBy = searchSection.FILES;
    } else if (regexpText(props.location.pathname, `${routes.reports}`)) {
      searchBy = searchSection.REPORTS;
    } else if (regexpText(props.location.pathname, `${routes.members}`)) {
      sectionNameId = 'menu.members';
      searchBy = searchSection.MEMBERS;
    } else if (regexpText(props.location.pathname, `${routes.document}`)) {
      sectionNameId = 'menu.document';
    }

    return (
      <ul className="header">
        <li className="header__left">
          {!isReport && !isMeeting && (
            <h3 className="header__section-name">
              <FormattedMessage id={sectionNameId} />
            </h3>
          )}
          <MainFilters
            isTypeFilrter={isMeeting}
            isDateFilter={isMeeting}
            isSearchFilter
            searchBy={searchBy}
            location={props.location}
            history={props.history}
            filterReportsType={isReport}
          />
        </li>
        <li className="header__right">
          <ul className="header__menu">
            <li className="header__item">
              <NavLink className="header__item-link" activeClassName="header__item-link--active" to={routes.meetings}>
                <FormattedMessage id="menu.meetings" />
              </NavLink>
            </li>
            {/* <li className='header__item'>
          <NavLink className='header__item-link' activeClassName='header__item-link--active' to={routes.document}>
          <FormattedMessage id='menu.document' /></NavLink></li> */}
            <li className="header__item">
              <NavLink className="header__item-link" activeClassName="header__item-link--active" to={routes.files}>
                <FormattedMessage id="menu.files" />
              </NavLink>
            </li>
            <li className="header__item">
              <NavLink className="header__item-link" activeClassName="header__item-link--active" to={routes.reports}>
                <FormattedMessage id="menu.reports" />
              </NavLink>
            </li>
            <li className="header__item">
              <NavLink className="header__item-link" activeClassName="header__item-link--active" to={routes.members}>
                <FormattedMessage id="menu.members" />
              </NavLink>
            </li>
            {
              <li className="header__item">
                {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'openProfile' does not exist on type 'Int... Remove this comment to see the full error message */}
                <MenuProfile openProfile={props.openProfile} />
              </li>
            }
          </ul>
        </li>
      </ul>
    );
  }) as any
);

export default Header;
