import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';
import { LIST_LIMIT } from '../constants/list';

export function getReportTypes() {
  return {
    [CALL_API]: {
      types: [types.REPORT_GET_TYPE_REQUEST, types.REPORT_GET_TYPE_SUCCESS, types.REPORT_GET_TYPE_FAILURE],
      endpoint: '/reportTypes',
      method: 'GET',
    },
  };
}

export function getReportsList() {
  return {
    [CALL_API]: {
      types: [types.GET_REPORTS_REQUEST, types.GET_REPORTS_SUCCESS, types.GET_REPORTS_FAILURE],
      endpoint: '/reports',
      method: 'POST',
    },
  };
}

export function getReportRootFolder(id: number) {
  return {
    [CALL_API]: {
      types: [types.GET_REPORT_ROOT_REQUEST, types.GET_REPORT_ROOT_SUCCESS, types.GET_REPORT_ROOT_FAILURE],
      endpoint: `/folder/root/${id}`,
      method: 'GET',
    },
  };
}

export function requestReportFolder(id: number) {
  return {
    [CALL_API]: {
      types: [types.GET_REPORT_FOLDER_REQUEST, types.GET_REPORT_FOLDER_SUCCESS, types.GET_REPORT_FOLDER_FAILURE],
      endpoint: `/folder/${id}`,
      method: 'GET',
    },
  };
}

export function clearList() {
  return {
    type: types.CLEAR_REPORT_LIST,
  };
}
export const requestList = (params, isMore = false, withFetching = false) => {
  const paramsStr = `?limit=${params.limit >= 0 ? params.limit : LIST_LIMIT}&offset=${params.offset || 0}`;
  const actionTypes = isMore
    ? [types.REPORT_MORE_REQUEST, types.REPORT_MORE_SUCCESS, types.REPORT_MORE_FAILURE]
    : [types.GET_REPORT_REQUEST, types.GET_REPORT_SUCCESS, types.GET_REPORT_FAILURE];

  const filters = {
    query: params.query || '',
    typesIds: params.typesIds || [],
    isCommentedByUser: params.isCommentedByUser || false,
  };

  return {
    [CALL_API]: {
      types: actionTypes,
      endpoint: `/report/search${paramsStr}`,
      body: {
        name: filters.query,
        typesIds: filters.typesIds,
        isCommentedByUser: filters.isCommentedByUser,
      },
    },
    filters,
    withFetching,
  };
};

function searchDocumentRequest(documentName) {
  return {
    [CALL_API]: {
      types: [types.DOCUMENT_SEARCH_REQUEST, types.DOCUMENT_SEARCH_SUCCESS, types.DOCUMENT_SEARCH_FAILURE],
      endpoint: '/documents/search',
      method: 'POST',
      body: {
        name: documentName,
      },
    },
  };
}

export function refreshList(limit, offset, params) {
  return dispatch => {
    return dispatch(requestList({ limit, offset, ...params }, false));
  };
}

export const getReports = () => {
  return dispatch => {
    return dispatch(getReportsList());
  };
};

export const getReportRoot = id => {
  return dispatch => {
    return dispatch(getReportRootFolder(id));
  };
};

export const getReportFolder = id => {
  return dispatch => {
    return dispatch(requestReportFolder(id));
  };
};

export function getList(limit, offset, params) {
  return dispatch => {
    dispatch(clearList());
    return dispatch(requestList({ limit, offset, ...params }, false));
  };
}

export function getMore(limit = LIST_LIMIT, offset, params) {
  return (dispatch, getState) => {
    const {
      files: { query },
    } = getState();
    return dispatch(
      requestList(
        {
          limit,
          offset,
          query,
          ...params,
        },
        true
      )
    );
  };
}

export function searchDocument(documentName) {
  return dispatch => {
    dispatch({ type: types.DOCUMENT_SEARCH_CLEAR });
    return dispatch(searchDocumentRequest(documentName));
  };
}
