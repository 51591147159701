import React, { Component } from 'react';
import cn from 'classnames';

import { getAppHeaders, getAuthHeaders, axiosInstance as axios } from '../../middleware/refresh';

import './styles.scss';

type Props = {
  url: string;
  classNames?: string;
};

class AsyncImage extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'img' does not exist on type 'AsyncImage'... Remove this comment to see the full error message
    this.img = React.createRef();
  }

  componentDidMount() {
    axios
      .get(this.props.url, {
        withCredentials: true,
        responseType: 'blob',
        headers: {
          ...getAuthHeaders(),
          ...getAppHeaders(),
        },
      })
      .then(response => {
        const image = document.createElement('img');
        image.src = URL.createObjectURL(response.data);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'img' does not exist on type 'AsyncImage'... Remove this comment to see the full error message
        if (this.img.current) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'img' does not exist on type 'AsyncImage'... Remove this comment to see the full error message
          this.img.current.appendChild(image);
        }
      });
  }

  render() {
    return (
      <div
        className={cn('async-image', this.props.classNames)}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'img' does not exist on type 'AsyncImage'... Remove this comment to see the full error message
        ref={this.img}
      />
    );
  }
}

export default AsyncImage;
