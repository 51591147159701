import { SET_STORAGE } from '../constants/ActionTypes';

const initialState = {};

export default function appReducer(state = initialState, action) {
  const { type, ...data } = action;
  switch (type) {
    case SET_STORAGE:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
}
