import * as types from '../constants/ActionTypes';
import { CALL_API, axiosInstance as axios } from '../middleware/refresh';
import Storage from '../utils/storage';

import { appConfig } from '../config';

export const setLoginSuccess = () => ({
  type: types.LOGIN_CHEAK,
});

export const loginPhone = phone => {
  return {
    [CALL_API]: {
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
      endpoint: '/user/login',
      body: {
        phone,
        token: appConfig.loginToken,
      },
    },
  };
};

export const login = phone => dispatch => {
  return dispatch(loginPhone(phone));
};

const confirmationPin = ({ phone, pin }) => {
  return {
    [CALL_API]: {
      types: [types.SMS_REQUEST, types.SMS_SUCCESS, types.SMS_FAILURE],
      endpoint: '/user/confirmPin',
      body: {
        phone,
        pin,
      },
    },
  };
};

const refreshTokenAction = () => {
  return {
    [CALL_API]: {
      types: [types.REFRESH_TOKEN_REQUEST, types.REFRESH_TOKEN_SUCCESS, types.REFRESH_TOKEN_FAILURE],
      endpoint: '/user/refreshToken',
      body: {
        refreshToken: Storage.get('refreshToken'),
      },
    },
  };
};

export const setSessionDate = ({ accessToken, refreshToken, expiration }) => {
  Storage.set('accessToken', accessToken);
  Storage.set('refreshToken', refreshToken);
  Storage.set('expiration', expiration);
};

const logoutAction = () => ({
  type: types.LOGOUT_SUCCESS,
});

const absentAction = () => ({
  type: types.ABSENT_SUCCESS,
});

export const refreshToken = () => (dispatch, getState) => {
  return dispatch(refreshTokenAction()).then(res => {
    const state = getState();
    if (!state.errorCode && res !== undefined) {
      return setSessionDate({
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
        expiration: res.expiration,
      });
    }
    return null;
  });
};

export const confirmPin = obj => (dispatch, getState) => {
  return dispatch(confirmationPin(obj)).then(res => {
    const state = getState();
    if (!state.errorCode && res !== undefined) {
      return setSessionDate({
        accessToken: res.data.accessToken,
        refreshToken: res.data.refreshToken,
        expiration: res.data.expiration,
      });
    }
    return null;
  });
};

const destroyTokens = async () => {
  await axios({
    method: 'get',
    withCredentials: true,
    url: `/user/logout`,
  });
  Storage.delete('accessToken');
  Storage.delete('refreshToken');
  Storage.delete('expiration');
  window.location.href = '/';
  return true;
};

export const absent = () => dispatch => {
  destroyTokens().then(() => dispatch(absentAction()));
};

export const logout = () => dispatch => {
  destroyTokens().then(() => dispatch(logoutAction()));
};
