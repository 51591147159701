import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { absent } from '../../actions/auth';
import { TIMER_TO_MODAL, TIMER_TO_LOGOUT } from '../../constants/timers';

import cn from '../../utils/cn';

import './styles.scss';

const TIKIR_TIME = 1000;

type State = any;
@cn('logged-out-modal-overlay')
class LoggedOutModalOverlay extends Component<{}, State> {
  state = {
    isModalOpen: false,
    counter: 0,
  };

  componentDidMount() {
    this.bindEventListeners();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'LoggedOut... Remove this comment to see the full error message
    this.timer = null;
    this.onUserAction();
  }

  componentWillUnmount() {
    this.resetTimer();
    this.removeEventListeners();
  }

  componentDidUpdate(prevProps: {}, prevState: State) {
    if ((this.state.isModalOpen && !prevState.isModalOpen) || (!this.state.isModalOpen && prevState.isModalOpen)) {
      this.setTimer(this.state.isModalOpen);
    }
  }

  setTimer = isModalOpen => {
    if (isModalOpen) {
      this.getInterval('logout');
    } else {
      this.getInterval('modal');
    }
  };

  getInterval = type => {
    this.setState(
      {
        counter: type === 'modal' ? TIMER_TO_MODAL : TIMER_TO_LOGOUT,
      },
      () => {
        if (type === 'modal') {
          this.modalTick();
        } else {
          this.tick();
        }
      }
    );
  };

  decrementCounter = () => {
    this.setState(state => {
      return {
        counter: state.counter - TIKIR_TIME,
      };
    });
  };

  modalTick = () => {
    this.resetTimer();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'LoggedOut... Remove this comment to see the full error message
    this.timer = setInterval(() => {
      if (this.state.counter - TIKIR_TIME > 0) {
        this.decrementCounter();
      } else {
        this.resetTimer();
        this.removeEventListeners();
        this.setState({ isModalOpen: true });
      }
    }, TIKIR_TIME);
  };

  tick = () => {
    this.resetTimer();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'LoggedOut... Remove this comment to see the full error message
    this.timer = setInterval(() => {
      if (this.state.counter - TIKIR_TIME > 0) {
        this.decrementCounter();
      } else {
        this.resetTimer();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onLogout' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onLogout();
      }
    }, TIKIR_TIME);
  };

  resetTimer = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'LoggedOut... Remove this comment to see the full error message
    clearInterval(this.timer);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'LoggedOut... Remove this comment to see the full error message
    this.timer = null;
  };

  onUserAction = () => {
    this.resetTimer();
    this.setTimer(this.state.isModalOpen);
  };

  continueWork = () => {
    this.bindEventListeners();
    this.resetTimer();
    this.setState({
      isModalOpen: false,
    });
  };

  bindEventListeners() {
    document.addEventListener('mousemove', this.onUserAction);
    document.addEventListener('mouseleave', this.onUserAction);
    document.addEventListener('keydown', this.onUserAction);
  }

  removeEventListeners() {
    document.removeEventListener('mousemove', this.onUserAction);
    document.removeEventListener('mouseleave', this.onUserAction);
    document.removeEventListener('keydown', this.onUserAction);
  }

  formatTime = ms => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const min = parseInt(ms / 60000, 10);
    let sec = ms - min * 60 * 1000;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    sec = parseInt(sec / 1000, 10);
    const str = `${min >= 10 ? min : `0${min}`}:${sec >= 10 ? sec : `0${sec}`}`;

    return <span>{str}</span>;
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    return (
      <div className={`${cn} ${this.state.isModalOpen ? 'logged-out-modal-overlay--open' : ''}`}>
        <div className={cn('content-container')}>
          <p className={cn('content')}>
            <FormattedMessage id="loggedOut.description" />
          </p>
          <time className={cn('time')}>{this.state.counter > 0 && this.formatTime(this.state.counter)}</time>
          <p className={cn('content')}>
            <FormattedMessage id="loggedOut.continueWork" />
          </p>
          <button onClick={this.continueWork} className={cn('button')}>
            <FormattedMessage id="loggedOut.continue" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  onLogout: absent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof LoggedOutModalOverlay' is not assigna... Remove this comment to see the full error message
)(injectIntl(LoggedOutModalOverlay));
