import React, { Component } from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import cn from '../../utils/cn';

import './styles.scss';
import '../../styles/global.scss';

type Props = {
  filters: any[];
  onChangeType: (...args: any[]) => any;
  intl: IntlShape;
};

@cn('files-filter')
class FilesTypeFilter extends Component<Props> {
  handleChangeType = filter => {
    this.props.onChangeType(filter);
  };

  renderFilters = (cn, filters) => (
    <ul className={cn('list')}>
      {filters.map(filter => (
        <li key={filter.id} className={`${cn('item')} ${filter.active ? cn('item', { active: true }) : ''}`}>
          <button onClick={() => this.handleChangeType(filter)} className={cn('button')}>
            {this.props.intl.formatMessage({ id: filter.id })}
          </button>
        </li>
      ))}
    </ul>
  );

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { filters } = this.props;

    return <div className={cn()}>{filters && this.renderFilters(cn, filters)}</div>;
  }
}

// @ts-expect-error ts-migrate(2769) FIXME: Type 'typeof FilesTypeFilter' is not assignable to... Remove this comment to see the full error message
export default injectIntl(FilesTypeFilter);
