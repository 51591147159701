import React, { Component } from 'react';

import FilesTypeFilter from '../../components/FilesTypeFilter';
import FilesCheckboxes from '../../components/FilesCheckboxes';

import { LIST_LIMIT } from '../../constants/list';

import './styles.scss';

const filesFilter = [
  {
    id: 'files.filters.all',
    params: null,
    active: true,
  },
  {
    id: 'files.filters.myComments',
    params: {
      isCommentedByUser: true,
    },
  },
];

type Props = {
  listTypes?: any[];
  list?: any[];
  query?: string;
  locale?: string;
  offset?: number;
  isFetching?: boolean;
  total?: number;
  getFiles: (...args: any[]) => any;
  getFilesMore: (...args: any[]) => any;
};

type State = any;

class FilesFilters extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      types: filesFilter,
      query: '',
      listTypes: this.props.listTypes,
      typesIds: this.getChackedIds(this.props.listTypes),
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleDocumentScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleDocumentScroll);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.listTypes !== nextProps.listTypes) {
      return {
        listTypes: nextProps.listTypes,
      };
    }
    if (prevState.query !== nextProps.query) {
      return {
        query: nextProps.query,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.query !== this.state.query) {
      this.handleSendChangesByFilters();
    }
  }

  handleDocumentScroll = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const limitOffset = this.props.offset + LIST_LIMIT;
    const d = document.documentElement;
    const offset = d.scrollTop + window.innerHeight;
    const height = d.offsetHeight;

    if (offset === height) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (limitOffset < this.props.total && !this.props.isFetching) {
        const paramsByActiveType = this.state.types.filter(item => item.active)[0].params;
        this.props.getFilesMore(LIST_LIMIT, limitOffset, {
          typesIds: this.state.typesIds,
          ...paramsByActiveType,
          query: this.state.query,
        });
      }
    }
  };

  getChackedIds = list => {
    const chacked = list.filter(item => item.chacked);
    return chacked.map(item => item.id);
  };

  handleChangeCheckboxes = list => {
    this.setState(
      {
        typesIds: this.getChackedIds(list),
      },
      () => {
        this.handleSendChangesByFilters();
      }
    );
  };

  handleChangeType = type => {
    this.setState(
      state => {
        return {
          types: state.types.map(item => {
            if (type.id === item.id) {
              return {
                ...item,
                active: true,
              };
            }
            return {
              ...item,
              active: false,
            };
          }),
        };
      },
      () => {
        this.handleSendChangesByFilters();
      }
    );
  };

  handleSendChangesByFilters = () => {
    const typesIds = this.state.typesIds;
    const paramsByActiveType = this.state.types.filter(item => item.active)[0].params;
    this.props.getFiles(LIST_LIMIT, 0, {
      typesIds,
      ...paramsByActiveType,
      query: this.state.query,
    });
  };

  handleOnMount = list => {
    this.handleChangeCheckboxes(list);
  };

  render() {
    return (
      <div className="files-filters">
        <FilesTypeFilter onChangeType={this.handleChangeType} filters={this.state.types} />
        {this.state.listTypes.length > 0 && (
          // @ts-expect-error ts-migrate(2786) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
          <FilesCheckboxes
            onMount={this.handleOnMount}
            onChange={this.handleChangeCheckboxes}
            checkboxFields={this.state.listTypes}
            locale={this.props.locale}
          />
        )}
      </div>
    );
  }
}

export default FilesFilters;
