import React from 'react';
// eslint-disable-next-line
import { injectIntl, IntlShape } from 'react-intl';

import DatePeriod from '../DatePeriod';
import NoResults from '../NoResults';
import Loading from '../Loading';
import Documents from './Documents';

import * as locales from '../../constants/locales';
import { CANCELED } from '../../constants/meetingStatuses';

import truncate from '../../utils/truncate';

import './styles.scss';

type Props = {
  list?: any[];
  locale?: string;
  className?: string;
  isFetching?: boolean;
  intl: IntlShape;
  isMeetings?: boolean;
  isShowCanseledMeetings?: boolean;
  onDownloadFile: (...args: any[]) => any;
  parentType?: string;
};

class DocumentsList extends React.Component<Props> {
  renderFilesList = (files, key) => {
    const { intl } = this.props;
    const originalsText = intl.formatMessage({ id: 'files.originals' });

    return (
      <Documents
        key={key}
        documents={files}
        locale={this.props.locale}
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'parentType' does not exist on type 'Intr... Remove this comment to see the full error message
        parentType={this.props.parentType}
        onDownloadFile={this.props.onDownloadFile}
        originalsText={originalsText}
      />
    );
  };

  renderMeetingTitle = meeting => {
    const { locale, isMeetings, intl } = this.props;
    const meetingName = locale === locales.RU ? meeting.russianName : meeting.englishName;

    const isNew = typeof meeting.updatedAt === 'undefined';
    const updatedText = `${intl.formatMessage({
      id: isNew ? 'files.created' : 'files.updated',
    })} `;

    const updatedAt = Number.isNaN(meeting.updatedAt) ? meeting.updatedAt : parseInt(meeting.updatedAt, 10);
    return (
      <React.Fragment key={`h_${meeting.id}`}>
        <h1 className="files__title">{truncate(meetingName, 30)}</h1>
        {isMeetings ? (
          <DatePeriod dateTimeStart={meeting.dateTimeStart} dateTimeEnd={meeting.dateTimeEnd} locale={locale} />
        ) : (
          <>
            <span className="files__updated">{updatedText}</span>
            <DatePeriod dateTimeStart={updatedAt || meeting.date} isSingleDate locale={locale} />
          </>
        )}
      </React.Fragment>
    );
  };

  renderFilesByMeetings = () => {
    const { list, isFetching, isShowCanseledMeetings, isMeetings } = this.props;
    let listToMap = list;
    if (isMeetings) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      listToMap = listToMap.filter(item => (!isShowCanseledMeetings ? item.status !== CANCELED : true));
    }

    let isNoResults = true;

    const results =
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      listToMap.length && // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      listToMap.map(meeting => {
        const documents = meeting.documents;

        let filesEng = documents.minutes || [];
        filesEng = filesEng.concat(documents.eng ? documents.eng : []);

        let filesRus = documents.minutes || [];
        filesRus = filesRus.concat(documents.rus ? documents.rus : []);

        const files = filesEng.concat(filesRus);

        let isShow = false;
        if (files.length > 0) {
          isShow = true;
          isNoResults = false;
        }

        return (
          isShow && (
            <React.Fragment key={`fragment_${meeting.id}`}>
              {this.renderMeetingTitle(meeting)}
              {this.renderFilesList(documents, meeting.id)}
            </React.Fragment>
          )
        );
      });
    return (
      <>
        {isFetching ? <Loading /> : null}
        {isNoResults ? (
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'titleId' does not exist on type 'Intrins... Remove this comment to see the full error message
          <NoResults titleId="app.results" />
        ) : (
          results
        )}
      </>
    );
  };

  render() {
    return <>{this.renderFilesByMeetings()}</>;
  }
}

export default injectIntl(DocumentsList);
