import React, { Component } from 'react';
import { connect } from 'react-redux';

import Scrollbars from 'react-custom-scrollbars';
import { MembersFilter } from '../../../components/UsersTable';

import MembersList from '../../../components/MembersList';

import { getMembersByGroup, searchMembers, getGroupsByType, getMember } from '../../../actions/members';
import { filterSet } from '../../../actions/filters';

import { searchBy as searchSection } from '../../../constants/search';

import cn from '../../../utils/cn';

import './index.scss';
import '../../../styles/global.scss';

type MembersProps = {
  match: any;
  locale?: string;
  members?: any;
  getMembersByGroup: (...args: any[]) => any;
  getMember: (...args: any[]) => any;
  filterSet: (...args: any[]) => any;
  query?: string;
  searchMembers: (...args: any[]) => any;
  getGroupsByType: (...args: any[]) => any;
};

type MembersState = any;

@cn('members')
class Members extends Component<MembersProps, MembersState> {
  state = {
    activeGroups: [],
    query: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.query !== nextProps.query) {
      return {
        query: nextProps.query,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: MembersProps, prevState: MembersState) {
    const { activeGroups } = this.state;
    if (activeGroups !== prevState.activeGroups) {
      activeGroups.forEach(group => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        this.props.getMembersByGroup(group.id);
      });
    }
    if (prevState.query !== this.state.query) {
      if (this.state.query !== '') {
        this.handleSearch();
      }
    }
  }

  handleSelectGroups = groups => {
    this.setState({
      activeGroups: groups,
    });
    this.props.filterSet({ query: '', searchBy: searchSection.MEMBERS });
  };

  handleGetGroupsByType = groups => {
    return this.props.getGroupsByType(groups).then(resp => resp.data);
  };

  handleSearch = () => {
    this.props.searchMembers({ name: this.state.query });
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { groupType, groupId } = this.props.match.params;
    return (
      <main className={`container container--with-padding-top ${cn()}`}>
        <div className={cn('members-filters')}>
          <Scrollbars autoHide>
            <MembersFilter
              groupType={groupType}
              groupId={groupId}
              // locale={this.props.locale}
              onSelectGroups={this.handleSelectGroups}
              getGroupsByType={this.handleGetGroupsByType}
            />
          </Scrollbars>
        </div>

        <MembersList
          showGroups={this.state.activeGroups}
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'locale' does not exist on type 'Intrinsi... Remove this comment to see the full error message
          locale={this.props.locale}
          query={this.state.query}
          byQuery={this.state.query !== ''}
          isFetching={this.props.members.isFetching}
          members={this.props.members}
          className={cn('members-list')}
          getMember={this.props.getMember}
        />
      </main>
    );
  }
}

const mapStateToProps = ({ i18n, members, filters }) => ({
  locale: i18n.locale,
  members,
  query: filters.query,
});

const mapDispatchToProps = {
  getMembersByGroup,
  getMember,
  filterSet,
  getGroupsByType,
  searchMembers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
)(Members);
