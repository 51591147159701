import React from 'react';

const MaskIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="32" viewBox="0 0 48 32" {...props}>
    <path
      fillRule="evenodd"
      d="M28.571 25.155L19.2 12.16 7.2 28.8h34.08L33.6 18.24l-5.029 6.915zM28.8 19.2l4.8-6.4L48 32H0L19.2 6.4l9.6 12.8zm12.8-6.4a6.4 6.4 0 1 1 0-12.8 6.4 6.4 0 0 1 0 12.8zm0-3.2a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4z"
    />
  </svg>
);

export default MaskIcon;
