import React from 'react';

import cn from '../../utils/cn';
import Mouse from '../../utils/mouse';

import './styles.scss';

type Props = {
  width: number;
  height: number;
  draw?: any;
  color?: string;
  weight?: number;
  isEraserEnabled?: boolean;
  active?: boolean;
  saveDraw: (...args: any[]) => any;
  pageNumber?: number;
};

type State = any;

@cn('document-drowing-layer')
class DocumentDrowingLayer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      color: this.props.color,
      weight: this.props.weight,
      currentDraw: this.props.draw || {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.color !== nextProps.color) {
      return {
        color: nextProps.color,
      };
    }
    if (prevState.weight !== nextProps.weight) {
      return {
        weight: nextProps.weight,
      };
    }
    return null;
  }

  draw = {
    canvas: null,
    ctx: null,
    prevX: 0,
    currX: 0,
    prevY: 0,
    currY: 0,
    flag: false,
    dot_flag: false,
  };

  documentDrowingLayerRef = React.createRef();

  documentDrowingLayerRefOverlay = React.createRef();

  componentDidMount() {
    this.loadCanvas();
    if (this.state.currentDraw && this.state.currentDraw.content) {
      this.loadDraw(this.state.currentDraw, this.documentDrowingLayerRef.current);
    }
    this.loadListeners();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.isEraserEnabled !== prevProps.isEraserEnabled) {
      this.enableEraseMode(this.props.isEraserEnabled);
    }
    if (this.props.width !== prevProps.width) {
      if (!this.props.active) {
        if (this.state.currentDraw && this.state.currentDraw.content) {
          this.loadDraw(this.state.currentDraw, this.documentDrowingLayerRef.current);
        }
      } else {
        const canvas = !this.props.isEraserEnabled
          ? this.documentDrowingLayerRef.current
          : this.documentDrowingLayerRefOverlay.current;

        this.state.currentDraw && this.state.currentDraw.content && this.loadDraw(this.state.currentDraw, canvas);
      }
    }
    if (this.props.active !== prevProps.active && !this.props.active) {
      const draw = {
        pageNumber: this.props.draw ? this.props.draw.pageNumber : null,
        content: this.props.draw ? this.props.draw.content : null,
      };
      if (prevState.currentDraw.content && draw.content !== prevState.currentDraw.content) {
        this.props.saveDraw(prevState.currentDraw);
      }
    }
  }

  enableEraseMode = isEraserEnabled => {
    if (isEraserEnabled) {
      this.saveDrawToLayer(this.documentDrowingLayerRef, this.documentDrowingLayerRefOverlay);
    } else {
      this.saveDrawToLayer(this.documentDrowingLayerRefOverlay, this.documentDrowingLayerRef);
    }
  };

  loadCanvas = () => {
    const canvas = this.documentDrowingLayerRefOverlay.current;
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const ctx = canvas.getContext('2d');
    this.draw = {
      ...this.draw,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'null'.
      canvas,
      ctx,
    };
  };

  loadDraw = (draw, canvas) => {
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = `data:image/png;base64,${draw.content}`;
    image.onload = function () {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  };

  loadListeners = () => {
    const canvas = this.draw.canvas;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.addEventListener('mousemove', this.move, false);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.addEventListener('mousedown', this.down, false);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.addEventListener('mouseup', this.up, false);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.addEventListener('mouseout', this.out, false);
  };

  move = e => {
    this.findxy('move', e);
  };

  down = e => {
    this.findxy('down', e);
  };

  up = e => {
    this.findxy('up', e);
  };

  out = e => {
    this.findxy('out', e);
  };

  saveDrawToLayer = (from, to) => {
    const canvasFrom = from.current;
    const canvasFromCtx = canvasFrom.getContext('2d');

    const canvasTo = to.current;
    const canvasToCtx = canvasTo.getContext('2d');
    canvasToCtx.globalCompositeOperation = 'source-over';
    const toSaveData = canvasFrom;
    canvasToCtx.drawImage(toSaveData, 0, 0);
    canvasFromCtx.clearRect(0, 0, this.props.width, this.props.height);
  };

  saveTmpDraw = canvas => {
    const content = canvas.current.toDataURL().replace('data:image/png;base64,', '');
    const currentDraw = {
      pageNumber: this.props.pageNumber,
      content,
    };
    this.setState(
      {
        currentDraw,
      },
      () => this.props.saveDraw(this.state.currentDraw)
    );
  };

  findxy = (res, e) => {
    if (!this.props.active) return;
    const ctx = this.draw.ctx;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    ctx.lineJoin = 'round';
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    ctx.lineCap = 'round';
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    ctx.lineWidth = this.state.weight;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    ctx.strokeStyle = this.state.color;
    const position = Mouse.getClickPosition(e);
    if (res === 'down') {
      this.draw.prevX = this.draw.currX;
      this.draw.prevY = this.draw.currY;
      this.draw.currX = position.x;
      this.draw.currY = position.y;

      this.draw.flag = true;
      this.draw.dot_flag = true;
      if (this.draw.dot_flag) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.beginPath();
        this.draw.dot_flag = false;
      }
    }
    if (res === 'up' || res === 'out') {
      if (this.draw.flag) {
        if (!this.props.isEraserEnabled) {
          this.saveDrawToLayer(this.documentDrowingLayerRefOverlay, this.documentDrowingLayerRef);
          this.saveTmpDraw(this.documentDrowingLayerRef);
        } else {
          this.saveTmpDraw(this.documentDrowingLayerRefOverlay);
        }
      }
      this.draw.flag = false;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      ctx.closePath();
    }
    if (res === 'move') {
      if (this.draw.flag) {
        this.draw.prevX = this.draw.currX;
        this.draw.prevY = this.draw.currY;
        this.draw.currX = position.x;
        this.draw.currY = position.y;
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.lineJoin = 'round';
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.lineWidth = this.state.weight;
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.strokeStyle = this.state.color;
        this.drawing();
      }
    }
  };

  drawing = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.draw.ctx.globalCompositeOperation = this.props.isEraserEnabled ? 'destination-out' : 'source-over';
    if (!this.props.isEraserEnabled) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.draw.ctx.clearRect(0, 0, this.props.width, this.props.height);
    }
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.draw.ctx.lineTo(this.draw.currX, this.draw.currY);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.draw.ctx.stroke();
  };

  componentWillUnmount() {
    const canvas = this.draw.canvas;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.removeEventListener('mousemove', this.move);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.removeEventListener('mousedown', this.down);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.removeEventListener('mouseup', this.up);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    canvas.removeEventListener('mouseout', this.out);
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type '(cn: any) => Element' is not assignable to t... Remove this comment to see the full error message
  render(cn) {
    const { width, height } = this.props;
    return (
      <div id="canvas-container">
        <canvas
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLCanv... Remove this comment to see the full error message
          ref={this.documentDrowingLayerRef}
          className={cn({ hidden: this.props.isEraserEnabled })}
          width={width}
          height={height}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            zIndex: this.props.active ? 1 : 0,
          }}
        />
        <canvas
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
          ref={this.documentDrowingLayerRefOverlay}
          className={cn()}
          width={width}
          height={height}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            zIndex: this.props.active ? 2 : 0,
          }}
        />
      </div>
    );
  }
}

export default DocumentDrowingLayer;
