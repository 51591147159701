import { CALL_API } from '../middleware/refresh';
import {
  DOCUMENT_GET_FAILURE,
  DOCUMENT_GET_REQUEST,
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_EXIT,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAILURE,
  COMMENT_DELETE,
  COMMENT_EDIT_REQUEST,
  COMMENT_EDIT_SUCCESS,
  COMMENT_EDIT_FAILURE,
  COMMENT_ADD_REQUEST,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAILURE,
  DRAW_ADD_REQUEST,
  DRAW_ADD_SUCCESS,
  DRAW_ADD_FAILURE,
} from '../constants/ActionTypes';

export function getDocument(id) {
  return {
    [CALL_API]: {
      types: [DOCUMENT_GET_REQUEST, DOCUMENT_GET_SUCCESS, DOCUMENT_GET_FAILURE],
      endpoint: `/document/${id}`,
      method: 'GET',
    },
  };
}

export function exitDocument() {
  return {
    type: DOCUMENT_EXIT,
  };
}

function deleteCommentFromDoc(id) {
  return {
    [CALL_API]: {
      types: [COMMENT_DELETE_REQUEST, COMMENT_DELETE_SUCCESS, COMMENT_DELETE_FAILURE],
      endpoint: `/comment/${id}`,
      method: 'DELETE',
    },
  };
}

function editCommentRequest(data) {
  return {
    [CALL_API]: {
      types: [COMMENT_EDIT_REQUEST, COMMENT_EDIT_SUCCESS, COMMENT_EDIT_FAILURE],
      endpoint: `/comment/${data.id}`,
      method: 'PATCH',
      body: {
        isShared: data.isShared,
      },
    },
  };
}

function addCommentRequest(documentId, data) {
  return {
    [CALL_API]: {
      types: [COMMENT_ADD_REQUEST, COMMENT_ADD_SUCCESS, COMMENT_ADD_FAILURE],
      endpoint: `/document/${documentId}/comment`,
      method: 'POST',
      body: {
        ...data,
      },
    },
  };
}

function addDrawRequest(documentId, draw) {
  return {
    [CALL_API]: {
      types: [DRAW_ADD_REQUEST, DRAW_ADD_SUCCESS, DRAW_ADD_FAILURE],
      endpoint: `/document/${documentId}/draw`,
      method: 'POST',
      body: {
        ...draw,
      },
    },
  };
}

export const deleteComment = id => dispatch => {
  return dispatch(deleteCommentFromDoc(id)).then(data => {
    if (data && data.data) {
      dispatch({
        type: COMMENT_DELETE,
        id,
      });
    }
  });
};

export const editComment = data => dispatch => {
  return dispatch(editCommentRequest(data));
};

export const addComment = (documentId, data) => dispatch => {
  return dispatch(addCommentRequest(documentId, data));
};

export const addDraw = draw => (dispatch, getState) => {
  const {
    document: { id },
  } = getState();
  return dispatch(addDrawRequest(id, draw));
};
