import React from 'react';
import PropTypes from 'prop-types';

import * as locales from '../../constants/locales';

function withSortedFiles(WrappedComponent) {
  return class HOC extends React.PureComponent {
    static propTypes = {
      documents: PropTypes.object,
      locale: PropTypes.string,
    };

    constructor(props) {
      super(props);
      this.state = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'documents' does not exist on type 'Reado... Remove this comment to see the full error message
        documents: this.props.documents,
        local: [],
        common: [],
      };
    }

    componentDidMount() {
      this.prepareFiles();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.documents !== prevState.documents) {
        return {
          documents: nextProps.documents,
        };
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'documents' does not exist on type 'Reado... Remove this comment to see the full error message
      if (this.state.documents !== prevState.documents) {
        this.prepareFiles();
      }
    }

    prepareFiles = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'documents' does not exist on type 'Reado... Remove this comment to see the full error message
      const { documents, locale } = this.props;

      const engMinute = documents.minutes || [];
      const eng = documents.eng ? documents.eng : [];

      const rusMinute = documents.minutes || [];
      const rus = documents.rus ? documents.rus : [];

      let minutes = locale === locales.RU ? rusMinute : engMinute;
      minutes = this.groupedBySourceFileIdSorted(minutes);

      const { commonFiles: commonFiles1, myFiles: myFiles1 } = this.pushToArrays(minutes);

      let files = eng.concat(rus);
      files = this.groupedBySourceFileIdSorted(files);

      const { commonFiles: commonFiles2, myFiles: myFiles2 } = this.pushToArrays(files);

      const commonFiles = commonFiles1.concat(commonFiles2);
      const myFiles = myFiles1.concat(myFiles2);

      this.setState({
        local: myFiles,
        common: commonFiles,
      });
    };

    groupedBySourceFileIdSorted = array => {
      const res = [];
      array.forEach(item => {
        const id = item.sourceFileId === null ? item.id : item.sourceFileId;
        if (!res[id]) {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'never[]' is not assignable to type 'never'.
          res[id] = [];
        }
        if (res[id]) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'push' does not exist on type 'never'.
          res[id].push(item);
        }
      });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'version' does not exist on type 'never'.
      return res.sort((a, b) => a.version - b.version);
    };

    pushToArrays = sourse => {
      const commonFiles = [];
      const myFiles = [];
      sourse.forEach(array => {
        array.forEach((item, index) => {
          if (index < array.length - 1) {
            if (item.isDownloaded) {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
              myFiles.push(item);
            } else {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
              commonFiles.push(item);
            }
          } else {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            commonFiles.push(item);
          }
        });
      });
      return {
        myFiles,
        commonFiles,
      };
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'common' does not exist on type 'Readonly... Remove this comment to see the full error message
          commonFiles={this.state.common}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'local' does not exist on type 'Readonly<... Remove this comment to see the full error message
          localFiles={this.state.local}
        />
      );
    }
  };
}

export default withSortedFiles;
