import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import './styles/scroll-block.scss';

const withScrollList = injectProps => WrappedComponent => {
  return class HOC extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'direction' does not exist on type 'Reado... Remove this comment to see the full error message
        direction: this.props.direction || 'down',
        isFetching: false,
        offsetTop: 20,
        passedTitleElem: null,
        upComingTitleElem: null,
        todayTitleElem: null,
        searchTitleElem: null,
      };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollbar' does not exist on type 'HOC'.
      this.scrollbar = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.isFetching !== nextProps.isFetching) {
        return {
          isFetching: nextProps.isFetching,
        };
      }
      if (nextProps.direction && prevState.direction !== nextProps.direction) {
        return {
          direction: nextProps.direction,
        };
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState) {
      if (!prevState.isFetching) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollbar' does not exist on type 'HOC'.
        const scrollbar = this.scrollbar.current;
        if (scrollbar.getScrollTop() === 0) {
          // вверху всегда должен оставаться не нулевой scrollTop, чтобы можно было вызвать событие onScrollFrame.
          scrollbar.scrollTop(1);
        }
        if (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meeting' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.props.meeting?.id !== prevProps.meeting?.id ||
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetTop' does not exist on type 'Reado... Remove this comment to see the full error message
          prevState.offsetTop !== this.state.offsetTop
        ) {
          const offsetTop =
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetTop' does not exist on type 'Reado... Remove this comment to see the full error message
            this.state.offsetTop > 1 ? this.state.offsetTop : 1;
          // const scrollBarScrollTop = this.scrollBarRect().scrollTop;
          // const scrollBarHeight = this.scrollBarRect().clientHeight;
          // console.log(offsetTop + 166, scrollBarScrollTop, scrollBarHeight);
          // if (offsetTop < scrollBarScrollTop || offsetTop + 166 > scrollBarScrollTop + scrollBarHeight) {
          scrollbar.view.scrollTop = offsetTop;
          // }
        }
      }
    }

    getTimeTitle = element => {
      if (element?.id === 'meetings.groups.passed') {
        this.setState({
          passedTitleElem: element,
        });
      }

      if (element?.id === 'meetings.groups.upcoming') {
        this.setState({
          upComingTitleElem: element,
        });
      }

      if (element?.id === 'meetings.groups.today') {
        this.setState({
          todayTitleElem: element,
        });
      }
    };

    scrollBarRect = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollbar' does not exist on type 'HOC'.
      const scrollbar = this.scrollbar.current;
      const scrollBarInfo = scrollbar ? scrollbar.getValues() : null;
      return scrollBarInfo;
    };

    getOffTop = (element, activeId) => {
      const activeIdAsString = activeId?.toString();
      if (element?.id === activeIdAsString) {
        const offsetTop = element.offsetTop - 100 > 0 ? element.offsetTop - 100 : element.offsetTop; // чтобы была посередине
        this.setState({
          offsetTop,
        });
      }
    };

    moveDown = direction => {
      let { limit } = injectProps;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      let offset = this.props.offset;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'direction' does not exist on type 'Reado... Remove this comment to see the full error message
      if (this.state.direction !== direction) {
        // change direction
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        offset += this.props.meetings.length;
      } else {
        offset += limit;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type 'Readonly<... Remove this comment to see the full error message
      if (offset - this.props.total >= 0) {
        return;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type 'Readonly<... Remove this comment to see the full error message
      if (offset + limit >= this.props.total) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type 'Readonly<... Remove this comment to see the full error message
        limit = this.props.total - offset;
      }

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.props.offset !== offset) {
        this.setState({ direction });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFetching' does not exist on type 'Read... Remove this comment to see the full error message
        if (!this.props.isFetching) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetingsMore' does not exist on type... Remove this comment to see the full error message
          this.props.loadMeetingsMore(limit, offset);
        }
      }
    };

    moveUp = direction => {
      let { limit } = injectProps;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      let hiddenTop = this.props.offset;

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'direction' does not exist on type 'Reado... Remove this comment to see the full error message
      if (this.state.direction !== direction) {
        // change direction
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
        hiddenTop = this.props.offset - this.props.itemsLengthBeforeUbpate;
      }
      if (hiddenTop <= 0) {
        return;
      }
      const offset = hiddenTop - limit > 0 ? hiddenTop - limit : 0;

      limit = hiddenTop > 0 && hiddenTop < limit ? hiddenTop : limit;

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.props.offset !== offset) {
        this.setState({ direction });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFetching' does not exist on type 'Read... Remove this comment to see the full error message
        if (!this.props.isFetching && this.props.loadMeetingsMoreUp) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadMeetingsMoreUp' does not exist on ty... Remove this comment to see the full error message
          this.props.loadMeetingsMoreUp(limit, offset);
        }
      }
    };

    handleScrollFrame = values => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleOnScroll' does not exist on type '... Remove this comment to see the full error message
      this.props.handleOnScroll(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollbar' does not exist on type 'HOC'.
        this.scrollbar.current.view,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'upComingTitleElem' does not exist on typ... Remove this comment to see the full error message
        this.state.upComingTitleElem,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'todayTitleElem' does not exist on type '... Remove this comment to see the full error message
        this.state.todayTitleElem,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'passedTitleElem' does not exist on type ... Remove this comment to see the full error message
        this.state.passedTitleElem,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'grouped' does not exist on type 'Readonl... Remove this comment to see the full error message
        this.props.grouped
      );
      if (values.top >= 1) {
        this.moveDown('down');
      }
      if (values.top === 0) {
        this.moveUp('up');
      }
    };

    render() {
      return (
        <Scrollbars
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollbar' does not exist on type 'HOC'.
          ref={this.scrollbar}
          onScrollFrame={this.handleScrollFrame}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          <WrappedComponent getTimeTitle={this.getTimeTitle} getOffTop={this.getOffTop} {...this.props} />
        </Scrollbars>
      );
    }
  };
};

export default withScrollList;
