import React from 'react';
import { connect } from 'react-redux';

import { loadMeetings, loadMeetingsMore, loadMeetingsMoreUp, selectMeeting } from '../../actions/meetings';
import { profile } from '../../actions/user';
import ProfileLoading from '../../components/ProfileLoading';
import withMeetings from '../HOC/withMeetings';

type UserLoadingProps = {
  onLoad?: (...args: any[]) => any;
  isLoaded?: boolean;
  meetings?: any[];
  offset?: number;
  totalMeetings?: number;
  user?: any;
  loadProfile: (...args: any[]) => any;
  loadMeetings: (...args: any[]) => any;
  loadMeetingsMore: (...args: any[]) => any;
  loadMeetingsMoreUp: (...args: any[]) => any;
  filters?: any;
  match?: any;
  locale?: string;
};

type UserLoadingState = any;

class UserLoading extends React.Component<UserLoadingProps, UserLoadingState> {
  constructor(props: UserLoadingProps) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoaded && nextProps.isLoaded !== prevState.isLoaded) {
      return { isLoaded: nextProps.isLoaded };
    }

    return null;
  }

  componentDidMount() {
    return this.props.loadProfile();
  }

  componentDidUpdate() {
    if (this.state.isLoaded && this.props.onLoad) {
      this.props.onLoad();
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'IntlSh... Remove this comment to see the full error message
    return <ProfileLoading user={this.props.user} locale={this.props.locale} />;
  }
}

const mapStateToProps = ({ user, scrollableList, filters, i18n }) => ({
  user,
  totalMeetings: scrollableList.total,
  meetings: scrollableList.items,
  offset: scrollableList.offset,
  filters,
  locale: i18n.locale,
});

const mapDispatchToProps = {
  loadProfile: profile,
  loadMeetings,
  loadMeetingsMore,
  loadMeetingsMoreUp,
  selectMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withMeetings(UserLoading, { noLoadingMessage: true }));
