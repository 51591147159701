// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import { DocumentHeader, DocumentDrawingSettings, DocumentBody } from '../../../components/UsersTable';

import * as DocumentActions from '../../../actions/document';
import { getAppHeaders } from '../../../middleware/refresh';

import { COLORS, RANGE } from '../../../constants/draw';

import { File } from '../../../utils/file';
import cn from '../../../utils/cn';
import { appConfig } from '../../../config';

import './styles.scss';

type DocumentProps = {
  match: any;
  history: any;
  document?: any;
  getDocument: (...args: any[]) => any;
  exitDocument: (...args: any[]) => any;
  addComment: (...args: any[]) => any;
  editComment: (...args: any[]) => any;
  deleteComment: (...args: any[]) => any;
  addDraw: (...args: any[]) => any;
  file?: any;
};

type DocumentState = any;

@cn('document')
class Document extends Component<DocumentProps, DocumentState> {
  state = {
    isDrawingEnabled: false,
    drawingColor: COLORS.blue,
    drawingWeight: RANGE.max,
    isDrawingEraserEnabled: false,
    isPreviewEnabled: false,
    isCommentsEnabled: false,
    isCommentsAddingEnabled: false,
    rawPdfDocument: undefined,
  };

  getFileRequest = memoize(url => {
    return {
      url: `${appConfig.apiUrl}${url}`,
      withCredentials: true,
      httpHeaders: {
        // ...getAuthHeaders(),
        ...getAppHeaders(),
      },
    };
  });

  toggleDrawing = () => {
    const { isDrawingEnabled } = this.state;

    this.setState({
      isDrawingEnabled: !isDrawingEnabled,
      isDrawingEraserEnabled: false,
      isCommentsAddingEnabled: false,
    });
  };

  togglePreview = () => {
    const { isPreviewEnabled } = this.state;

    this.setState({
      isPreviewEnabled: !isPreviewEnabled,
    });
  };

  toggleComments = () => {
    const { isCommentsEnabled } = this.state;

    this.setState({
      isCommentsEnabled: !isCommentsEnabled,
    });
  };

  toggleCommentsAdding = () => {
    const { isCommentsAddingEnabled } = this.state;

    this.setState({
      isCommentsAddingEnabled: !isCommentsAddingEnabled,
      isDrawingEnabled: false,
      isDrawingEraserEnabled: false,
    });
  };

  printPdfDocument = () => {
    const { rawPdfDocument } = this.state;
    if (rawPdfDocument) {
      return File.printRawPdf(rawPdfDocument);
    }
    return Promise.reject('No pdfDocument was provided');
  };

  downloadPdfDocument = () => {
    const { document } = this.props;
    return File.download(document.url, document.name);
  };

  onPdfDocumentLoaded = rawPdfDocument => {
    this.setState({ rawPdfDocument });
  };

  onChangeDrawingColor = drawingColor => {
    this.setState({ drawingColor });
  };

  onChangeDrawingWeight = weight => {
    const drawingWeight = parseInt(weight, 10);
    this.setState({ drawingWeight });
  };

  onChangeEraserEnabled = isErase => {
    this.setState({ isDrawingEraserEnabled: isErase });
  };

  onBack = () => {
    const { history, match } = this.props;
    if (history.location && !history.location.key) {
      if (match.params.parentType && match.params.parentId) {
        window.location.href = `/${match.params.parentType}s/${match.params.parentId}`;
        return;
      }
      if (match.params.parentType) {
        window.location.href = `/${match.params.parentType}s`;
        return;
      }
      window.location.href = `/meetings`;
      return;
    }
    history.goBack();
  };

  render(cn) {
    const { match, document, getDocument, exitDocument, addComment, editComment, deleteComment, addDraw } = this.props;
    const {
      isDrawingEnabled,
      drawingWeight,
      drawingColor,
      isDrawingEraserEnabled,
      isPreviewEnabled,
      isCommentsEnabled,
      isCommentsAddingEnabled,
    } = this.state;

    return (
      <div className="container-document">
        <main className={`${cn()} container__document`}>
          <DocumentHeader
            document={document}
            isDrawingEnabled={isDrawingEnabled}
            isPreviewEnabled={isPreviewEnabled}
            isCommentsEnabled={isCommentsEnabled}
            isCommentsAddingEnabled={isCommentsAddingEnabled}
            toggleDrawing={this.toggleDrawing}
            togglePreview={this.togglePreview}
            toggleComments={this.toggleComments}
            toggleCommentsAdding={this.toggleCommentsAdding}
            onBack={this.onBack}
            printPdfDocument={this.printPdfDocument}
            downloadPdfDocument={this.downloadPdfDocument}
          />
          {isDrawingEnabled && (
            <DocumentDrawingSettings
              onChangeColor={this.onChangeDrawingColor}
              onChangeWeight={this.onChangeDrawingWeight}
              onChangeEraserEnabled={this.onChangeEraserEnabled}
            />
          )}
          <DocumentBody
            documentId={match.params.documentId}
            document={document}
            getDocument={getDocument}
            getFileRequest={this.getFileRequest}
            exitDocument={exitDocument}
            addComment={addComment}
            editComment={editComment}
            deleteComment={deleteComment}
            addDraw={addDraw}
            isDrawingEnabled={isDrawingEnabled}
            drawingColor={drawingColor}
            drawingWeight={drawingWeight}
            isDrawingEraserEnabled={isDrawingEraserEnabled}
            isPreviewEnabled={isPreviewEnabled}
            isCommentsEnabled={isCommentsEnabled}
            isCommentsAddingEnabled={isCommentsAddingEnabled}
            onPdfDocumentLoaded={this.onPdfDocumentLoaded}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ document }) => ({
  document,
});

const mapDispatchToProps = {
  getDocument: DocumentActions.getDocument,
  exitDocument: DocumentActions.exitDocument,
  addComment: DocumentActions.addComment,
  editComment: DocumentActions.editComment,
  deleteComment: DocumentActions.deleteComment,
  addDraw: DocumentActions.addDraw,
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
