import React from 'react';
import cn from 'classnames';

import './styles.scss';

const Button = props => {
  const { children, className, buttonSize, buttonType, ...restProps } = props;
  return (
    <button
      className={cn('button', className, {
        [`button--${buttonSize}`]: buttonSize,
        [`button--${buttonType}`]: buttonType,
      })}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
