import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';
import cn from 'classnames';

import * as locales from '../../constants/locales';
import { appConfig } from '../../config';

import './styles.scss';

type DatePeriodProps = WrappedComponentProps & {
  dateTimeStart: Date;
  className?: string;
  locale?: string;
} & ({ dateTimeEnd: Date; isSingleDate?: false | undefined } | { dateTimeEnd?: Date; isSingleDate: true });

const DatePeriod = ({ dateTimeStart, dateTimeEnd, isSingleDate = false, intl, locale, className }: DatePeriodProps) => {
  const startToday = moment().startOf('day');
  const endToday = moment().endOf('day');
  const itemStart = moment(dateTimeStart);
  const itemEnd = dateTimeEnd && moment(dateTimeEnd);

  const isToday =
    itemStart.isBetween(startToday, endToday) || (!isSingleDate && itemEnd?.isBetween(startToday, endToday));

  let str = '';
  if (isToday) {
    str += intl.formatMessage({ id: 'time.today' });
  } else {
    str +=
      locale === locales.RU
        ? `${itemStart.format('D MMMM YYYY')} ${intl.formatMessage({
            id: 'time.year',
          })} `
        : itemStart.format('MMM D, YYYY');
  }

  str += ` ${intl.formatMessage({ id: 'time.at' })} `;

  str += `${itemStart.format(locale === locales.RU ? 'H:mm' : 'h:mm A')}`;

  if (!isSingleDate && itemEnd) {
    str += ' - ';
    const endTimeInNewDay = moment(dateTimeEnd) > moment(dateTimeStart).endOf('day');

    if (endTimeInNewDay) {
      str += `${itemEnd.format(locale === locales.RU ? 'D MMMM' : 'MMM D')} `;
    }
    str += itemEnd.format(locale === locales.RU ? 'H:mm' : 'h:mm A');
  }

  return (
    <span className={cn('period', className)}>
      <time className="period__time">{str}</time>
      <span className="period__gmt"> GMT {appConfig.timeZoneNum}</span>
    </span>
  );
};

export default injectIntl(DatePeriod);
