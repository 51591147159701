import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import './styles.scss';

const Ballon = injectIntl(({ textId }) => (
  <div className="ballon">
    {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'IntlShape' is not assignable to type 'number... Remove this comment to see the full error message */}
    <FormattedMessage id={textId} />
  </div>
));

export default Ballon;
