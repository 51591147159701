import React from 'react';
import moment from 'moment';

import * as GROUPS from '../../constants/listGroup';

const withGroupList = WrappedComponent => {
  return class HOC extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meetings' does not exist on type 'Readon... Remove this comment to see the full error message
        list: this.props.meetings,
        grouped: {
          [GROUPS.UPCOMING]: [],
          [GROUPS.TODAY]: [],
          [GROUPS.PASSED]: [],
          [GROUPS.RESULTS]: [],
        },
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.list !== nextProps.meetings) {
        return {
          list: nextProps.meetings,
        };
      }
      return null;
    }

    groupedList = list => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
      const { filters } = this.props;
      if (filters.query) {
        return {
          [GROUPS.UPCOMING]: [],
          [GROUPS.TODAY]: [],
          [GROUPS.PASSED]: [],
          [GROUPS.RESULTS]: list,
        };
      }
      const startToday = moment().startOf('day');
      const endToday = moment().endOf('day');
      return {
        [GROUPS.UPCOMING]: list.filter(item => endToday <= moment(item.dateTimeStart)),
        [GROUPS.TODAY]: list.filter(item => {
          const itemStart = moment(item.dateTimeStart);
          const itemEnd = moment(item.dateTimeEnd);
          return endToday.isBetween(itemStart, itemEnd) || itemEnd.isBetween(startToday, endToday);
        }),
        [GROUPS.PASSED]: list.filter(item => moment(item.dateTimeEnd) <= startToday),
      };
    };

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'list' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      const grouped = this.groupedList(this.state.list);
      return (
        <WrappedComponent
          {...this.props}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'list' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          meetings={this.state.list}
          grouped={grouped}
        />
      );
    }
  };
};

export default withGroupList;
