import { COMMITTEE_LIST_REQUEST, COMMITTEE_LIST_SUCCESS, COMMITTEE_LIST_FAILURE } from '../constants/ActionTypes';

const defaultState = {
  items: [],
  isFetching: false,
  error: null,
  limit: 0,
  offset: 0,
  total: 0,
};

const committeeList = (state = defaultState, action) => {
  switch (action.type) {
    case COMMITTEE_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case COMMITTEE_LIST_SUCCESS:
      return {
        ...state,
        ...action.data,
        items: [...action.data.items],
        isFetching: false,
      };
    case COMMITTEE_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default committeeList;
