import {
  GET_FILES_REQUEST,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
  FILES_MORE_FAILURE,
  FILES_MORE_REQUEST,
  FILES_MORE_SUCCESS,
  CLEAR_FILES_LIST,
} from '../constants/ActionTypes';

const defaultState = {
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
  isFetching: false,
  error: null,
  filters: {},
};

const files = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_FILES_LIST:
      return {
        ...state,
        items: [],
        limit: 0,
        offset: 0,
        total: 0,
      };
    case FILES_MORE_REQUEST:
    case GET_FILES_REQUEST:
      return {
        ...state,
        filters: action.filters,
        isFetching: action.withFetching,
      };
    case GET_FILES_SUCCESS:
      return {
        ...state,
        ...action.data,
        items: action.data.items,
        isFetching: false,
      };
    case FILES_MORE_SUCCESS:
      return {
        ...state,
        ...action.data,
        items: [...state.items, ...action.data.items],
        isFetching: false,
      };
    case FILES_MORE_FAILURE:
    case GET_FILES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default files;
