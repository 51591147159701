import * as types from '../constants/ActionTypes';
import { CALL_API } from '../middleware/refresh';

export function getList(limit = 0, offset) {
  const params = limit >= 0 && offset >= 0 ? `?limit=${limit}&offset=${offset}` : '';
  return {
    [CALL_API]: {
      types: [types.COMMITTEE_LIST_REQUEST, types.COMMITTEE_LIST_SUCCESS, types.COMMITTEE_LIST_FAILURE],
      endpoint: `/committee/search${params}`,
    },
  };
}

export function getCommittee(committeeId, actions) {
  return {
    [CALL_API]: {
      types: actions,
      method: 'GET',
      endpoint: `/committee/${committeeId}`,
    },
  };
}
