const Mouse = {
  getClickPosition(e) {
    const parentPosition = this.getPosition(e.currentTarget);
    const xPosition = e.clientX - parentPosition.x;
    const yPosition = e.clientY - parentPosition.y;

    return {
      x: xPosition,
      y: yPosition,
    };
  },
  getPosition(el) {
    let xPos = 0;
    let yPos = 0;
    let e = el;

    while (e) {
      if (e.tagName === 'BODY') {
        // deal with browser quirks with body/window/document and page scroll
        const xScroll = e.scrollLeft || document.documentElement.scrollLeft;
        const yScroll = e.scrollTop || document.documentElement.scrollTop;

        xPos += e.offsetLeft - xScroll + e.clientLeft;
        yPos += e.offsetTop - yScroll + e.clientTop;
      } else {
        // for all other non-BODY elements
        xPos += e.offsetLeft - e.scrollLeft + e.clientLeft;
        yPos += e.offsetTop - e.scrollTop + e.clientTop;
      }

      e = e.offsetParent;
    }
    return {
      x: xPos,
      y: yPos,
    };
  },
};

export default Mouse;
