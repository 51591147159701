import { CALL_API } from '../middleware/refresh';
import * as types from '../constants/ActionTypes';

const searchGroups = params => {
  return {
    [CALL_API]: {
      types: [types.GET_MEMBERS_GROUPS_REQUEST, types.GET_MEMBERS_GROUPS_SUCCESS, types.GET_MEMBERS_GROUPS_FAILURE],
      endpoint: '/management/search',
      body: { ...params, limit: 2000 },
    },
  };
};

export const searchMembers = params => {
  return {
    [CALL_API]: {
      types: [types.SEARCH_MEMBERS_REQUEST, types.SEARCH_MEMBERS_SUCCESS, types.SEARCH_MEMBERS_FAILURE],
      endpoint: '/member/search',
      body: params,
    },
  };
};

export function getGroupsByType(groupType) {
  return dispatch => {
    return dispatch(searchGroups({ groupType }));
  };
}

export function getMembersByGroup(groupId) {
  return {
    [CALL_API]: {
      types: [
        types.GET_MEMBERS_BY_GROUP_REQUEST,
        types.GET_MEMBERS_BY_GROUP_SUCCESS,
        types.GET_MEMBERS_BY_GROUP_FAILURE,
      ],
      method: 'GET',
      endpoint: `/management/${groupId}`,
    },
  };
}

export function getMember(id) {
  return {
    [CALL_API]: {
      types: [types.GET_MEMBER_REQUEST, types.GET_MEMBER_SUCCESS, types.GET_MEMBER_FAILURE],
      method: 'GET',
      endpoint: `/member/${id}`,
    },
  };
}
